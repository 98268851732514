
<template>
  <div class="tabelaAmigoSocio">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <div class="loader" v-if="isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar amigos sócios ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="buttonAdicionarDonativo">
          <button v-on:click="toggleRegistarDonativo()">
            <template v-if="showFormDonativo">
              <i class="bi bi-chevron-left"></i> Cancelar
            </template>
            <template v-else>
               <i class="bi bi-plus-circle"></i> Registar Donativo
            </template>
          </button>
          <button @click="exportarDadosAmigosSocios" class="exportar-amigos">
              <i class="bi bi-file-earmark-arrow-down-fill"></i> Exportar Dados Amigos Sócios
          </button>
        </div>
        <div>
          <template v-if="showFormDonativo">
            <keep-alive>
              <RegistarDonativo :tipoDonativo="'p'"/>
            </keep-alive>
          </template>
        </div>
        <div class="tabela-paginate" v-show="!showFormDonativo">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Estado do Estatuto: </th>
              <!--<th>Dados</th>-->
            </tr>
            <tr class="conteudo" v-for="(users, tableKey) in allAmigoSocio" :key="tableKey">
              <td class="idPessoa">{{ users.idPessoa }}</td>
              <td class="verButton"  v-on:click="ver(users.idPessoa)" title="Ver Ficha de Pessoa">
                {{ users.nome }}
              </td>
              <td>{{users.email}}</td>
              <td>{{users.telefone}}</td>
              <td class="estatuto"><span :class="checkEstadoAmigoSocio(users.dataExpiracao) == 'Válido' ? 'badge text-bg-success' : checkEstadoAmigoSocio(users.dataExpiracao) == 'Expirado' ? 'badge text-bg-danger' : 'badge text-bg-warning'">{{checkEstadoAmigoSocio(users.dataExpiracao)}}</span></td>
              <td class="buttons">
                <!--<div class="verButton">
                    <a v-on:click="ver(users.idPessoa)"> Ver </a>
                </div>-->
                <div class="editarButton">
                  <a class="bi bi-pencil-square" v-on:click="editar(users)" title="Editar"></a>
                </div>
                <div class="apagarButton">
                  <a class="bi bi-trash-fill" v-on:click="apagar(users)" title="Remover amigo sócio"></a>
                </div>
              </td>
            </tr>
          </table>
            <Pagination :page=page :total=total @pagination="getAllAmigoSocio"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  mounted() {
    bus.$on('refreshAmigoSocio', () =>{
        this.getAllAmigoSocio();
        this.showFormDonativo=false;
    })
  },
  activated() {
    this.getAllAmigoSocio();
  },
  data() {
    return {
      allAmigoSocio: null,
      showFormDonativo: false,
      tableKey: 0,
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      isLoading: true,
    };
  },
  methods: {
    getAllAmigoSocio(page){
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllAmigoSocio?pageURL=" + (page ? page : 1))
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allAmigoSocio = response.data.amigos;
          this.total = response.data.total;
        }
        this.isLoading = false;
      })
      .catch(error => {
        if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
    },
    toggleRegistarDonativo(){
      this.showFormDonativo = !this.showFormDonativo;
    },
    ver(idPessoa) {
      bus.$emit("viewPessoa", idPessoa);
    },
    editar(data) {
        this.$axios.get("getDadosPessoa/" + data.idPessoa)
        .then(response => {
          if(response.status === 401){
              bus.$emit("logout");
          }
          else
          {
            bus.$emit("editarPessoa", response.data);
          }
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
        });
    },
    checkEstadoAmigoSocio(data){
        let dataExpiracao = new Date(data);
        let dataHoje = new Date();
        var dataProximoMes = new Date(dataHoje.setMonth(dataHoje.getMonth()+1));

      try{
        if(new Date(data).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)){
          return "Expirado";
        }
        else if(dataExpiracao.setHours(0,0,0,0) <= dataProximoMes.setHours(0,0,0,0))
        {
          return "A expirar";
        }
        else
        {
          return "Válido";
        }
      }catch (e){
        return "Não definido";
      }
    },
    apagarAmigoSocio(pessoa){
      this.$axios.delete("deleteAmigoSocio/" + pessoa.idPessoa)
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Amigo Sócio " + pessoa.nome + " removido com sucesso");
            this.getAllAmigoSocio();
          }
          else
          {
            this.$toasted.error("Erro ao remover Amigo Sócio");
          }
        })
        .catch(() => {
          this.$toasted.error("Erro ao remover amigo sócio")
        });
    },
    apagar(pessoa)
    {
      this.title = "Remover Amigo Sócio";
      this.message = "Tem a certeza que pretende remover o amigo sócio - " + pessoa.nome +" ? \n ** Atenção que apagará todos os apoios do mesmo **";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.apagarAmigoSocio(pessoa);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    exportarDadosAmigosSocios() {
      this.$axios.get("getAllAmigoSocioExportar")
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){

            let arrData = typeof response.data.amigos != "object" ? JSON.parse(response.data.amigos) : [...response.data.amigos];
            arrData.sort((a, b) => a.dataExpiracao.localeCompare(b.dataExpiracao));

            let CSV = "sep=," + "\r\n\n";

            let row = "";

            row += "Nome" + "," + "Email" + "," + "Data Expiração" + "," + "Status";

            CSV += row + "\r\n";

            arrData.forEach(element => {
              row = "";
              row += element.nome + "," + element.email + "," + element.dataExpiracao + "," + this.checkEstadoAmigoSocio(element.dataExpiracao);
              CSV += row + "\r\n";
            });

            if(arrData.length == 0)
            {
              this.$toasted.error("Não existem amigos sócios para exportar dados");
              return;
            }
            

            let fileName = "Dados_Amigos_Socios";
            fileName = fileName.replace(/ /g, "_");

            let uri = "data:text/csv;charset=utf-8," + escape(CSV);

            let link = document.createElement("a");
            link.href = uri;
            link.style = "visibility:hidden";
            link.download = fileName + ".csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .catch(() => {
          this.$toasted.error("Erro ao exportar dados")
        });
    },
  }
};


</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.exportar-amigos{
  margin-left: 5px;
}

td.estatuto {
    text-align: center;
    vertical-align: middle;
}

.apagarButton {
  cursor: pointer;
}

.apagarButton a:hover {
  font-weight: bold;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.tabelaAmigoSocio h1 {
  text-align: center;
}

.tabelaAmigoSocio {
  font-family: "Open Sans";
}

.tabelaAmigoSocio .tabela .conteudo:hover{
  font-weight: bold;
  background-color: #e2e2e2
  
}
.tabelaAmigoSocio .tabela {
  width: 100%;
  margin-bottom: 20px;
}
.tabela #tabela{
  width: 70%;
  margin: auto;
}
.tabelaAmigoSocio .dados .idPessoa {
  width: 3%;
}
.tabelaAmigoSocio .tabela table td {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaAmigoSocio .dados table td {
  width: 25%;
}

.tabelaAmigoSocio .tabela table th {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaAmigoSocio .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabelaAmigoSocio .verButton {
  cursor: pointer;
}
.tabelaAmigoSocio .verButton a:hover {
  font-weight: bold;
}

.tabelaAmigoSocio .editarButton {
  cursor: pointer;
}

.tabelaAmigoSocio .editarButton a:hover, .apagarButton a:hover {
  font-weight: bold;
  font-size: 13px;
}

.tabelaAmigoSocio .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}

.tabelaAmigoSocio .buttonAdicionarDonativo {
  margin-bottom: 5px;
  margin-left: 15%;
}

.tabelaAmigoSocio .buttonAdicionarDonativo button {
  text-align: center;
  cursor: pointer;
  background-color: #a7ec82;
  border: none;
  border-radius: 4px;
}

.tabelaAmigoSocio .buttonAdicionarDonativo button:hover {
  background-color: #72d33d;
}
</style>