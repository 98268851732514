
<template>
  <div class="relatorioNumeroEventosTipo">
    <br>
    <h3>Número de Eventos por tipo:</h3>
    <br>
      <div id="fields" class="tabela">
        <table class="w3-table-all" id="tabela">
          <!--ID, nome, tipo, data, local -->
          <th> Tipo Evento </th>
          <th> Total </th>
          <tr class="conteudo" v-for="(row, tableKey) in this.stats" :key="tableKey">
            <td>{{rowFormat(row.tipoEvento)}}</td>
            <td>{{row.totalTipoEventos}}</td>
          </tr>
        </table>
      </div>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  methods: {
    rowFormat: (string) =>{
        switch(string){
          case 'Palestra': return string + 's'; 
          case 'Simposio': return 'Simpósios'; 
          case 'Congresso': return 'Congressos'; 
          case 'Concerto': return 'Concertos'; 
          case 'PalestraSimposio': return 'Palestras de Simpósios'; 
          case 'PalestraCongresso': return 'Palestras de Congressos'; 
          case 'Workshop': return string + 's'; 
          case 'Piquenique': return string + 's'; 
          case 'Jantar Solidariedade': return 'Jantares de Solidariedade'; 
          case 'Mini-Gala': return string + 's'; 
          case 'Apoio Solidariedade': return 'Apoios de Solidariedade'; 
          case 'Tertulia': return 'Tertúlias'; 
        }
    }
  },
  activated() {
    this.$axios.get("getRelatorioContagemEventos")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.stats = response.data;
        }
      })
      .catch(() => {

      });
  },
  data() {
    return {
      stats: []
    };
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.tabela{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.relatorioNumeroEventosTipo h3 {
  text-align: center;
  background-color: linen;
  width: 100%;
}

.relatorioNumeroEventosTipo{
  font-family: "Open Sans";

}

.relatorioNumeroEventosTipo #tabela {
  width: 70%;
}
.relatorioNumeroEventosTipo .tabela table th {
  border: 1px solid #ccc;
}
.relatorioNumeroEventosTipo .tabela table td {
  border: 1px solid #ccc;
}

</style>