
<template>
  <div class="tabelaParticipantes">
    <div class="loader" v-if="isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar participantes ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="tabela-paginate">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <!--<th>Dados</th>-->
            </tr>
            <tr class="conteudo" v-for="(users, key) in allParticipantes" :key="key">
              <td class="idPessoa">{{ users.idPessoa }}</td>
              <td class="verButton" v-on:click="ver(users.idPessoa)" title="Ver Ficha de Pessoa">{{ users.nome }}</td>
              <td>{{ users.email}}</td>
              <td>{{ users.telefone}} </td>
              <td class="buttons">
                <!--<div class="verButton">
                    <a v-on:click="ver(users.idPessoa)"> Ver </a>
                </div>-->
                <div class="editarButton">
                  <a class="bi bi-pencil-square" v-on:click="editar(users)" title="Editar"></a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=page :total=total @pagination="getAllParticipantes"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  activated() {
    this.getAllParticipantes();
  },
  name: "LoginScreen",
  data() {
    return {
      allParticipantes: null,
      page: 1,
      total: 0,
      isLoading: true,
    };
  },
  computed: {},
  methods: {
    getAllParticipantes(page) {
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllParticipantes?pageURL="+(page ? page : 1))
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allParticipantes = response.data.participantes;
          this.total = response.data.total;
        }
        this.isLoading = false;
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
    },
    ver(idPessoa) {
      bus.$emit("viewPessoa", idPessoa);
    },
    editar(data) {
      bus.$emit("editarPessoa", data);
    }
  }
};


</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

#tabela{
  width: 70%;
  margin: auto;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}


.tabelaParticipantes h1 {
  text-align: center;
}

.tabelaParticipantes {
  font-family: "Open Sans";
}


.tabelaParticipantes .tabela .conteudo:hover{
  font-weight: bold;
  background-color: #e2e2e2
  
}
.tabelaParticipantes .tabela {
  width: 100%;
  margin-bottom: 20px;
}
.tabelaParticipantes .dados .idPessoa {
  width: 3%;
}
.tabelaParticipantes .tabela table td {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaParticipantes .dados table td {
  width: 25%;
}

.tabelaParticipantes .tabela table th {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaParticipantes .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabelaParticipantes .verButton {
  cursor: pointer;
}
.tabelaParticipantes .verButton a:hover {
  font-weight: bold;
}

.tabelaParticipantes .editarButton {
  cursor: pointer;
}

.tabelaParticipantes .editarButton a:hover {
  font-weight: bold;
  font-size: 13px;
}

.tabelaParticipantes .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}
</style>