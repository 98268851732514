<template>
  <div>
    <br>
    <div class="voltarPessoa">
      <button v-on:click="switchComponent('PersonsTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <br>
    <h1>Editar Pessoa</h1>
    <br>
    <div class="editarPessoa">
        <div class="form">
          <div class="nome">
            <label>Nome:<i class="obrigatorio">*</i></label>
            <br>
            <input type="text" v-model="input.nome" name="nome">
          </div>
          <div class="email">
            <label>Email:<i class="obrigatorio">*</i></label>
            <br>
            <input type="text" v-model="input.email" name="email">
          </div>
          <div class="telefone">
            <label>Telefone:<i class="obrigatorio">*</i></label>
            <br>
            <input type="text" v-model="input.telefone" name="telefone">
          </div>
          <div class="genero">
            <label>Género:<i class="obrigatorio">*</i></label>
            <br>
            <input type="radio" v-model="input.genero" name="M" value="M"> Masculino
            <br>
            <input type="radio" v-model="input.genero" name="F" value="F"> Feminino
            <br>
            <input type="radio" v-model="input.genero" name="O" value="O"> Outro
            <br>
          </div>
          <div class="zona">
            <label>Zona:<i class="obrigatorio">*</i></label>
            <br>
            <input type="text" v-model="input.zona" name="zona">
          </div>
          <div class="idade">
            <label>Data Nasc.:<i class="obrigatorio">*</i></label>
            <br>
            <input type="date" v-model="input.dataNasc" name="idade" :max="maxDate">
          </div>
          <div class="nif">
            <label>NIF:</label>
            <br>
            <input type="text" v-model="input.nif" name="nif">
          </div>
          <div class="receberEmail-group">
            <label>Receber Emails informativos:<i class="obrigatorio">*</i></label>
            <input type="checkbox" class="receberEmail" v-model="input.receberEmail" >
          </div>
          <div class="comoConheceu">
            <label>Como Conheceu:<i class="obrigatorio">*</i></label>
            <div class="comoConheceuInputs">
              <select v-model="input.comoConheceu" @change="changeComoConheceu">
                <option>Pessoa</option>
                <option value="Local">Folheto em Local</option>
                <option>Redes Sociais</option>
                <option>E-Mail</option>
              </select>
              <template v-if="input.comoConheceu === 'Pessoa'">
                  <vue-single-select
                          v-model="input.atravesDe"
                          :options="allPessoa"
                          :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
                          option-label="nome"
                          :disabled="disabledPessoa"
                  ></vue-single-select>
              </template>
              <template v-else-if="input.comoConheceu === 'Local'">
                  <input type="text" class="local" v-model="input.atravesDe" placeholder="Local">
              </template>
            </div>
          </div>
          <div class="registar">
            <button type="submit" v-on:click.prevent="submit()" :disabled="submiting">
              <i class="bi bi-file-earmark-check-fill"></i> Editar
            </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import { bus } from "../../main.js";
export default {
  props: {
    dadosPessoa: {
      type: Object,
      required: true
    }
  },
  activated() {
    this.$axios.get("getAllPessoasIdNome/")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allPessoa = response.data;
          if(this.dadosPessoa.comoConheceu === 'Pessoa'){
            this.getPessoa()
          }
          this.disabledPessoa = false;
        }
      })
      .catch(error => {
        if(error.response.status === 401){
            bus.$emit("logout");
        }
      });

      let MyDate = new Date();
      this.maxDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                        ('0' + MyDate.getDate()).slice(-2);


    this.input.nome = this.dadosPessoa.nome;
    this.input.email = this.dadosPessoa.email;
    this.input.genero = this.dadosPessoa.genero;
    this.input.telefone = this.dadosPessoa.telefone;
    this.input.zona = this.dadosPessoa.zona;
    this.input.nif = this.dadosPessoa.nif;
    this.input.receberEmail = this.dadosPessoa.receberEmail;

    this.input.dataNasc = this.dadosPessoa.dataNasc;
    this.input.comoConheceu = 
    (this.dadosPessoa.comoConheceu === 'Pessoa' ? this.dadosPessoa.comoConheceu :
     this.dadosPessoa.comoConheceu === 'E-Mail' ? this.dadosPessoa.comoConheceu :   
     this.dadosPessoa.comoConheceu === 'Redes Sociais'? this.dadosPessoa.comoConheceu:
     'Local'
    );

    if(this.dadosPessoa.comoConheceu != "Pessoa" && this.dadosPessoa.comoConheceu != "Redes Sociais" && this.dadosPessoa.comoConheceu != 'E-Mail')
    {
      this.input.atravesDe = this.dadosPessoa.comoConheceu;
    }
  },
  deactivated() {
    this.input.nome = "";
    this.input.email = "";
    this.input.genero = "";
    this.input.telefone = "";
    this.input.zona = "";
    this.input.nif = "";
    this.input.dataNasc = "";
    this.input.comoConheceu = "";
    this.input.atravesDe = "";
  },

  data() {
    return {
      input: {
        jaFreq: true,
        nome: "",
        inputPessoa: "",
        email: "",
        genero: "",
        telefone: "",
        zona: "",
        nif: "",
        dataNasc: "",
        comoConheceu: "Pessoa",
        atravesDe: "",
        receberEmail: 1,
      },
      allPessoa: [],
      maxDate: null,
      disabledPessoa: true,
      submiting: false,
    };
  },
  methods: {
    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    changeComoConheceu(){
      if(this.input.comoConheceu === 'Pessoa'){
        this.getPessoa()
      }
      else if(this.input.comoConheceu === 'Local' && this.dadosPessoa.comoConheceu != "Pessoa" && this.dadosPessoa.comoConheceu != "Redes Sociais" && this.dadosPessoa.comoConheceu != 'E-Mail'){
        this.input.atravesDe = this.dadosPessoa.comoConheceu
      }
    },
    getPessoa(){
      this.input.atravesDe = this.allPessoa.find(pessoa => pessoa.id === this.dadosPessoa.atravesQuem) || "";
    },
    validarEmail(email) {

      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!email.match(validRegex)) {

        return false;
      }
      return true;
    },
    submit: function() {

      if (
          this.input.nome == "" ||
          this.input.nome == null ||
          this.input.email == "" ||
          this.input.email == null ||
          this.input.telefone == "" ||
          this.input.telefone == null ||
          this.input.zona == "" ||
          this.input.zona == null ||
          this.input.genero == "" ||
          this.input.genero == null ||
          this.input.dataNasc == "" ||
          this.input.dataNasc == null ||
          this.input.receberEmail == null
        ) {
          this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
          return;
        }

      if(this.input.nome.trim().length == 0 || this.input.zona.trim().length == 0){
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
        return;
      }

      if(this.input.genero != "M" && this.input.genero != "F" && this.input.genero != "O"){
        this.$toasted.error("Campo Género inválido");
        return;
      }

      if(this.input.comoConheceu != "Pessoa" && this.input.comoConheceu != "Local" && this.input.comoConheceu != "Redes Sociais" && this.input.comoConheceu != "E-Mail"){
        this.$toasted.error("Campo Como Conheceu inválido");
        return;
      }

      if(this.input.telefone.length > 15){
        this.$toasted.error("Número de telefone de tamanho inválido");
        return;
      }

      if(!/^\d+$/.test(this.input.telefone)){
        this.$toasted.error("Caracteres inválidos no número de telefone");
        return;
      }

      if(this.input.nif != null){
        if(this.input.nif != "" && this.input.nif.trim().length == 0)
        {
          this.$toasted.error("NIF inválido");
          return;
        }
        else if(this.input.nif != "" && this.input.nif.length != 9)
        {
          this.$toasted.error("NIF de tamanho inválido");
          return;
        }
      }

      if(!this.validarEmail(this.input.email)){
        this.$toasted.error("Formato de email inválido");
        return;
      }

      if(new Date(this.input.dataNasc).getTime() > new Date().getTime() ){
        this.$toasted.error("Data de nascimento inválida");
        return;
      }

      this.submiting = true;

      this.$axios.put("editPessoa/" + this.dadosPessoa.idPessoa,
          {
            nome: this.input.nome,
            email: this.input.email,
            telefone: this.input.telefone,
            zona: this.input.zona,
            dataNasc: this.input.dataNasc,
            genero: this.input.genero,
            nif: this.input.nif || null,
            comoConheceu:
              this.input.comoConheceu === "Local"
                ? this.input.atravesDe
                : this.input.comoConheceu,
            atravesQuem:
              this.input.comoConheceu === "Local"
                ? null
                : this.input.atravesDe.id,
            receberEmail: this.input.receberEmail == true ? 1 : this.input.receberEmail == false ? 0 : this.input.receberEmail
          },
        )
        .then(response => {
            if (response.status == 401)
            {
              bus.$emit("logout");
            }
            else if (response.status === 200 && response.data != "269") {
              this.$toasted.success("Pessoa editada com sucesso");
              this.switchComponent('PersonsTablesMenu');
            }
            else if(response.status == 400 && response.data == 'nif'){
              this.$toasted.error("Nif já existente! Possível pessoa duplicada");
            }
            else if(response.status == 400 && response.data == 'email'){
              this.$toasted.error("Email já existente! Possível pessoa duplicada");
            }
            else if(response.status == 400 && response.data == 'telefone'){
              this.$toasted.error("Telefone já existente! Possível pessoa duplicada");
            }
            else{
              this.$toasted.error("Erro ao editar pessoa");
            }
            this.submiting = false;
        })
        .catch(() => {
          this.$toasted.error("Erro a editar Pessoa");
        });
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

h1 {
  text-align: center;
  font-family: "Open Sans";
}

.editarPessoa .registar button:hover:disabled{
  background-color: #e99d72;
  cursor: not-allowed;
}

.receberEmail-group {
    margin-left: 115px;
    grid-column-start: 1;
    grid-row-start: 3;
    margin-top: 80px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

input.receberEmail {
    width: 14px;
    height: 14px;
}

.voltarPessoa button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.voltarPessoa i {
  font-size: 17px;
  margin-right: 3px;
}
.voltarPessoa button:hover {
  background-color: #fdf5e6
}

.editarPessoa {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}



.comoConheceuInputs{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: 10px;
}

.editarPessoa .form {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  background-color: oldlace;
  width: 100%;
}
.editarPessoa .form .obrigatorio{
  color: red;
}
.editarPessoa .nome {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 10px;
  margin-left: 115px;
}

.editarPessoa .nome input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.editarPessoa .email {
  grid-column-start: 2;
  grid-row-start: 1;
  margin-top: 10px;
  margin-right: 115px;
}

.editarPessoa .email input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}
.editarPessoa .telefone {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 2;
}

.editarPessoa .telefone input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.editarPessoa .zona {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 2;
}

.editarPessoa .zona input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.editarPessoa .nif {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 3;
}

.editarPessoa .nif input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}




.editarPessoa .idade {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 4;
}

.editarPessoa .idade input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.editarPessoa .comoConheceu {
  /* width: 50%;
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 4; */
  display: flex;
  flex-direction: column;
  margin-right: 115px;
}

.editarPessoa .comoConheceu select {
  height: 35px;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
}

.editarPessoa .comoConheceu .local {
  width: 100%;
  /* margin-left: 9px; */
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.editarPessoa .registar {
  margin-right: 115px;
  margin-bottom: 30px;
  grid-column-start: 2;
  grid-row-start: 5;
  justify-self: end;
}

.editarPessoa .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.editarPessoa .registar button:hover {
  background-color: #fab995;
}
</style>