<template>
  <div class="adicionarPalestraSimposio">
    <br>
    <div class="form">
      <div class="nome">
        <label>
          Nome:
          <i class="obrigatorio">*</i>
        </label>
        <br>
        <input type="text" v-model="input.nome" name="nome">
      </div>
      <div class="data">
        <label>
          Data:
          <i class="obrigatorio">*</i>
        </label>
        <br>
        <input type="datetime-local" v-model="input.data" :min="limitDate" :max="limitDate" name="data">
      </div>
        <div class="botaoOradorExistente">
          <button type="button" class="btn btn-secondary" v-on:click="toggleInsert()"><i class="bi bi-person-plus-fill"></i> {{buttonText}}</button>
        </div>

        <template v-if="input.oradorRecorrente">
          <div class="orador">
            <label>
              Orador:
              <i class="obrigatorio">*</i>
            </label>
            <vue-single-select
              v-model="input.inputOrador"
              :options="allOrators"
              :placeholder="disabledOrador ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
              option-label="nome"
              :disabled="disabledOrador"
            ></vue-single-select>
          </div>
        </template>

        <template v-else>
          <div class="novoOrador">
              <div class="listaPessoas">
                <label>
                  Novo Orador:
                  <i class="obrigatorio">*</i>
                </label>
                  <vue-single-select
                    v-model="input.inputPessoa"
                    :options="allPersons"
                    :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
                    option-label="nome"
                    :disabled="disabledPessoa"
                  ></vue-single-select>
              </div>
            <div class="especialidade">
              <label>
                Especialidade:
                <i class="obrigatorio">*</i>
              </label>
              <br>
              <input name="inputEspecialidade" v-model="input.especialidade">
            </div>
          </div>
        </template>

      <div class="observacoes">
        <label>Observações:</label>
        <br>
        <textarea type="text" v-model="input.observacoes" name="observacoes"></textarea>
      </div>
      <div class="registar">
        <button type="submit" v-on:click.prevent="submit()" :disabled="submiting">
          <i class="bi bi-file-earmark-check-fill"></i> Registar
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  mounted() {
    this.limitDate = this.dataEvento.replace(" ", 'T');
    this.$axios.get("getAllPessoasNaoOradoresIdNome")
      .then((response) => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allPersons = response.data;
          this.disabledPessoa = false;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          bus.$emit("logout");
        }
      });

    this.$axios.get("getAllOradoresIdNome")
      .then((response) => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.allOrators = response.data;
          this.disabledOrador = false;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          bus.$emit("logout");
        }
      });
  },
  props: {
    idEvento: {
      type: Number,
      required: true,
    },
    dataEvento: {
      required: true,
    },
    localEvento: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      input: {
        nome: "",
        tipoEvento: "PalestraSimposio",
        inputPessoa: "",
        inputOrador: "",
        data: "",
        local: this.localEvento,
        observacoes: "",
        especialidade: "",
        oradorRecorrente: true,
        //False significa inserção nova pessoa como orador
      },
      buttonText: "Novo Orador",
      allPersons: [],
      allOrators: [],
      limitDate: null,
      disabledPessoa: true,
      disabledOrador: true,
      submiting: false,
    };
  },
  methods: {
    clearFields: function () {
      (this.input.nome = ""),
        (this.input.oradorRecorrente = true),
        (this.input.inputOrador = ""),
        (this.input.inputPessoa = ""),
        (this.input.data = ""),
        (this.input.local = ""),
        (this.input.observacoes = ""),
        (this.input.especialidade = "")
    },
    submit: function () {
      var oradorAux;
      //verificar se há outros campos obrigatorios vazios
      if (
        this.input.nome === "" ||
        this.input.oradorRecorrente === "" ||
        this.input.data === "" ||
        this.input.local === ""
      ) {
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
        return;
      }

      var dataPalestraAux = new Date(this.input.data);
      dataPalestraAux.setHours(0);
      dataPalestraAux.setMinutes(0);
      var dataEventoAux = new Date(this.dataEvento);
      dataEventoAux.setHours(0);
      dataEventoAux.setMinutes(0);
      if (dataPalestraAux.getTime() != dataEventoAux.getTime()) {
        this.$toasted.error(
          "Uma palestra tem de ocorrer no mesmo dia do Simpósio"
        );
        return;
      }


      //INSERÇÕES DE EVENTO
      oradorAux = this.input.oradorRecorrente ? this.input.inputOrador ? this.input.inputOrador.id : null : this.input.inputPessoa ? this.input.inputPessoa.id : null;
      //tratar do orador
      if (oradorAux == null) {
        this.$toasted.error("É necessário selecionar ou inserir um orador");
        return;
      }
      
      if(!this.input.oradorRecorrente)
      {
        if(this.input.especialidade == "" || this.input.especialidade.trim().length === 0)
        {
          this.$toasted.error("A especialidade de um  orador não pode estar vazia");
          return;
        }

        this.submiting = true;

        this.$axios.post("postOrador", {
            idOrador: oradorAux,
            especialidade: this.input.especialidade,
          })
          .then((response) => {
            if(response.status == 401)
            {
              bus.$emit("logout");
            }
            else if(response.status == 201)
            {
              this.$toasted.success("Inserção de orador realizada com sucesso");
              this.postEvento(oradorAux);
            }
            else
            {
              this.submiting = false;
              this.$toasted.error("Erro ao registar orador");
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              bus.$emit("logout");
            }
            this.$toasted.error("Erro a inserir pessoa nos oradores");
          });
      }
      else
      {
        this.postEvento(oradorAux); 
      }
    },
    toggleInsert: function () {
      if (this.input.oradorRecorrente) {
        this.buttonText = "Cancelar";
      } else {
        this.buttonText = "Novo Orador";
      }
      this.input.oradorRecorrente = !this.input.oradorRecorrente;
    },
    postEvento(idOrador)
    {
      this.submiting = true;
      this.$axios.post("postEvento", {
          idOrador: idOrador,
          nomeEvento: this.input.nome,
          dataEvento: this.input.data.replace("T", " "),
          localEvento: this.input.local,
          tipoEvento: this.input.tipoEvento,
          observacoes: this.input.observacoes,

      }).then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            //obter SELECT ID e colocar na tabela de relacionamento
            let eventoAux = response.data;

            this.$axios.post("postPalestraSimposio", {
                idPalestra: eventoAux,
                idSimposio: this.idEvento,
              })
              .then(() => {
                this.$toasted.success("Inserção de palestra realizada com sucesso");
                bus.$emit("submitPalestra");
                this.submiting = false;
                return;
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  bus.$emit("logout");
                }
              });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
          this.$toasted.error("Erro na inserção de evento");
        });
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.adicionarPalestraSimposio .registar button:hover:disabled{
  background-color: #e99d72;
  cursor: not-allowed;
}

h1 {
  text-align: center;
  font-family: "Open Sans";
}

.adicionarPalestraSimposio {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.adicionarPalestraSimposio .form {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 15px;
  background-color: oldlace;
  width: 100%;
}

.adicionarPalestraSimposio .form .obrigatorio {
  color: red;
}

.adicionarPalestraSimposio .nome {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 15px;
  margin-left: 192px;
}
.adicionarPalestraSimposio .nome input {
  width: 90%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}
.adicionarPalestraSimposio .data {
  grid-column-start: 2;
  margin-top: 15px;
}

.adicionarPalestraSimposio .data input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .observacoes {
  grid-column-start: 1;
  grid-row-start: 2;
  margin-left: 192px;
}
.adicionarPalestraSimposio .observacoes textarea {
  width: 90%;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #a1a0a0;
  resize: none;
  border-radius: 4px;
}

.adicionarPalestraSimposio .orador {
  height: 15px;
  width: 100%;
  grid-row-start: 2;
  grid-column-start: 2;
}

.adicionarPalestraSimposio .botaoOradorExistente {
  grid-row-start: 2;
  grid-column-start: 3;
  justify-self: center;
}

.adicionarPalestraSimposio .botaoOradorExistente input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.adicionarPalestraSimposio .botaoOradorExistente a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.adicionarPalestraSimposio .novoOrador .listaPessoas {
  width: 100%;
  grid-column-start: 1;
}

.adicionarPalestraSimposio .novoOrador .botaoPessoaExistente {
  margin-top: 30px;
  grid-column-start: 2;
  grid-row-start: 3;
  justify-self: center;
  margin-left: 55px;
}

.adicionarPalestraSimposio .novoOrador .botaoPessoaExistente input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.adicionarPalestraSimposio .novoOrador .botaoPessoaExistente a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.adicionarPalestraSimposio .novoOrador {
  grid-row-start: 4;
  grid-row-start: 2;
  grid-column-start: 2;
}
.adicionarPalestraSimposio .novoOrador .nomeOrador {
  grid-column-start: 1;
  grid-row-start: 4;
  margin-left: 192px;
}

.adicionarPalestraSimposio .novoOrador .nomeOrador input {
  width: 90%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .novoOrador .email {
  grid-column-start: 2;
  grid-row-start: 4;
}

.adicionarPalestraSimposio .novoOrador .email input {
  width: 70%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .novoOrador .telefone {
  grid-column-start: 2;
  grid-row-start: 5;
}

.adicionarPalestraSimposio .novoOrador .telefone input {
  width: 70%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .novoOrador .genero {
  grid-column-start: 1;
  grid-row-start: 6;
  margin-left: 192px;
}

.adicionarPalestraSimposio .novoOrador .zona {
  grid-column-start: 2;
  grid-row-start: 6;
}

.adicionarPalestraSimposio .novoOrador .zona input {
  width: 70%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .novoOrador .idade {
  grid-column-start: 1;
  grid-row-start: 5;
  margin-left: 192px;
}

.adicionarPalestraSimposio .novoOrador .idade input {
  width: 90%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .novoOrador .nif {
  grid-column-start: 2;
  grid-row-start: 7;
}

.adicionarPalestraSimposio .novoOrador .nif input {
  width: 70%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .novoOrador .especialidade {
  margin-top: 10px;
}

.adicionarPalestraSimposio .novoOrador .especialidade input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.adicionarPalestraSimposio .novoOrador .comoConheceu {
  grid-column-start: 1;
  grid-row-start: 8;
  margin-left: 192px;
  width: 39%;
  margin-bottom: 15px;
  display: -webkit-box;
}

.adicionarPalestraSimposio .novoOrador .comoConheceu select {
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 30px;
}

.adicionarPalestraSimposio .novoOrador .comoConheceu .atravesQuem {
  margin-top: 15px;
  width: 98%;
  margin-left: 9px;
}

.adicionarPalestraSimposio .novoOrador .comoConheceu .local {
  margin-top: 22px;
  width: 100%;
  margin-left: 9px;
}

.adicionarPalestraSimposio .novoOrador .comoConheceu .local input {
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  width: 103%;
  height: 35px;
}

.adicionarPalestraSimposio .registar {
  margin-bottom: 30px;
  grid-column-start: 3;
  grid-row-start: 9;
}

.adicionarPalestraSimposio .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.adicionarPalestraSimposio .registar button:hover {
  background-color: #fab995;
}
</style>