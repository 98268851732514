<template>
  <div class="fichaEvento">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <br>
    <div class="voltar">
      <button  v-on:click="switchComponent('EventsTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>

    <h1>{{this.eventData.nomeEvento}}</h1>
    <br>
    <div class="dadosEvento">
      <div class="dadosGerais">
        <div class="nome">
          <label>Nome:</label>
          <br>
          {{this.eventData.nomeEvento}}
        </div>

        <div class="tipo">
          <label>Tipo de Evento:</label>
          <br>
          {{this.eventData.tipoEvento}}
        </div>

        <div>
          <label>Data:</label>
          <br>
          {{this.eventData.dataEvento}}
        </div>

        <div>
          <label>Local:</label>
          <br>
          {{this.eventData.localEvento}}
        </div>

        <div class="div-observacao">
          <label>Obs:</label>
          <br>
          {{this.eventData.observacoes}}
        </div>

        <div v-if="this.eventData.nomeOrador">
          <label>Orador:</label>
          <br>
          {{this.eventData.nomeOrador}}
        </div>

        <div v-if="this.eventData.tipoEvento === 'Workshop'">
          <label>Custo:</label>
          <br>
          {{this.custo}}€
        </div>

        <!-- total de participantes, total de novos membros ,idade media participantes, gráfico idades? 
        Pessoa com mais Participações, Pessoa com mais Acompanhantes-->
        <div>
          <label>Total de participantes:</label>
          <br>
          {{this.countParticipantes}}
        </div>

      </div>
    </div>
    <br>
    <br>
    <br>
    <div class="buttonAdicionarParticipante">
      <button v-if="!showFormParticipantes" type="button" v-on:click="enableFormParticipantes()">
        <i class="bi bi-person-plus-fill"></i> Inserir participante
      </button>
    </div>

    <div v-if="showFormParticipantes">
      <div class="adicionarParticipanteCancelar">
        <button type="button" v-on:click="disableFormParticipantes()">
          <i class="bi bi-x"></i> Cancelar
        </button>
      </div>
      <FormNovoParticipante
        :idEvento="idEvento"
        :listaParticipantes="participanteData"
        :orador="eventData.idOrador"
      ></FormNovoParticipante>
    </div>
    <br>
    <template v-if="this.countParticipantes">
      <div class="participantes">
        <h2>Participantes: {{this.countParticipantes}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <!--<th>ID</th>-->
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th>Nif</th>
                  <th>Primeiro Evento?</th>
                  <th>Cargos</th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(parts, componentKey) in this.participanteData"
                  :key="componentKey"
                >
                  <!--<td class="idPessoa">{{ parts.idPessoa }}</td>-->
                  <td class="verButton" v-on:click="verPessoa(parts.idParticipante)" title="Ver Ficha de Pessoa">{{ parts.nome }}</td>
                  <td>{{ parts.email }}</td>
                  <td>{{ parts.telefone }}</td>
                  <td>{{ parts.nif ? parts.nif : 'Sem Nif' }}</td>
                  <td>{{ parts.flagPrimeiroEvento ? "Sim" : "Não"}}</td>
                  <td class="cargos"><span class="badge text-bg-info" v-if="parts.isStaff">Staff</span><span class="badge text-bg-info" v-if="parts.isAmigo">Amigo Sócio</span><span class="badge text-bg-info" v-if="parts.isOrador">Orador</span><span class="badge text-bg-info" v-if="parts.isEmpresario">Empresário</span></td>
                  <td class="buttons">
                    <!--<div class="verButton">
                    <a v-on:click="ver(event.idEvento)">Ver</a>
                    </div>-->
                    <div class="apagarButton">
                      <a class="bi bi-trash-fill" v-on:click="apagar(parts)" title="Remover Participante"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateParticipantes.page :total=paginateParticipantes.total @pagination="getDadosParticipantes"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <h2 v-else class="text-center">Sem Participantes</h2>
    <br>
    <br>
    <br>
    <template v-if="this.eventData.tipoEvento === 'Simposio'">
      <div class="adicionarPalestra">
        <button v-if="!showFormPalestras" type="button" v-on:click="enableFormPalestras()">
          <i class="bi bi-plus-circle"></i> Inserir palestra
        </button>
      </div>
      <div v-if="showFormPalestras">
        <div class="adicionarPalestraCancelar">
          <button type="button" v-on:click="disableFormPalestras()">
            <i class="bi bi-x"></i> Cancelar
          </button>
        </div>
        <FormAddPalestraSimposio :idEvento="idEvento" :dataEvento="eventData.dataEvento" :localEvento="eventData.localEvento"></FormAddPalestraSimposio>
      </div>
      <br>
    </template>
    <template v-if="this.eventData.tipoEvento === 'Simposio'">
      <div class="tabelaPalestras">
        <h2>Palestras: {{this.countPalestras}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <!--<th>ID</th>-->
                  <th>Nome</th>
                  <th>Orador</th>
                  <th>Data </th>
                  <th>Local </th>
                  <!-- <th>Observacoes</th> -->
                </tr>
                <tr
                  class="conteudo"
                  v-for="(palestra, componentKey) in this.palestraSimposioData"
                  :key="componentKey"
                >
                  <!--<td class="idPalestra">{{ palestra.idPalestra }}</td>-->
                  <td class="verButton" v-on:click="ver(palestra.idEvento)" title="Ver Ficha de Evento">
                    {{ palestra.nomeEvento }}
                  </td>
                  <td>{{ palestra.nomeOrador }}</td>
                  <td>{{ palestra.dataEvento }}</td>
                  <td>{{ palestra.localEvento }}</td>
                  <!-- <td>{{ palestra.observacoes}}</td> -->
                  <td class="buttons">
                    <div class="editarButton">
                      <a class="bi bi-pencil-square" v-on:click="editarEvento(palestra)" title="Editar Apoio"></a>
                    </div>
                    <div class="eliminarButton">
                      <a class="bi bi-trash-fill" v-on:click="eliminarPalestraSimposio(palestra)" title="Eliminar Palestra Simpósio"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginatePalestras.page :total=paginatePalestras.total @pagination="getPalestrasSimposio"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="this.eventData.tipoEvento === 'Congresso'">
      <div class="adicionarPalestra">
        <button v-if="!showFormPalestrasCongresso" type="button" v-on:click="toggleFormPalestrasCongresso()">
          <i class="bi bi-plus-circle"></i> Inserir palestra
        </button>
      </div>
      <br>
      <div v-if="showFormPalestrasCongresso">
        <div class="adicionarPalestraCancelar">
          <button type="button" v-on:click="toggleFormPalestrasCongresso()">
            <i class="bi bi-x"></i> Cancelar
          </button>
        </div>
        <FormAddPalestraCongresso :idEvento="idEvento" :dataEvento="eventData.dataEvento" :localEvento="eventData.localEvento"></FormAddPalestraCongresso>
        <br>
      </div>
    </template>
    <template v-if="this.eventData.tipoEvento === 'Congresso'">
      <div class="tabelaPalestras">
        <h2>Palestras: {{this.countPalestras}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <!--<th>ID</th>-->
                  <th>Nome</th>
                  <th>Orador</th>
                  <th>Data </th>
                  <th>Local </th>
                  <!-- <th>Observacoes</th> -->
                </tr>
                <tr
                  class="conteudo"
                  v-for="(palestra, componentKey) in this.palestraCongressoData"
                  :key="componentKey"
                >
                  <!--<td class="idPalestra">{{ palestra.idPalestra }}</td>-->
                  <td class="verButton" v-on:click="ver(palestra.idEvento)" title="Ver Ficha de Evento">
                    {{ palestra.nomeEvento }}
                  </td>
                  <td>{{ palestra.nomeOrador }}</td>
                  <td>{{ palestra.dataEvento }}</td>
                  <td>{{ palestra.localEvento }}</td>
                  <!-- <td>{{ palestra.observacoes}}</td> -->
                  <td class="buttons">
                    <div class="editarButton">
                      <a class="bi bi-pencil-square" v-on:click="editarEvento(palestra)" title="Editar Apoio"></a>
                    </div>
                    <div class="eliminarButton">
                      <a class="bi bi-trash-fill" v-on:click="eliminarPalestraCongresso(palestra)" title="Eliminar Palestra Congresso"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginatePalestraCongresso.page :total=paginatePalestraCongresso.total @pagination="paginatePalestraCongresso"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="this.eventData.tipoEvento === 'Concerto'">
      <div class="buttonAdicionarCantor">
        <button v-if="!showFormCantores" type="button" v-on:click="toggleFormCantores()">
          <i class="bi bi-person-plus-fill"></i> Inserir Cantor
        </button>
      </div>
      <br>
    </div>
    <div v-if="showFormCantores">
      <div class="adicionarCantorCancelar">
        <button type="button" v-on:click="toggleFormCantores()">
          <i class="bi bi-x"></i> Cancelar
        </button>
      </div>
      <keep-alive>
        <FormAddCantor
          :idEvento="idEvento"
          :cantores="cantoresData"
        ></FormAddCantor>
      </keep-alive>
      <br>
      <br>
    </div>
    <template v-if="this.eventData.tipoEvento === 'Concerto' && this.cantoresData.length != 0">
      <div class="tabelaCantores">
        <h2>Cantores: {{this.countCantores}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th class="text-center">Nome</th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(cantor, componentKey) in this.cantoresData"
                  :key="componentKey"
                >
                  <!--<td class="idPalestra">{{ palestra.idPalestra }}</td>-->
                  <td class="verButton text-center" v-on:click="verCantor(cantor)" title="Ver Ficha de Cantor">{{ cantor.nomeCantor }}</td>
                  <td class="buttons">
                    <div class="eliminarButton">
                      <a class="bi bi-trash-fill" v-on:click="eliminarCantorConcerto(cantor)" title="Remover Cantor"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateCantores.page :total=paginateCantores.total @pagination="getCantoresConcerto"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <br>
    <br>
    <div class="tabelaApoios">
      <template v-if="this.countApoios">
      <div class="participantes">
        <h2>Apoios: {{this.countApoios}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <!--<th>ID</th>-->
                  <th>Nome Staff</th>
                  <th>Data Início</th>
                  <th>Data Fim </th>
                  <th>Pontos </th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(parts, componentKey) in this.apoiosEvento"
                  :key="componentKey"
                >
                  <td class="verButton" v-on:click="verPessoa(parts.idStaff)" title="Ver Ficha de Pessoa">{{ parts.nome }}</td>
                  <td class="dataInicio">{{parts.dataInicio}}</td>
                  <td class="dataFim">{{parts.dataFim}}</td>
                  <td class="pontos">{{parts.pontos}}</td>
                  <td class="buttons">
                    <div class="editarButton">
                      <a class="bi bi-pencil-square" v-on:click="editarApoio(parts)" title="Editar Apoio"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateApoios.page :total=paginateApoios.total @pagination="getApoiosEvento"></Pagination>
            </div>  
          </div>
        </div>
      </div>
    </template> 
    
    </div>
    <br>
    <br>
  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  props: {
    idEvento: {
      required: true
    }
  },
  watch: {
    idEvento() {
      this.getEvento();
    },
  },
  created() {
    bus.$on("submitParticipante", () => {
      // this.disableFormParticipantes();
      this.getDadosParticipantes();
    });

    bus.$on("submitCantores", () => {
      this.toggleFormCantores();
      this.getCantoresConcerto();
    });

    bus.$on("submitPalestra", () => {
      this.disableFormPalestras();
      this.getPalestrasSimposio();
    });

    bus.$on("submitPalestraCongresso", () => {
      this.toggleFormPalestrasCongresso();
      this.getPalestrasCongresso();
    });
  },
  activated() {
    this.getEvento();
  },
  deactivated() {
    this.eventData = [];
    this.participanteData = [];
    this.palestraSimposioData = [];
    this.custo = 0,
    this.showFormParticipantes = false;
    this.showFormPalestras = false;
    this.showFormCantores = false;
    this.countParticipantes = 0;
    this.countNovosMembros = 0;
    this.countPalestras = 0;
  },
  data() {
    return {
      eventData: [],
      participanteData: [],
      palestraSimposioData: [],
      apoiosEvento: [],
      cantoresData:[],
      custo: 0,
      showFormParticipantes: false,
      showFormPalestras: false,
      showFormCantores: false,
      countParticipantes: 0,
      countPalestras: 0,
      countApoios: 0,
      countCantores:0,
      showFormPalestrasCongresso: false,
      palestraCongressoData: [],
      paginatePalestraCongresso: {
        page: 1,
        total: 0
      },
      paginateParticipantes: {
        page: 1,
        total: 0
      },
      paginatePalestras: {
        page: 1,
        total: 0
      },
      paginateApoios: {
        page: 1,
        total: 0
      },
      paginateCantores: {
        page: 1,
        total: 0
      },
      dialog: false,
      title: "",
      message: "",
      btns: []
    };
  },
  methods: {
    getEvento()
    {
      this.$axios.get("getDadosEvento/" + this.idEvento)
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {

          this.eventData = response.data;
          this.custo = +this.eventData.custo;

          this.getDadosParticipantes();
          this.getPalestrasSimposio();
          this.getCantoresConcerto();
          this.getPalestrasCongresso();

          this.getApoiosEvento();      
        }
      })
      .catch((error) => {
        this.error = error;
      });
    },
    ver(idEvento) {
      bus.$emit("viewEvento", idEvento);
    },
    verCantor(cantor) {
      bus.$emit("viewCantor", cantor);
    },
    getPalestrasCongresso(page)
    {
      this.paginatePalestraCongresso.page = page || 1;
      if (this.eventData.tipoEvento === "Congresso") {
        this.$axios.get("getAllPalestrasCongresso/" + this.idEvento+"?pageURL="+(page ? page : 1))
          .then(response => {
            if (response.status === 401) {
              bus.$emit("logout");
            }
            else
            {
              this.palestraCongressoData = response.data.palestras;
              this.paginatePalestras.total = response.data.total;
              this.countPalestras = response.data.total;
            }
          })
          .catch(() => {

          });
      }
    },
    getPalestrasSimposio(page)
    {
      this.paginatePalestras.page = page || 1;
      if (this.eventData.tipoEvento === "Simposio") {
        this.$axios.get("getAllPalestrasSimposio/" + this.idEvento+"?pageURL="+(page ? page : 1))
          .then(response => {
            if (response.status === 401) {
              bus.$emit("logout");
            }
            else
            {
              this.palestraSimposioData = response.data.palestras;
              this.paginatePalestras.total = response.data.total;
              this.countPalestras = response.data.total;
              
            }
          })
          .catch(() => {

          });
      }
    },

    getCantoresConcerto(page)
    {
      this.paginateCantores.page = page || 1;
      if (this.eventData.tipoEvento === "Concerto") {
        this.$axios.get("getAllCantoresConcerto/" + this.idEvento+"?pageURL="+(page ? page : 1))
          .then(response => {
            if (response.status === 401) {
              bus.$emit("logout");
            }
            else
            {
              this.cantoresData = response.data.cantores;
              this.paginateCantores.total = response.data.total;
              this.countCantores = response.data.total;
            }
          })
          .catch(() => {

          });
      }
    },
    getDadosParticipantes(page)
    {
      this.paginateParticipantes.page = page || 1;
      this.countNovosMembros = 0;
      this.$axios.get("getDadosParticipantesEvento/" + this.idEvento+"?pageURL="+(page ? page : 1))
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.participanteData = response.data.participantes;
            this.paginateParticipantes.total = response.data.total;
            this.countParticipantes = response.data.total;
          }

        })
        .catch(error => {
          if (error.response.status === 401) {
              bus.$emit("logout");
          }
        });
    },
    getApoiosEvento(page){

      this.paginateApoios.page = page || 1;
      this.$axios.get("getApoiosEvento/" + this.idEvento+"?pageURL="+(page ? page : 1))
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.apoiosEvento = response.data.apoios;
            this.paginateApoios.total = response.data.total;
            this.countApoios = response.data.total;
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
        });

    },
    enableFormParticipantes() {
      this.showFormParticipantes = true;
    },
    toggleFormCantores()
    {
      this.showFormCantores = !this.showFormCantores;
    },
    toggleFormPalestrasCongresso()
    {
      if(this.paginatePalestras.total < 14){
        this.showFormPalestrasCongresso = !this.showFormPalestrasCongresso;
      }
      else
      {
        this.$toasted.show("Não é possível adicionar mais de 14 palestras a um congresso");
      }
    },
    enableFormPalestras() {
      if(this.paginatePalestras.total < 7){
        this.showFormPalestras = true;
      }
      else
      {
        this.$toasted.show("Não é possível adicionar mais de 7 palestras a um simpósio");
      }
    },
    disableFormParticipantes() {
      this.showFormParticipantes = false;
    },
    disableFormPalestras() {
      this.showFormPalestras = false;
    },
    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    verPessoa(id) {
      bus.$emit("viewPessoa", id);
    },
    editarApoio(id){
      bus.$emit("editarApoio", id);
    },
    apagarParticipante(participante){
      this.$axios.delete("deleteParticipacao/" + this.idEvento + "/" + participante.idParticipante)
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Participação de " + participante.nome + " removida com sucesso");
            this.getDadosParticipantes();
          }
          else
          {
            this.$toasted.error("Erro ao remover a participação");
          }
        })
        .catch(() => {
          this.$toasted.error("Erro a remover participação")
        });
    },
    apagar(participante)
    {
      this.title = "Remover Participação";
      this.message = "Tem a certeza que pretende remover o participante " + participante.nome +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.apagarParticipante(participante);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    editarEvento(evento)
    {
      bus.$emit("editarEvento", evento);
    },
    eliminarPalestraSimposio(palestra)
    {
      this.title = "Eliminar Palestra Simpósio";
      this.message = "Tem a certeza que pretende eliminar a palestra " + palestra.nomeEvento +" ?";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.eliminarPalestraSimposioConfirm(palestra);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    eliminarCantorConcerto(cantor)
    {
      this.title = "Remover Cantor";
      this.message = "Tem a certeza que pretende remover o cantor " + cantor.nomeCantor +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.eliminarCantorConcertoConfirm(cantor);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    eliminarCantorConcertoConfirm(cantor)
    {
      if(this.cantoresData.length == 1)
      {
        this.$toasted.error("É necessário pelo menos um cantor");
      }
      else
      {
        this.$axios.delete("deleteCantorConcerto/" + this.idEvento + "/" + cantor.idCantor)
          .then((response) => {
            if (response.status === 401) {
              bus.$emit("logout");
            }
            else if(response.status === 200){
              this.$toasted.success("Cantor " + cantor.nomeCantor + " removido com sucesso");
              this.getCantoresConcerto();
            }
            else
            {
              this.$toasted.error("Erro a remover cantor")
            }
          })
          .catch(() => {
            this.$toasted.error("Erro a remover cantor")
          });
      }
    },
    eliminarPalestraSimposioConfirm(palestra)
    {
      this.$axios.delete("deleteEvento/" + palestra.idPalestra)
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Palestra Simpósio \" " + palestra.nomeEvento + " \" eliminada com sucesso");
            this.getPalestrasSimposio();
          }
          else
          {
            this.$toasted.error("Erro a eliminar palestra")
          }
        })
        .catch(() => {
          this.$toasted.error("Erro a eliminar palestra")
        });
    },
    eliminarPalestraCongresso(palestra)
    {
      this.title = "Eliminar Palestra Congresso";
      this.message = "Tem a certeza que pretende eliminar a palestra " + palestra.nomeEvento +" ?";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.eliminarPalestraCongressoConfirm(palestra);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    eliminarPalestraCongressoConfirm(palestra)
    {
      this.$axios.delete("deleteEvento/" + palestra.idPalestra)
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Palestra Congresso \" " + palestra.nomeEvento + " \" apagada com sucesso");
            this.getPalestrasCongresso();
          }
          else
          {
            this.$toasted.error("Erro a eliminar palestra")
          }
        })
        .catch(() => {
          this.$toasted.error("Erro a eliminar palestra")
        });
    },
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.cargos{
  max-width: 155px;
}

.badge.text-bg-info{
  margin: 3px 3px;
}

.div-observacao{
  max-width: 650px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.tabelaPalestras {
    margin-bottom: 10px;
}

.fichaEvento h1 {
  text-align: center;
}
.fichaEvento h2 {
  text-align: center;
  background-color: linen;
}

.fichaEvento .voltar button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fichaEvento .voltar i {
  font-size: 17px;
  margin-right: 3px;
}
.fichaEvento .voltar button:hover {
  background-color: #fdf5e6
}

.fichaEvento {
  font-family: "Open Sans";
}
.fichaEvento .dadosEvento {
  background-color: rgb(238, 222, 192);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fichaEvento .dadosGerais {
  display: inline-grid;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: oldlace;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 65px;
  padding-right: 65px;
  grid-row-gap: 20px;
}

.fichaEvento .dadosGerais label {
  font-weight: bold;
}

.fichaEvento .nome {
  grid-column-start: 1;
}

.fichaEvento .tipo {
  grid-column-start: 2;
}

.fichaEvento .tabela {
  width: 80%;
}

.fichaEvento .tabela table td {
  border: 1px solid #ccc;
}

.fichaEvento .tabela table th {
  border: 1px solid #ccc;
}
.fichaEvento .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}

.fichaEvento .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fichaEvento .verButton {
  cursor: pointer;
}
.fichaEvento .verButton a:hover {
  font-weight: bold;
}

.fichaEvento .editarButton, .fichaEvento .eliminarButton {
  cursor: pointer;
}

.fichaEvento .editarButton a:hover, .fichaEvento .eliminarButton a:hover {
  font-weight: bold;
  font-size: 14px;
}

.fichaEvento .apagarButton {
  cursor: pointer;
}

.fichaEvento .apagarButton a:hover {
  font-weight: bold;
}

.fichaEvento .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}

.fichaEvento .buttonAdicionarParticipante, .fichaEvento .buttonAdicionarCantor {
  float: right;
  margin-right: 18%;
}

.fichaEvento .buttonAdicionarParticipante button, .fichaEvento .buttonAdicionarCantor button {
  text-align: center;
  cursor: pointer;
  background-color: #f7ae94;
  border: none;
  border-radius: 4px;
}

.fichaEvento .buttonAdicionarParticipante button:hover, .fichaEvento .buttonAdicionarCantor button:hover {
  background-color: #f88e68;
}

.fichaEvento .adicionarParticipanteCancelar, .fichaEvento .adicionarCantorCancelar {
    text-align: end;
    padding: 10px;
    margin-right: 18%;
}

.fichaEvento .adicionarParticipanteCancelar button, .fichaEvento .adicionarCantorCancelar button {
  text-align: center;
  cursor: pointer;
  background-color: #f7ae94;
  border: none;
  border-radius: 4px;
}

.fichaEvento .adicionarParticipanteCancelar button:hover, .fichaEvento .adicionarCantorCancelar button:hover {
  background-color: #f88e68;
}

.buttonAdicionarCantor{
  display: flex;
}

.fichaEvento .adicionarPalestra {
  float: right;
  margin-right: 18%;
}

.fichaEvento .adicionarPalestra button {
  text-align: center;
  cursor: pointer;
  background-color: #f7ae94;
  border: none;
  border-radius: 4px;
}

.fichaEvento .adicionarPalestra button:hover {
  background-color: #f88e68;
}

.fichaEvento .adicionarPalestraCancelar {
  text-align: end;
  margin: 20px 40px 20px 0;
  margin-right: 18%;
}

.bi-x::before {
    font-weight: 600 !important;
}

.fichaEvento .adicionarPalestraCancelar button {
  text-align: center;
  cursor: pointer;
  background-color: #f7ae94;
  border: none;
  border-radius: 4px;
}

.fichaEvento .adicionarPalestraCancelar button {
  background-color: #f88e68;
}
</style>