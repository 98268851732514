
<template>
  <div class="tabelaOrador">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <div class="loader" v-if="isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar cantores ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="tabela-paginate">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <!-- <th>Email</th> -->
              <!-- <th>Telefone</th> -->
              <!--<th>Dados</th>-->
            </tr>
            <tr class="conteudo" v-for="(users, key) in allCantores" :key="key">
              <td class="idPessoa">{{ users.idCantor }}</td>
              <td class="verButton" v-on:click="ver(users)" title="Ver Ficha de Cantor"> {{users.nomeCantor}}</td>
              <td class="buttons">
                <div class="apagarButton">
                  <a class="bi bi-trash-fill" v-on:click="apagar(users)" title="Apagar"></a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=page :total=total @pagination="getAllCantores"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  activated() {
    this.getAllCantores();
  },
  data() {
    return {
      allCantores: null,
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      isLoading: true
    };
  },
  methods: {
    getAllCantores(page) {
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllCantores?pageURL="+(page ? page : 1))
      .then((response) => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allCantores = response.data.cantores;
          this.total = response.data.total;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          bus.$emit("logout");
        }
      });
    },
    apagarCantor(cantor)
    {
      this.$axios.delete("deleteCantor/" + cantor.idCantor)
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status == 200)
          {
            this.$toasted.success("Cantor eliminado com sucesso")
            this.getAllCantores();
          }
          else if(response.status == 400 && response.data == "concerto")
          {
            this.$toasted.error("Erro ao eliminar cantor, pois o mesmo tem concertos associados")
          }
          else
          {
            this.$toasted.error("Erro ao eliminar cantor")
          }
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
        });
    },
    apagar(cantor)
    {
      this.title = "Eliminar Cantor";
      this.message = "Tem a certeza que pretende eliminar o cantor - " + cantor.nomeCantor +" ?";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.apagarCantor(cantor);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    ver(cantor){
      bus.$emit('viewCantor', cantor);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

#tabela{
  width: 70%;
  margin: auto;
}

.tabelaOrador .apagarButton {
  cursor: pointer;
}

.tabelaOrador .apagarButton a:hover {
  font-weight: bold;
  font-size: 14px;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.tabelaOrador h1 {
  text-align: center;
}

.tabelaOrador {
  font-family: "Open Sans";
}

.tabelaOrador .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}
.tabelaOrador .tabela {
  width: 100%;
  margin-bottom: 20px;
}
.tabelaOrador .dados .idPessoa {
  width: 3%;
}
.tabelaOrador .tabela table td {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaOrador .dados table td {
  width: 25%;
}

.tabelaOrador .tabela table th {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaOrador .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabelaOrador .verButton {
  cursor: pointer;
}
.tabelaOrador .verButton a:hover {
  font-weight: bold;
}

.tabelaOrador .editarButton {
  cursor: pointer;
}

.tabelaOrador .editarButton a:hover {
  font-weight: bold;
  font-size: 13px;
}

.tabelaOrador .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}
</style>