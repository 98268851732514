
<template>
  <div class="tabelaOrador">
    <div class="loader" v-if="isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar oradores ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="tabela-paginate">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Especialidade</th>
              <!--<th>Dados</th>-->
            </tr>
            <tr class="conteudo" v-for="(users, key) in allOrador" :key="key">
              <td class="idPessoa">{{ users.idOrador }}</td>
              <td class="verButton" v-on:click="ver(users.idOrador)" title="Ver Ficha de Pessoa">{{users.nome}}</td>
              <td>{{ users.email }}</td>
              <td>{{ users.telefone }}</td>
              <td   class="especialidade-linha">{{ users.especialidade }}</td>
              <td class="buttons">
                <!--<div class="verButton">
                    <a v-on:click="ver(users.idPessoa)"> Ver </a>
                </div>-->
                <div class="editarButton">
                  <a
                    class="bi bi-pencil-square"
                    v-on:click="editar(users)"
                    title="Editar"
                  ></a>
                </div>
                <div class="editarButton">
                  <a
                    class="bi bi-pen-fill"
                    @click="alterarEspecialidade(users)"
                    title="Editar especialidade"
                  ></a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=page :total=total @pagination="getAllOradores"></Pagination>
        </div>
      </div>
    </div>
  <input-dialog 
    ref="inputDialog"
    confirmationBtn="Guardar"
    cancelBtn="Cancelar"
    :inputValue="OradorEditar.especialidade"
    msg="Indique a especialidade do orador"
    title="Alterar Especialidade"
    @inputDialog="alterarEspecialidadeConfirm">

  </input-dialog> 
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  activated() {
    this.getAllOradores();
  },
  data() {
    return {
      allOrador: null,
      page: 1,
      total: 0,
      isLoading: true,
      OradorEditar: {
        idOrador: null,
        especialidade: "",
      },
    };
  },
  methods: {
    alterarEspecialidade(orador)
    {
      this.OradorEditar = orador;
      let dlg = this.$refs.inputDialog
      dlg.show()
    },
    alterarEspecialidadeConfirm(especialidade)
    {
      this.$axios.put("putEspecialidadeOrador/" + this.OradorEditar.idOrador,
          {
            especialidade: especialidade,

          }).then((response) => {
          if(response.status == 200)
          {
            this.$toasted.success("Especialidade alterada com sucesso");
            let dlg = this.$refs.inputDialog
            dlg.hide();
            this.OradorEditar.especialidade = especialidade;
          }
          else
          {
            this.$toasted.error("Erro ao alterar especialidade");
          }
        })
        .catch(() => {
          this.$toasted.error("Erro ao alterar especialidade");
        });
    },
    getAllOradores(page) {
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllOradores?pageURL="+(page ? page : 1))
      .then((response) => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allOrador = response.data.oradores;
          this.total = response.data.total;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          bus.$emit("logout");
        }
      });
    },
    ver(idPessoa) {
      bus.$emit("viewPessoa", idPessoa);
    },
    editar(data) {
      this.$axios.get("getDadosPessoa/" + data.idPessoa)
      .then((response) => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          bus.$emit("editarPessoa", response.data);
        }
      });

    },
  },
};
</script>



<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

#tabela{
  width: 70%;
  margin: auto;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.tabelaOrador h1 {
  text-align: center;
}

.tabelaOrador {
  font-family: "Open Sans";
}

.tabelaOrador .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}
.tabelaOrador .tabela {
  width: 100%;
  margin-bottom: 20px;
}
.tabelaOrador .dados .idPessoa {
  width: 3%;
}
.tabelaOrador .tabela table td {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaOrador .dados table td {
  width: 25%;
}

.tabelaOrador .tabela table th {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaOrador .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabelaOrador .verButton {
  cursor: pointer;
}
.tabelaOrador .verButton a:hover {
  font-weight: bold;
}

.tabelaOrador .editarButton {
  cursor: pointer;
}

.tabelaOrador .editarButton a:hover {
  font-weight: bold;
  font-size: 13px;
}

.tabelaOrador .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}
</style>