<template>
<div class= "all">
  <div class="atempharlogo">
    <div class="imagem" >
      <a @click="push" role="button">
        <img src="/img/logo_atemphar.png">
      </a>
    </div>
      <div class="txtTitle">
      Plataforma de Dados
      </div> 
        <template v-if="user != null && Object.keys(user).length != 0">
          <!-- <div class="items-right">
            <div class="mudarPass" v-on:click="mudarPassword"><label><i class="bi bi-key"></i> Mudar Password</label></div>
            <div class="logoutButton" v-on:click="logout"><i class="bi bi-box-arrow-right"></i> Logout </div>
          </div> -->
          <div class="items-right">
            <div class="container">
              <div class="half">
                <label for="profile2" class="profile-dropdown" role="button">
                  <input type="text" id="profile2">
                  <img src="/img/user.png">
                  <span>{{user.nome}}</span>
                  <label role="button" for="profile2"><i class="bi bi-list-nested"></i></label>
                  <ul>
                    <li><a @click="perfil"><i class="bi bi-person-circle"></i> Perfil</a></li>
                    <li><a @click="mudarPassword"><i class="bi bi-key"></i> Mudar Password</a></li>
                    <li><a @click="logout"><i class="bi bi-box-arrow-right"></i> Logout</a></li>
                  </ul>
                </label>
              </div>
            </div>
          </div>
        </template>
  </div>
  <div :class="(!(PanelComponent === 'LoginScreen') && (PanelComponent != null)) ? 'menu loggedin' : 'menu nologin'" id="menuTopBar">
    <!-- botões do menu -->
    <button id="menuPessoa" :class="PanelComponent === 'PersonsTablesMenu' ? 'menuItem Pessoas ativado' : 'menuItem Pessoas'" v-on:click="switchComponent('PersonsTablesMenu')">Pessoas</button>
    <button id="menuEvento" :class="PanelComponent === 'EventsTablesMenu' ? 'menuItem Eventos ativado' : 'menuItem Eventos'" v-on:click="switchComponent('EventsTablesMenu')">Eventos</button>
    <button id="menuEmpresa" :class="PanelComponent === 'AllEmpresasTable' ? 'menuItem Empresa ativado' : 'menuItem Empresa'" v-on:click="switchComponent('AllEmpresasTable')">Empresas</button>
    <button id="menuInquerito" :class="PanelComponent === 'InqueritosTablesMenu' ? 'menuItem Inquerito ativado' : 'menuItem Inquerito'" v-on:click="switchComponent('InqueritosTablesMenu')">Inquéritos</button>
    <button id="menuMensagem" :class="PanelComponent === 'MensagensTablesMenu' ? 'menuItem Mensagem ativado' : 'menuItem Mensagem'" v-on:click="switchComponent('MensagensTablesMenu')">Mensagens</button>
    <button id="menuRelatorio" :class="PanelComponent === 'RelatorioGlobalBase' ? 'menuItem Relatorio ativado' : 'menuItem Relatorio'" v-on:click="switchComponent('RelatorioGlobalBase')">Relatórios</button>
  </div>
</div>
</template>

<script>
import { bus } from "../main.js";

export default {
  props: {
      PanelComponent: {
        type: String,
        required: false
      },
      user: {
        type: Object,
        required: false,
        default: null
      }
  },
  name: 'Index',
   methods: {
    logout: function(){
      sessionStorage.removeItem("token");
      bus.$emit("logout");
    },
    perfil()
    {
      if(this.$route.path != "/")
      {
        this.$router.push("/");
        setTimeout(() => {
          bus.$emit("editarPessoa", this.user);
        }, 200);

      }
      else
      {
        bus.$emit("editarPessoa", this.user);
      }
    },
    switchComponent(comp) {
      bus.$emit('switchComp', comp);
    },
    mudarPassword()
    {
      if(this.$route.name != "ChangePassword")
      {
        this.$router.push({ name: 'ChangePassword', params: {auth: true, token: "password", user: this.user}});
      }
    },
    push()
    {
      if(this.$route.path != "/")
      {
        this.$router.push("/");
      }
    }
  },
}
</script>

<style lang="scss" scoped>
// PROJECT STYLING

$border-radius: 3px;
$primary: #f2f2f2;
$active: orange;
$animation: pulse;

.profile-dropdown{
  display: flex;
  height: 35px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position:relative;
  background: $primary;
  margin: auto;
  font-weight: bold;
  font-size: 1.3rem;
  border-radius: $border-radius;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
  -webkit-box-shadow: -2px 3px 20px -1px rgba(0,0,0,0.8); 
  box-shadow: -2px 3px 20px -1px rgba(0,0,0,0.8);
  
  * {
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */     
  }
  
  input[type="text"]{
    width: 0px;
    border: none;
    background: $primary;
    outline: none;
    cursor: pointer;
    
    &:focus {
      & ~ ul{
        visibility: visible;
        transition: visibility 0.1s, opacity 0.5s linear;
        animation: pulse 0.8s;
      }
      
      & ~ label{
        background: $active;
        height: 35px;
        width: 45px;
        text-align: center;
        padding: 0;
        
        i{
          color: $primary;
        }

        &:after{
          content: '';
          position:absolute;
          top: 100%;
          right: calc(50% - 10px);
          display:block;
          border-style: solid;
          border-width: 7px 10px 0 10px;
          border-color: $active transparent transparent transparent;
          width: 0;
          height: 0;
        }
      }
    }
  }
  
  img{
    vertical-align:middle;
    margin-right: 1rem;
    margin: .5rem .75rem .5rem .5rem;
    padding: 5px;
    width: 30px;
  }
  
  span{
    display:inline-block;
    vertical-align: sub;
    margin-right:2rem;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 17px;
    max-width: 200px;
    min-width: 75px;
  }
  
  ul{
    visibility: hidden;
    transition: visibility 0.3s, opacity 0.1s;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    position:absolute;
    top: 100%;
    right: 0;
    width: 100%;
    border-radius: $border-radius;
    margin-top: 10px;
    -webkit-box-shadow: -2px 3px 20px -1px rgba(0,0,0,0.8); 
    box-shadow: -2px 3px 20px -1px rgba(0,0,0,0.8);
    
    li{
      a{
        display:block;
        padding: .75rem 1rem;
        text-decoration: none;
        color: darken($primary ,50%);
        font-size: 1rem;
        
        i{
          font-size: 1.3rem;
          vertical-align: middle;
          margin: 0 .75rem 0 -.25rem;
        }
        
        &:hover{
          background: darken($primary, 5%);
        }
      }
      
      &:first-child a:hover{
        border-radius: $border-radius $border-radius 0 0;
      }
      
      &:last-child a:hover{
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }
  
  & > label {
    position: relative;
    height: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    background: transparent;
    color: #333;
    box-sizing: border-box;
    padding: 0;
    width: 45px;
    text-align: center;
    float: right;
    border-radius: 0 $border-radius $border-radius 0;

    i{
      color: darken($primary, 25%);
      font-size: 1.75rem;
    }
  }
  
  &:after{
    content: '';
    display:table;
    clear:both;
  }
}

body{
  font-family: Lato;
}

h1{
  text-align:center;
  font-size: 3rem;
  color: rgba(0,0,0, .5);
  text-transform: uppercase;
  margin: 2rem 0 0;
  letter-spacing: .5rem;
}

.container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0px !important;

  .half{
    float:left;
    margin-bottom: 2rem;
    margin-right: 50px;
  }
  
  &:after{
    content: '';
    display:table;
    clear:both;
  }

@keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1.03);
    }

    100% {
      transform: scale(1);
    }

  }
}

p.subtitle{
  color: rgba(0,0,0, .5);
  font-weight: bold;
  text-align:center;
  margin: .5rem 0 2rem;
  letter-spacing: .1rem;
}
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.mudarPass i.bi.bi-key {
  font-size: 17px;
}

.all .atempharlogo{
  background-image: url("/img/campo_trigo.jpg");
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  height: 100%;
  
}

.all .atempharlogo .imagem{
  grid-column-start: 1;
  grid-row-start: 2;
  margin-left: 10px;
  
}

.all .atempharlogo .imagem img{
  width: 55%;
  margin-top: 10px;
  
}
.all .atempharlogo .txtTitle {
    grid-column-start: 2;
    grid-row-start: 3;
    color: #FFFFFF;
    font-family: "Berlin Sans FB";
    font-size: 40px;
    margin-right: 50px;
    text-align: right;
}

.all .helpButton{
  color: #FFFFFF;
  font-family: "Berlin Sans FB";
  cursor: pointer;
  font-size: 15px;
  grid-column-start: 1;
  margin-left: 15px;
  margin-top: 5px;
  width: 15%;
}

.all  .logoutButton{
  text-align: right;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Berlin Sans FB";
  font-size: 15px;
  grid-column-start: 2;
  margin-top: 5px;
  grid-row-start: 1;
  justify-self: end;
  text-align: center;
}

.all .menu{
  width: 100%;
  overflow: hidden;
  display: flex;
  gap: 10px;
  padding: 5px;
}
.all .menu button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 20px 2.48vw;
  transition: 0.3s;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.menu.nologin button{
  cursor: default !important;
}

.all .menuItem {
  font-size: 1.7vw;
  font-family: "Open Sans";
  margin: auto;
  /*width: 25%;*/
  width: 15%;
  
}

.all .menuItem.Pessoas {
  background-color: #B3CC47;
  flex-grow: 4;
}


.all .loggedin #menuPessoa:hover{
  background-color: rgb(140, 167, 20);
}

.all .menuItem.Pessoas.ativado {
  background-color: rgb(140, 167, 20);
  color: white;
  text-underline-position: under;
  text-decoration-line: underline;
  font-weight: bold;
}

.all .menuItem.Eventos{
  background-color: #5BABCD;
  flex-grow: 4;
}

.all .loggedin #menuEvento:hover{
  /*background-color:rgb(33, 76, 150);*/
  background-color: rgb(39, 134, 175);
}

.all .menuItem.Eventos.ativado{
  background-color:rgb(39, 134, 175);
  color: white;
  text-underline-position: under;
  text-decoration-line: underline;
  font-weight: bold;
}

.all .menuItem.Inquerito{
  background-color: #EF864A;
  flex-grow: 4;
}

.all .loggedin #menuInquerito:hover{
  background-color: rgb(236, 107, 32);
}

.all .menuItem.Inquerito.ativado{
  background-color: rgb(236, 107, 32);
  color: white;
  text-underline-position: under;
  text-decoration-line: underline;
  font-weight: bold;
}

.all .menuItem.Empresa{
  background-color: #FFDB3D;
  flex-grow: 4;
}

.all .loggedin #menuEmpresa:hover{
  background-color: rgb(238, 199, 26);
}

.all .menuItem.Empresa.ativado{
  background-color: rgb(238, 199, 26);
  color: white;
  text-underline-position: under;
  text-decoration-line: underline;
  font-weight: bold;
}

.all .menuItem.Mensagem{
  background-color: #2abcbf;
  flex-grow: 4;
}

.all .loggedin #menuMensagem:hover{
  background-color: #119093;
}

.all .menuItem.Mensagem.ativado{
  background-color: #119093;
  color: white;
  text-underline-position: under;
  text-decoration-line: underline;
  font-weight: bold;
}

.all .menuItem.Relatorio{
  background-color: #EA599D;
  flex-grow: 4;
}

.all .loggedin #menuRelatorio:hover{
  background-color: rgb(221, 61, 136);
}

.all .menuItem.Relatorio.ativado{
  background-color: rgb(221, 61, 136);;
  color: white;
  text-underline-position: under;
  text-decoration-line: underline;
  font-weight: bold;
}

.mudarPass{
    color: #FFFFFF;
    font-family: "Berlin Sans FB";
    font-size: 15px;
    grid-column-start: 2;
    margin-top: 5px;
    grid-row-start: 1;
    justify-self: end;
    text-align: center;
}

.items-right {
  grid-column-start: 2;
  grid-row-start: 2;
}

.mudarPass label{
  cursor: pointer;
}

@media (max-width: 768px) {
  .all .menuItem {
      font-size: 2.8vw !important;
  }
  .all .atempharlogo .imagem img {
    width: 90% !important;
  }
}

@media (max-width: 510px) {
  .all .menu{
    gap: 6px !important;
  }
}

@media (max-width: 425px) {
  .all .menuItem {
    font-weight: 600;
  }
}

@media (max-width: 910px) {
  .all .menuItem {
    font-size: 2vw;
  }
}

@media (max-width: 600px) {
  .all .atempharlogo {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .half{
    margin-right: 0px !important;
  }

  .profile-dropdown ul li a{
    text-align: start !important;
  }

  .items-right{
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    gap: 10px;
    justify-self: center;
  }

  .all .atempharlogo .txtTitle {
    font-size: 30px;
    margin-right: 0px;
    text-align: center;
  }
}


</style>
