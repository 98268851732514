<template>
  <div class="formNovoStaff">
    <br>
    <br>
    <div class="form">
        <!-- já frequentou, user existente -->
      <div class="novoStaff">
        <label>Pessoa:<i class="obrigatorio">*</i></label>
        <br>
        <!--
        <input name="inputPessoa" v-model="input.inputPessoa" list="listaPessoa">
        <datalist id="listaPessoa">
          <option v-for="(pessoa, key) in allPessoa" :key="key">{{pessoa.idPessoa}}: {{pessoa.nome}}</option> 
        </datalist>
        -->
        <vue-single-select
                v-model="input.inputPessoa"
                :options="allPessoaNotStaff"
                :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome, email ou telemóvel'"
                option-label="nome"
                :disabled="disabledPessoa"
        ></vue-single-select>
      </div>
      <div class="registar">
        <button type="submit" v-on:click.prevent="submit()" :disabled="submiting">
          <i class="bi bi-file-earmark-check-fill"></i> Promover
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  mounted() {
    this.getAllNonStaffs();
  },
  data() {
    return {
      input: {
        inputPessoa: "",
      },
      allPessoaNotStaff: [],
      disabledPessoa: true,
      submiting: false,
    };
  },
  methods: {
    getAllNonStaffs()
    {
      this.$axios.get("getAllPessoasNaoStaff/")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allPessoaNotStaff = response.data;
          this.disabledPessoa = false;
        }
      })
      .catch(error => {
        this.error = error;
      });
    },
    submit: function() {

        if(this.input.inputPessoa == "" || this.input.inputPessoa == null){
          this.$toasted.error("Selecione uma pessoa para ser promovida a staff");
          return;
        }

        this.submiting = true;
        this.$axios.post("promoverStaff/"+this.input.inputPessoa.id)
        .then(response => {
          if(response.status == 401){
            bus.$emit("logout");
          }
          else if(response.status == 200){
            this.$toasted.success("Staff promovido com sucesso");
            this.input.inputPessoa = "";
            this.getAllNonStaffs();
            bus.$emit("promoteStaff");
          }
          else if (response.status == 400) {
            this.$toasted.error("Erro ao promover o staff");
          }
          this.submiting = false;
        })
        .catch(error => {
          if(error.response.status === 401){
            bus.$emit("logout");
          }
          this.submiting = false;
        });        
      }
    },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.formNovoStaff .registar button:hover:disabled
{
  background-color: #e99d72;
  cursor: not-allowed;
}

.formNovoStaff {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.formNovoStaff .form {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  background-color: oldlace;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.formNovoStaff .form .obrigatorio{
  color: red;
}

.formNovoStaff .novoStaff {
  width: 60%;
}

.formNovoStaff .registar {
  margin-bottom: 15px;
  margin-top: 10px;
}

.formNovoStaff .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.formNovoStaff .registar button:hover {
  background-color: #fab995;
}
</style>