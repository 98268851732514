<template>
    <div class="conteudo">
        <v-confirm
            v-model="dialog"
            :title="title"
            :message="message"
            :btns="btns"
        />
        <div class="conteudo-group">
            <!-- <label class="nota-mensagem"><label class="nota-mensagem-nota">Nota: </label>{{tipoMensagem == 'A' || tipoMensagem == 'AS' ? ' Caso queira adicionar o nome da pessoa à mensagem coloque na mesma ' : ' Caso queira adicionar o nome do evento à mensagem, o link de inscrição ou a data do mesmo coloque na mesma ' }}<label class="nota-mensagem-variavel">{{tipoMensagem == 'A' || tipoMensagem == 'AS' ? ' {nomePessoa}' : ' {nomeEvento} {linkEvento} {dataEvento}'}}</label></label> -->
            <div class="background-group">
                <div class="form-group-nomeLista">
                    <label>Nome da Lista:<i class="obrigatorio">*</i></label>
                    <input type="text" class="form-control" name="nomeLista" v-model="lista.nomeLista" placeholder="Nome da Lista">
                </div>
                <label class="contactos-disponiveis">Contactos disponíveis</label>
                <div class="letras">
                    <button class="btn btn-secondary letras-format" role="button" @click="letraClick(char)" v-for="(char, index) in allLetras" :key="index">{{char}}</button>
                </div>
                <div class="form-group-filtrar">
                    <form class="form-group-filtrarLista" @submit.prevent="formSubmit()">
                        <input type="text" class="form-control" name="nomeLista" v-model="filtroContactos" placeholder="Filtrar por nome, email, telefone">
                        <button type="submit" class="btn btn-secondary filtrar"><i class="bi bi-search"></i> Filtrar</button>
                    </form>
                </div>
                <div class="tipo-pessoa-group">
                    <div class="tipo-pessoa">
                        <label>Tipo de Pessoa: </label>
                        <select class="form-select" v-model="filtroTipoPessoa">
                            <option selected value="todos">Todos</option>
                            <option value="staff">Staffs</option>
                            <option value="amigo">Amigos Sócios</option>
                            <option value="empresario">Empresários</option>
                            <option value="evento">Inscritas em evento</option>
                        </select>
                    </div>
                </div>
                <div class="evento-group" v-if="filtroTipoPessoa == 'evento'">
                    <div class="tipo-pessoa">
                        <label for="mensagem">Evento:</label>
                        <vue-single-select
                            v-model="idEvento"
                            :options="allEvents"
                            :placeholder="disabledEvento ? 'A carregar dados...' : 'Escolher Evento'"
                            option-label="nome"
                            :disabled="disabledEvento"
                            :classes="{
                                pointer: -1,
                                wrapper: 'single-select-wrapper',
                                input: 'search-input-mensagem',
                                icons: 'icons',
                                required: 'required',
                                activeClass: 'active',
                                dropdown: 'dropdown'
                            }"
                        ></vue-single-select>
                    </div>
                    <div class="tipo-pessoa-presente">
                        <label>Apenas pessoas presentes: </label>
                        <label class="switch">
                            <input type="checkbox" v-model="filtroPresencas" :disabled="!idEvento">
                            <span :class="idEvento ? 'slider round' :  'slider round disabled'"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="tabela-paginate" v-if="!isLoading">
                <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th scope="col" class="nome-contacto" role="button" :title="ordenacaoNome == 'ASC' ? 'Ordenar Descendentemente' :  'Ordenar Ascendentemente' "><div class="nome-contacto" @click="toggleOrdenacao">Nome <i :class="ordenacaoNome == 'ASC' ? 'bi bi-caret-down' : 'bi bi-caret-up' "></i></div></th>
                        <th scope="col" class="email-contacto">Email</th>
                        <th scope="col" class="telefone-contacto">Telefone</th>
                        <th class="buttons"><a class="bi bi-clipboard2-plus-fill" role="button" @click="adicionarTodosContactos" title="Adicionar todos os contactos presentes na tabela"></a></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(contacto,key) in  allContactos" :key="key">
                    <td class="nome-contacto-td">{{contacto.nome}}</td>
                    <td class="email-contacto-td">{{contacto.email}}</td>
                    <td class="telefone-contacto-td">{{contacto.telefone}}</td>
                    <td class="text-center">
                        <div class="grupo-botoes">
                            <a class="bi bi-plus-circle-fill" role="button" v-on:click="adicionar(contacto)" title="Adicionar Contacto"></a>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
                <Pagination :page=paginateContactos.page :total=paginateContactos.total @pagination="getAllContactosDisponiveis"></Pagination>
            </div>
            <div class="loader" v-else>
                <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
                </div> 
                <h3>A carregar contactos ...</h3>
            </div>
            <div class="background-group">
                <label class="contactos-lista">Contactos pertencentes à lista</label>
                <div class="form-group-filtrar">
                    <form  class="form-group-filtrarLista" @submit.prevent="paginateContactosLista(null)">
                        <input type="text" class="form-control" name="nomeLista" v-model="filtroLista" placeholder="Filtrar por nome, email, telefone">
                        <button type="submit" class="btn btn-secondary filtrar"><i class="bi bi-search"></i> Filtrar</button>
                    </form>
                </div>
            </div>
            <div class="tabela-paginate">
                <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th scope="col" class="nome-contacto">Nome</th>
                        <th scope="col" class="email-contacto">Email</th>
                        <th scope="col" class="telefone-contacto">Telefone</th>
                        <th class="buttons"><a class="bi bi-trash-fill" role="button" @click="removerTodosContactos" title="Remover Todos"></a></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(contacto,key) in  lista.contactos" :key="key">
                    <td class="nome-contacto-td">{{contacto.nome}}</td>
                    <td class="email-contacto-td">{{contacto.email}}</td>
                    <td class="telefone-contacto-td">{{contacto.telefone}}</td>
                    <td class="text-center">
                        <div class="grupo-botoes">
                        <a class="bi bi-trash-fill" role="button" v-on:click="remover(contacto)" title="Remover Contacto"></a>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
                <Pagination :page=paginateLista.page :total=paginateLista.total @pagination="paginateContactosLista"></Pagination>
            </div>
            <div class="botoes">
                <button type="button" class="btn btn-secondary mensagem" @click="registarEditar" :disabled="submiting"><i class="bi bi-device-ssd"></i> {{acao == 'E' ? 'Editar Lista' : 'Criar Lista'}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
    props: {
        listaEdit: {
            type: Object,
            default: null,
            required: true,
        },
        acao:{
            type: String,
            default: 'C',
            required: true
        }
    }, 
    data() {
        return {
            allContactos: [],
            lista: {
                nomeLista: "",
                contactos: [],
            },
            paginateContactos:{
                page: 1,
                total: 0,
            },
            paginateLista:{
                page: 1,
                total: 0,
            },
            contactos: [],
            filtroLista: "",
            filtroContactos: "",
            ordenacaoNome: "ASC",
            filtroTipoPessoa: "todos",
            allEvents: [],
            allLetras: ['Todos','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z' ],
            filtroPorLetra: "Todos",
            idEvento: "",
            filtroPresencas: 0,
            submiting: false,
            disabledEvento: true,
            isLoading: true,
            dialog: false,
            title: "",
            message: "",
            btns: []
        };
    },
    async activated() {
        if(this.acao == 'E'){
           this.lista =  {...this.listaEdit};
           this.contactos = [...this.listaEdit.contactos];
           this.paginateLista.total = this.lista.contactos.length;
           this.paginateContactosLista();
        }

        this.isRemovingAll = false;
        this.presencas = 0;

        this.$axios.get("getAllEventosIdNome")
            .then(response => {
                if (response.status === 401) {
                bus.$emit("logout");
                }
                else
                {
                    this.allEvents = response.data;
                    this.disabledEvento = false;
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    bus.$emit("logout");
                }
            });

        let response = this.formarArrayIdsContactos();
        await response;
        this.getAllContactosDisponiveis();
    },
    watch: {
        filtroLista: function(newFiltro){
            if(newFiltro === ""){
                this.paginateContactosLista();
            }
        },
        filtroContactos: function(newFiltro){
            if(newFiltro === ""){
                this.filtroPorLetra = "Todos";
                this.getAllContactosDisponiveis();
            }
        },
        filtroTipoPessoa: function(newFiltro){
            if(newFiltro != "evento"){
                this.idEvento = "";
                this.filtroPresencas = 0;
                this.getAllContactosDisponiveis();
            }
        },
        idEvento: function(newId){
            if(newId != null){
                this.getAllContactosDisponiveis();
            }
            this.filtroPresencas = 0;
        },
        filtroPresencas: function(newFiltro){
            this.presencas = newFiltro ? 1 : 0;
            this.getAllContactosDisponiveis();
        },

    },
    methods: {
        letraClick(char){
            this.filtroPorLetra = char;
            this.getAllContactosDisponiveis();
        },
        formSubmit()
        {
            this.filtroPorLetra = "Todos";
            this.getAllContactosDisponiveis();
        },
        getAllContactosDisponiveis(page)
        {
            this.isLoading = true;
            this.paginateContactos.page = page || 1;
            this.$axios.post("getAllPessoasSemLista/?pageURL="+(page ? page : 1)+"&filtroURL="+this.filtroContactos+"&filtroTipoURL="+this.filtroTipoPessoa+"&OrdenacaoURL="+this.ordenacaoNome+"&filtroLetraURL="+this.filtroPorLetra+"&idEventoURL="+ (this.idEvento ? this.idEvento.id : "")+"&filtroPresencaURL="+ this.presencas, this.ids)
                .then(response => {
                    if (response.status === 401) {
                        bus.$emit("logout");
                    }
                    else
                    {
                        this.allContactos = response.data.pessoas;
                        this.paginateContactos.total = response.data.total;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        bus.$emit("logout");
                    }
                });
        },
        toggleOrdenacao()
        {
            this.ordenacaoNome = this.ordenacaoNome == "ASC" ? "DESC" : "ASC";
            this.getAllContactosDisponiveis();
        },
        paginateContactosLista(page)
        {
            this.paginateLista.page = page || 1;
            this.lista.contactos = this.contactos.filter(contacto => contacto.nome.toLowerCase().includes(this.filtroLista.toLowerCase()) || contacto.email.toLowerCase().includes(this.filtroLista.toLowerCase()))
            this.paginateLista.total = this.lista.contactos.length;
            this.lista.contactos = this.lista.contactos.slice((this.paginateLista.page - 1) * 20, this.paginateLista.page * 20);
        },
        adicionar(contacto)
        {
            this.allContactos.splice(this.allContactos.indexOf(contacto), 1);
            this.lista.contactos.push(contacto);
            this.contactos.push(contacto);
            this.paginateContactosLista(this.paginateLista.page)
            this.formarArrayIdsContactos();
            if(this.allContactos.length == 0)
            {
                this.getAllContactosDisponiveis(this.paginateContactos.page > 1 ? this.paginateContactos.page - 1 : 1);
            }
        },
        remover(contacto)
        {
            if(!this.isRemovingAll)
            {
                this.allContactos.push(contacto);
            }
            this.lista.contactos.splice(this.lista.contactos.indexOf(contacto), 1);
            this.contactos.splice(this.contactos.indexOf(contacto), 1);
            this.formarArrayIdsContactos();
            if(this.lista.contactos.length == 0)
            {
                this.paginateContactosLista(this.paginateLista.page > 1 ? this.paginateLista.page - 1 : 1)
            }
        },
        async formarArrayIdsContactos()
        {
            this.ids = this.contactos.map(contacto => contacto.id);
        },
        registarEditar() {

            if(this.validarCampos())
            {
                this.submiting = true;
                if(this.acao == 'E')
                {
                    this.$axios.put("putLista/" + this.lista.id, {
                        nomeLista: this.lista.nomeLista,
                        contactos: this.contactos
                    })
                    .then(response => {
                        if (response.status === 401) {
                        bus.$emit("logout");
                        }
                        else if(response.status == 200)
                        {
                            this.$toasted.success("Lista editada com sucesso");
                            bus.$emit("registoEdicaoLista");  
                        }
                        else
                        {
                            this.$toasted.error("Erro ao editar lista");
                        }
                        this.submiting = false;
                    })
                }
                else
                {
                    this.$axios.post("postLista", {
                        nomeLista: this.lista.nomeLista,
                        contactos: this.contactos
                    })
                    .then(response => {
                        if (response.status === 401) {
                        bus.$emit("logout");
                        }
                        else if(response.status == 200)
                        {
                            this.$toasted.success("Lista criada com sucesso");
                            bus.$emit("registoEdicaoLista");  
                        }
                        else
                        {
                            this.$toasted.error("Erro ao criar lista");
                        }
                        this.submiting = false;
                    })
                    .catch(error => {
                        this.$toasted.error("Erro ao criar lista");
                        if (error.response.status === 401) {
                            bus.$emit("logout");
                        }
                    });
                }
            }
        },
        validarCampos()
        {
            if(this.lista.nomeLista == null || this.lista.nomeLista.trim().length == 0)
            {
                this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
                return false;
            }
            else if(this.contactos == null || this.ids.length == 0)
            {
                this.$toasted.error("A lista deve de conter pelo menos 1 contacto");
                return false;
            }

            return true;
        },
        adicionarTodosContactosConfirm()
        {
            let contactoAux = [...this.allContactos];
            contactoAux.forEach((contacto) => {
                this.adicionar(contacto);
            });
            this.getAllContactosDisponiveis();
            this.$toasted.success("Todos os contactos foram adicionados");
        },
        adicionarTodosContactos()
        {
            if(this.allContactos.length == 0)
            {
                this.$toasted.error("Não existem contactos para adicionar");
                return;
            }
            this.title = "Adicionar todos os contactos";
            this.message = "Tem a certeza que pretende executar esta ação ?";
            this.btns = [
                {
                    text: "Sim",
                    function: () => 
                    {
                        this.adicionarTodosContactosConfirm();
                        this.dialog = false;
                    }
                },
                {
                    text: "Não",
                    function: () => this.dialog = false
                }
            ]
            this.dialog = true;
        },
        removerTodosContactosConfirm()
        {
            let contactoAux = [...this.contactos];
            contactoAux.forEach((contacto) => {
                this.remover(contacto);
            });
            if(this.contactos.length == 0)
            {
                this.isRemovingAll = false;
                this.getAllContactosDisponiveis();
                this.$toasted.success("Todos os contactos foram removidos");
            }
        },
        removerTodosContactos()
        {
            if(this.contactos.length == 0)
            {
                this.$toasted.error("Não existem contactos para remover");
                return;
            }
            this.title = "Remover todos os contactos";
            this.message = "Tem a certeza que pretende executar esta ação ?";
            this.btns = [
                {
                    text: "Sim",
                    function: () => 
                    {
                        this.isRemovingAll = true;
                        this.removerTodosContactosConfirm();
                        this.dialog = false;
                    }
                },
                {
                    text: "Não",
                    function: () => this.dialog = false
                }
            ]
            this.dialog = true;  
        }
    }
};
</script>

<style scoped>

.tipo-pessoa-presente {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 50%;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.evento-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.loader{
    margin-bottom: 100px;
}

.btn.btn-secondary.mensagem:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.letras{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3px;
    margin-bottom: 15px;
    justify-content: center;
}

.nome-contacto {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.nome-contacto:hover{
    text-decoration: underline;
}

.bi.bi-caret-down:before, .bi.bi-caret-up:before {
    font-size: 19px !important;
    margin-top: 5px;
}

.btn.btn-secondary.mensagem
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.background-group
{
    background-color: oldlace;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
}

.tipo-pessoa {
    display: flex;
    flex-direction: row;
    width: 50%;
    gap: 10px;
    align-items: center;
}

.tipo-pessoa-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.tipo-pessoa label{
    min-width: 120px;
}

.form-select:focus {
    border-color: #5bb1b3;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(17 144 147);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(141 207 208);
}

button.btn.btn-secondary.filtrar{
    min-width: 110px;
}

.form-group-filtrar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
}

.form-group-filtrarLista {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 80%;
}

.contactos-disponiveis, .contactos-lista{
    font-size: 19px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
}

.grupo-botoes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.nome-contacto-td, .email-contacto-td, .nome-contacto, .email-contacto, .telefone-contacto, .telefone-contacto-td{
  text-align: center;
}

.form-control:focus {
    border-color: #5bb1b3;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(17 144 147);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(141 207 208);
}

.conteudo{
    width: 100%;
    display: flex;
    justify-content: center;
}

.conteudo-group{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.obrigatorio{
    color: red;
}

.form-group-nomeLista{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 50px;
    width: 85%;
}

.botoes{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
}


.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6c757d;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.disabled {
  background-color: #ccc;
  cursor: auto;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #119093;
}

input:focus + .slider {
  box-shadow: 0 0 1px #119093;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  max-width: 40px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
