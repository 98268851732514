<template>
  <div id="page-container"  :style="PanelComponent == 'LoginScreen' ? 'min-height: 100vh !important' : ''">
    <div id="content-wrap">
      <TopBar :PanelComponent="PanelComponent" :user="user"/>
        <div class="conteudoPagina" :style="PanelComponent == 'LoginScreen' ? 'min-height: calc(100vh - 425px); !important' : ''">
          <keep-alive>
            <component
              v-on:auth="doLogin"
              :is="PanelComponent"
              :idEvento="idEvento"
              :idPessoa="idPessoa"
              :cantor="cantor"
              :idEmpresa="idEmpresa"
              :dadosApoio="dadosApoio"
              :dadosPessoa="dadosPessoa"
              :dadosEvento="dadosEvento"
              :dadosEmpresa="dadosEmpresa"
              :dadosInquerito="dadosInquerito"
            ></component>
          </keep-alive>
        </div>
      </div>
      <Footer></Footer>
  </div>
</template>

<script>
import { bus } from "./main.js";

export default {
  name: "Index",
  data: function() {
    return {
      idEvento: null,
      idPessoa: null,
      idEmpresa: null,
      cantor: null,
      dadosApoio: null,
      dadosPessoa: null,
      dadosEvento: null,
      dadosEmpresa: null,
      dadosInquerito : null,
      authenticated: false,
      PanelComponent: null,
      user: {}
    };
  },
  methods: {
    checkAuth: function() {
      return this.authenticated;
    },
    doLogin: function(value) {
      this.authenticated = value;
      if (value) {
        this.$axios.get("users/me")
        .then((response) => {
          this.user = response.data;
        })
        this.swapComponent("PersonsTablesMenu");
      }
    },
    swapComponent: function(comp) {
      this.PanelComponent = comp;
      window.scrollTo(0, 0);
    },
    loadEventPage: id => {
      bus.$emit("changeEventPage", id);
    }
  },
  created: function() {
    if (!this.authenticated) {
      this.swapComponent("LoginScreen");
    } else {
      this.swapComponent("PersonsTablesMenu");
    }

    bus.$on("switchComp", comp => {
      if (this.authenticated) {
        this.swapComponent(comp);
      }
    });

    bus.$on("logout", () => {
      if(sessionStorage.getItem("token"))
      {
        this.$toasted.error("Sessão expirada, faça login novamente");
      }
      this.user = {};
      this.authenticated = false;
      sessionStorage.removeItem("token");
      
      this.$axios.defaults.headers.common.Authorization = "";

      this.$route.path != "/" ?  this.$router.push("/") : this.swapComponent("LoginScreen");
    });

    bus.$on("viewEvento", id => {
      this.idEvento = id;
      this.swapComponent("EventoFichaIndividual");
    });

    bus.$on("viewPessoa", id => {
      this.idPessoa = id;
      this.swapComponent("PessoaFichaIndividual");
    });

    bus.$on("viewCantor", cantor => {
      this.cantor = cantor;
      this.swapComponent("CantorFichaIndividual");
    });

    bus.$on("viewEmpresa", id => {
      this.idEmpresa = id;
      this.swapComponent("EmpresaFichaIndividual");
    });

    bus.$on("editarApoio", dados => {
      this.dadosApoio = dados;
      this.swapComponent("EditarApoio");
    });

    bus.$on("editarPessoa", dados => {
      this.dadosPessoa = dados;
      this.swapComponent("EditarPessoa");
    });
    bus.$on("associarInquerito",dados=>{
      this.idEvento = dados;
      this.swapComponent("tableInqueritos");
    })


    bus.$on("editarEvento", dados => {
      this.dadosEvento = dados;
      this.swapComponent("EditarEvento");
    });

    bus.$on("editInscricoes", dados => {
      this.dadosEvento = dados;
      this.swapComponent("FormInscricoesEvento");
    });

    bus.$on("listarRespostasInquerito", dados => {
      this.dadosInquerito = dados;
      this.swapComponent("RespostasInqueritoTable");
    });

     bus.$on("registarPagamentoInscricao", dados => {
      this.dadosPessoa = dados;
      this.swapComponent("RegistarPagamentoInscricao");
    });

    bus.$on("editarEmpresa", dados => {
      this.dadosEmpresa = dados;
      this.swapComponent("EditarEmpresa");
    });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Actor");

button:focus-visible {
  outline: none;
}

.spinner {
  width: 25px;
  height: 25px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}

.w3-table-all th, .w3-table-all td {
 padding: 8px !important;
}

.tabela table td, .tabela table th{
  vertical-align: middle;
  text-align: center;
}

.badge.text-bg-success
{
  background-color: #28b247;
  width: 75px;
  padding: 4px;
  font-size: 12px;
}

.badge.text-bg-danger
{
  background-color: #dc3545;
  width: 75px;
  padding: 4px;
  font-size: 12px;
}

.badge.text-bg-warning
{
  background-color: #e2cb00;;
  width: 75px;
  padding: 4px;
  font-size: 12px;
}

.badge.text-bg-info
{
  background-color: #616a6a;;
  width: 75px;
  padding: 4px;
  font-size: 12px;
}

.search-input:disabled {
  background-color: #e9ecef !important;
  opacity: 1 !important;

}

.search-input-mensagem:disabled, .search-input-inquerito:disabled  {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}


.texto-centrado-toast {
  text-align: center;
}

.tabela{
  overflow-x: auto;
}

.bi-chevron-left::before, .bi-x::before {
  font-weight: 600 !important;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.v-confirm__titlebar, .v-confirm__content{
  border-radius: 5px !important;
}

.v-confirm__actions .button{
  font-size: 15px !important;
}

.conteudoPagina{
  min-height: calc(105vh - 425px);
  margin-bottom: 60px;
}

#page-container {
  position: relative;
  min-height: 105vh;
}

#content-wrap {
  padding-bottom: 60px;    /* Footer height pode ser necessário alterar*/
}

.mensagem {
  white-space: pre-line;
  text-align: start;
}


</style>