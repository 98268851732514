import { render, staticRenderFns } from "./RegistarEvento.vue?vue&type=template&id=65e1dd36&scoped=true&"
import script from "./RegistarEvento.vue?vue&type=script&lang=js&"
export * from "./RegistarEvento.vue?vue&type=script&lang=js&"
import style0 from "./RegistarEvento.vue?vue&type=style&index=0&id=65e1dd36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e1dd36",
  null
  
)

export default component.exports