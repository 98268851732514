<template>
    <div id="page-container">
        <div id="content-wrap">
            <div class="responderInquerito">
                <top-bar></top-bar>
                <div class="conteudo" v-if="isloading">                
                  <div class="loader">
                    <div class="spinner">
                      <div class="double-bounce1"></div>
                      <div class="double-bounce2"></div>
                    </div> 
                    <h3>A carregar inquerito ...</h3>
                  </div>
                </div>
                <div class="conteudo" v-else-if="this.questoes.ativo === 1 && this.questoes.questoes.length > 0">
                    <h1>Inquérito de Evento</h1>
                    <div class="informacoesEvento">
                        <h3><b>Informações do Evento</b></h3>
                        <p><b>Nome:</b> {{questoes.nomeEvento}}</p>
                        <p><b>Data:</b> {{questoes.dataEvento}}</p>
                        <p><b>Local:</b> {{questoes.localEvento}}</p>
                        <p><b>Tipo de Evento:</b> {{questoes.tipoEvento}}</p>
                    </div>
                    <br>
                    <div class="background">
                        <h3 class="text-center">Questões</h3>
                        <div class="campos">
                            <div class="campos-group">
                                <div v-for="(questao,counter) in questoes.questoes " :key="counter">
                                    <div v-if="questao.tipoVar=='STRING'">
                                        <div class="form">
                                            <div class="nome">
                                                <label>{{counter+1 +' - ' + questao.questao}}<i class="obrigatorio">*</i></label>
                                                <br>
                                                <input type="text" v-model="respostas[counter]" name="nome">
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                    <div v-if="questao.tipoVar=='LONG'">
                                        <div class="form">
                                            <div class="nome">
                                                <label>{{counter+1 +' - ' + questao.questao}}<i class="obrigatorio">*</i></label>
                                                <input type="number" v-model="respostas[counter]" name="nome">
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                    <div  v-if="questao.tipoVar=='INTERVALO'">
                                        <label class="mb-2">{{counter+1 +' - ' + questao.questao}}<i class="obrigatorio">*</i></label>
                                        <div class="radio">
                                            <div class="option" v-for="(valor,key) in questao.intervalo" :key="key">
                                                
                                                <label :for="'valor_' + questao.idQuestao + '_' + key"><input type="radio" :id="'valor_' + questao.idQuestao + '_' + key" :value="valor" v-model="respostas[counter]"> {{valor}}</label>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>

                                <div class="registar">
                                    <button type="submit" v-on:click.prevent="submit()" :disabled="submiting"><i class="bi bi-file-earmark-check-fill"></i> Submeter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="conteudo" v-else>
                    <h1>Link Inválido!</h1>
                    <button type="button" class="btn btn-secondary" @click="pushHome">Voltar à Página Inicial</button>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>

export default {
    data() {
        return {
            questoes: [],
            respostas: [],
            isloading: true,
            submiting: false,
        }

    }, 
    created() {
        this.getInquerito();
    },
    methods: {
        getInquerito()
        {
            this.$axios.get('getInqueritoToken/' + this.$route.params.token).then(response => {
                this.questoes = response.data;
                if(response.status == 400){
                    this.$toasted.error("Ocorreu um erro ao abrir o inquérito");
                }
                else if(Object.keys(response.data).length === 0)
                {
                    this.$toasted.show("O inquérito encontra-se fechado");
                }
                this.isloading = false;
            }).catch(error => {
                if(error.response.status == 400){
                    this.$toasted.error("Ocorreu um erro");
                }
                this.isloading = false;
            })
        },
        clearFields(){
            this.respostas = [];
        },
        pushHome(){
            this.$router.push('/');
        },
        getPosicoesIntervalosNumericos()
        {
            let posicoes = [];
            for(let i = 0; i < this.questoes.questoes.length; i++)
            {
                if(this.questoes.questoes[i].tipoVar == 'LONG')
                {
                    posicoes.push({posicao: i, intervalo: this.questoes.questoes[i].intervaloNumerico});
                }
            }
            return posicoes;
        },
        submit() {

            let respostas_aux = this.respostas.filter(resposta => resposta.trim().length > 0)

            if(respostas_aux.length <= 0 || respostas_aux.length != this.questoes.questoes.length){
                this.$toasted.error("É necessário responder corretamente a todas as questões do inquérito");
                return;
            }

            let posicoes = this.getPosicoesIntervalosNumericos();

            let intervaloNumericoValido = posicoes.some((posicaoAux) => {
                let intervalo = posicaoAux.intervalo.split(";");
                if(this.respostas[posicaoAux.posicao] < parseInt(intervalo[0]) || this.respostas[posicaoAux.posicao] > parseInt(intervalo[1]))
                {
                    this.$toasted.error("O valor da questão " + (posicaoAux.posicao + 1) + " não está dentro do intervalo");
                }
                else if(this.respostas[posicaoAux.posicao] % 1 !== 0 || this.respostas[posicaoAux.posicao].includes("."))
                {
                    this.$toasted.error("O valor da questão " + (posicaoAux.posicao + 1) + " não é um número inteiro");
                }

                return  this.respostas[posicaoAux.posicao] % 1 !== 0 || this.respostas[posicaoAux.posicao].includes(".") || this.respostas[posicaoAux.posicao] < parseInt(intervalo[0]) || this.respostas[posicaoAux.posicao] > parseInt(intervalo[1]) 
            })

            if(!intervaloNumericoValido)
            {
                this.submiting = true;
                this.$axios.post('postResposta/' + this.$route.params.token, this.respostas)
                    .then((response) => {
                        if(response.status == 201){
                            this.$toasted.show('Obrigado pela sua resposta');
                            this.clearFields();
                        }
                        else if(response.status == 406){
                            this.$toasted.error("O inquérito já não se encontra aberto");
                            this.getInquerito();
                        }
                        else
                        {
                            this.$toasted.error("Ocorreu um erro a submeter o seu inquérito");
                        }
                        this.submiting = false;
                

                    }).catch(error => {
                        if(error.response.status == 400){
                            this.$toasted.error("Ocorreu um erro");
                        }
                    })
            }
    
            
        }
    }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Actor");

.responderInquerito .registar button:hover:disabled{
    cursor: not-allowed;
}

.campos-group {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.obrigatorio{
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.spinner {
  width: 25px;
  height: 25px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 60px;    /* Footer height */
}

.responderInquerito {
    padding-bottom: 60px;    /* Footer height */
}

.responderInquerito .conteudo {
    min-height: calc(100vh - 425px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.responderInquerito .background {
    display: inline-grid;
    grid-row-gap: 10px;
    grid-column-gap: 50px;
    background-color: oldlace;
    width: 100%;

}

.responderInquerito .campos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.responderInquerito .radio{
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
}

.responderInquerito .radio .option{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.responderInquerito .informacoesEvento {
    margin: auto;
    width: 30%;
    padding: 10px;
    margin-top: 3%;
    border: 3px solid lightgray;
    overflow: hidden;
}

.responderInquerito .nome input{
    margin-top:10px;
    width: 100%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
}

.responderInquerito .registar {
    margin-bottom: 30px;
    grid-column-start: 2;
    grid-row-start: 5;
    /* justify-self: end; */
    display: flex;
    margin-top: 20px;
}

.responderInquerito .registar button {
    height: 50px;
    font-size: medium;
    cursor: pointer;
    border: none;
    background-color: #e99d72;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
}

@media (max-width: 620px)
{
    .informacoesEvento{
        width: 70% !important;
    }

    .campos-group{
        width: 100% !important;
        padding: 10px;
    }
}

@media (max-width: 1300px)
{
    .campos-group{
        width: 70% !important;
        padding: 10px;
    }
}

@media (max-width: 452px)
{
    .responderInquerito{
        margin-bottom: 60px;
    }
}
</style>