<template>
  <div class="formNovoParticipante">
    <br>
    <br>
    <div class="form">
        <!-- já frequentou, user existente -->
      <div class="novoParticipante">
        <label>Pessoa:<i class="obrigatorio">*</i></label>
        <br>
        <!--
        <input name="inputPessoa" v-model="input.inputPessoa" list="listaPessoa">
        <datalist id="listaPessoa">
          <option v-for="(pessoa, key) in allPessoa" :key="key">{{pessoa.idPessoa}}: {{pessoa.nome}}</option> 
        </datalist>
        -->
        <vue-single-select
                v-model="input.inputPessoa"
                :options="allPessoaNotEvento"
                :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome,email,telemóvel ou nif'"
                option-label="nome"
                :disabled="disabledPessoa"
        ></vue-single-select>
      </div>
      <div class="registar">
        <button type="submit" v-on:click.prevent="submit()" :disabled="submiting">
          <i class="bi bi-file-earmark-check-fill"></i> Registar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  props: {
    idEvento: {
      required: true
    }
  },
  mounted() {
    this.$axios.get("getPessoasNaoEmEvento/" + this.idEvento)
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allPessoaNotEvento = response.data;
          this.disabledPessoa = false;
        }
      })
      .catch(error => {
        this.error = error;
      });
  },
  data() {
    return {
      input: {
        inputPessoa: "",
      },
      allPessoaNotEvento: [],
      disabledPessoa: true,
      submiting: false,
    };
  },
  methods: {
    submit: function() {

        if(this.input.inputPessoa == "" || this.input.inputPessoa == null){
          this.$toasted.error("Selecione uma pessoa para participar no evento");
          return;
        }

        this.submiting = true;
        //verificar se ainda não participou em eventos
        this.$axios.get("getEventosParticipante/" + this.input.inputPessoa.id)
          .then(response => {
            if (response.status === 401) {
              bus.$emit("logout");
              return;
            }
            let hasEventosAux = response.data.total ? true : false;
            this.$axios.post("postParticipacaoEvento/",
                {
                  idParticipante: this.input.inputPessoa.id,
                  idEvento: this.idEvento,
                  flagPrimeiroEvento: hasEventosAux
                },
              )
              .then((response) => {
                if (response.status === 401) {
                  bus.$emit("logout");
                }
                else if (response.status === 201){
                  this.$toasted.success("Participante registado com sucesso");
                  this.allPessoaNotEvento.splice(this.allPessoaNotEvento.findIndex(pessoa => pessoa.id == this.input.inputPessoa.id), 1);
                  this.input.inputPessoa = "";
                  bus.$emit("submitParticipante");
                }
                else if(response.status == 409)
                {
                  this.$toasted.error("A pessoa que está a tentar inserir já se encontra registado no evento");
                }
                else
                {
                  this.$toasted.error("Erro ao registar participante");
                }
                this.submiting = false;
              })
              .catch(error => {
                if(error.response.status === 401){
                  bus.$emit("logout");
                }
                this.$toasted.error("Erro ao inserir participante");
              });
              return;
          }
          )
          .catch(error => {
           if(error.response.status === 401){
            bus.$emit("logout");
            }
          });
      }
    },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.formNovoParticipante .registar button:hover:disabled
{
  background-color: #e99d72;
  cursor: not-allowed;
}

.formNovoParticipante {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.formNovoParticipante .form {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  background-color: oldlace;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.formNovoParticipante .form .obrigatorio{
  color: red;
}

.formNovoParticipante .novoParticipante {
  width: 80%;
}

.formNovoParticipante .registar {
  margin-bottom: 15px;
  margin-top: 10px;
}

.formNovoParticipante .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.formNovoParticipante .registar button:hover {
  background-color: #fab995;
}
</style>