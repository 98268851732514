
<template>
  <div class="relatorioTopsOradores">
    <br>
    <h2>Classificações de Oradores:</h2>
    <br>
    <div class="dados">
      
      <div id="fields" class="tabela">
        <h3> Oradores com mais Palestras: </h3>
        <table class="w3-table-all" id="tabela">
          <!--ID, nome, tipo, data, local -->
          <th> Nome </th>
          <th> Número de Eventos Apresentados </th>
          <tr class="conteudo" v-for="(row, tableKey) in oradoresPalestras" :key="tableKey">
            <td>{{row.nome}}</td>
            <td>{{row.totalEventosApresentados}}</td>
          </tr>
        </table>
        <br>
        <h3> Oradores com mais público seu nos seus eventos: </h3>
        <table class="w3-table-all" id="tabela">
          <!--ID, nome, tipo, data, local -->
          <th> Nome </th>
          <th> Total Público</th>
          <tr class="conteudo" v-for="(row, tableKey) in oradoresPublicoSeuPalestras" :key="tableKey">
            <td>{{row.nomeOrador}}</td>
            <td>{{row.totalReferalsEmEventos}}</td>
          </tr>
        </table>
        <br>
        <h3> Oradores que mais compareceram noutros eventos: </h3>
        <table class="w3-table-all" id="tabela">
          <!--ID, nome, tipo, data, local -->
          <th> Nome </th>
          <th> Total Participações </th>
          <tr class="conteudo" v-for="(row, tableKey) in oradoresParticipacoesPalestras" :key="tableKey">
            <td>{{row.nome}}</td>
            <td>{{row.totalParticipacoesOutrosEventos}}</td>
          </tr>
        </table>
        <br>
        <!-- <h3> Oradores com mais presenças em eventos especiais: </h3>
        <table class="w3-table-all" id="tabela"> -->
          <!--ID, nome, tipo, data, local -->
          <!-- <th> Nome </th>
          <th> Total Participações  </th>
          <tr class="conteudo" v-for="(row, tableKey) in oradoresParticipacoesEspeciais" :key="tableKey">
            <td>{{row.nome}}</td>
            <td>{{row.totalParticipacoesEventosEspeciais}}</td>
          </tr>
        </table>
        <br> -->
        <h3> Oradores que mais apoiaram financeiramente: </h3>
        <table class="w3-table-all" id="tabela">
          <!--ID, nome, tipo, data, local -->
          <th> Nome </th>
          <th> Total Apoio </th>
          <tr class="conteudo" v-for="(row, tableKey) in oradoresApoiosFinanceiros" :key="tableKey">
            <td>{{row.nome}}</td>
            <td>€{{row.totalDonativos}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  methods: {
  },
  activated() {
    this.$axios.get("getRelatorioOradoresPorNumPalestras")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.oradoresPalestras = response.data;
        }
      })
      .catch(() => {

      });
    this.$axios.get("getRelatorioOradoresPorPublicoSeu")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.oradoresPublicoSeuPalestras = response.data;
        }
      })
      .catch(() => {

      });
    this.$axios.get("getRelatorioOradoresParticipacoes")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.oradoresParticipacoesPalestras = response.data;
        }
      })
      .catch(() => {

      });

    this.$axios.get("getRelatorioOradoresApoiosFinanceiros")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.oradoresApoiosFinanceiros = response.data;
        }
      })
      .catch(() => {

      });
  },
  data() {
    return {
      oradoresPalestras: [],
      oradoresPublicoSeuPalestras: [],
      oradoresParticipacoesPalestras: [],
      oradoresApoiosFinanceiros: []
    };
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.tabela{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.relatorioTopsOradores h3 {
  text-align: center;
  background-color: linen;
  width: 100%;
}

.relatorioTopsOradores h2 {
  text-align: center;
}
.relatorioTopsOradores{
  font-family: "Open Sans";

}

.relatorioTopsOradores #tabela {
  width: 70%;
}
.relatorioTopsOradores .tabela table th {
  border: 1px solid #ccc;
}
.relatorioTopsOradores .tabela table td {
  border: 1px solid #ccc;
}
</style>