
<template>
<div>
  <v-confirm
    v-model="dialog"
    :title="title"
    :message="message"
    :btns="btns"
  />
  <div class="tabelaStaff">
    <div class="loader" v-if="!promoverStaff && isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar staffs ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="buttonAdicionarApoio">
          <button v-on:click="toggleRegistarApoio()">
            <template v-if="registarApoio">
              <i class="bi bi-chevron-left"></i> Cancelar
            </template>
            <template v-else>
               <i class="bi bi-plus-circle"></i> Registar Apoio
            </template>
          </button>
          <button v-on:click="togglePromoverStaff()" class="promover-staff">
            <template v-if="promoverStaff">
              <i class="bi bi-chevron-left"></i> Cancelar
            </template>
            <template v-else>
               <i class="bi bi-person-check-fill"></i> Promover Staff
            </template>
          </button>
        </div>
        <div class="novoApoio">
          <template v-if="registarApoio">
            <keep-alive>
              <RegistarApoio/>
            </keep-alive>
          </template>
        </div>
        <div class="novoStaff">
          <template v-if="promoverStaff">
            <keep-alive>
              <FormNovoStaff></FormNovoStaff>
            </keep-alive>
          </template>
        </div>

        <div class="tabela-paginate">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <!--<th>Dados</th>-->
            </tr>
            <tr class="conteudo" v-for="(users, key) in allStaff" :key="key">
              <td class="idPessoa">{{ users.idPessoa }}</td>
              <td class="verButton" v-on:click="ver(users.idPessoa)" title="Ver Ficha de Pessoa">
                  {{ users.nome }}
              </td>
              <td>{{ users.email}}</td>
              <td>{{users.telefone}}</td>
              <td class="buttons">
                <!--<div class="verButton">
                    <a v-on:click="ver(users.idPessoa)"> Ver </a>
                </div>-->
                <div class="editarButton">
                  <a class="bi bi-pencil-square" v-on:click="editar(users)" title="Editar"></a>
                </div>
                <div class="editarButton">
                  <a class="bi bi-person-x-fill" @click="despromover(users)"  title="Remover Staff"></a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=page :total=total @pagination="getAllStaffs"></Pagination>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  mounted() {
    bus.$on("refreshStaff", () => {
      this.toggleRegistarApoio();
      this.getAllStaffs();
    });
    bus.$on("promoteStaff", () => {
      this.getAllStaffs();
    });
  },
  activated() {
    this.getAllStaffs();
  },
  data() {
    return {
      allStaff: [],
      registarApoio: false,
      promoverStaff: false,
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      isLoading: true,
    };
  },
  methods: {
    getAllStaffs(page){
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllStaff?pageURL="+(page ? page : 1))
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.allStaff = response.data.staff;
            this.total = response.data.total;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
        });
    },
    toggleRegistarApoio() {
      if(this.promoverStaff){
        this.togglePromoverStaff();
      }
      this.registarApoio = !this.registarApoio;
    },
    togglePromoverStaff() {
      if(this.registarApoio){
        this.toggleRegistarApoio();
      }
      this.promoverStaff = !this.promoverStaff;
    },
    ver(idPessoa) {
      bus.$emit("viewPessoa", idPessoa);
    },
    editar(data) {
      this.$axios.get("getDadosPessoa/" + data.idPessoa)
        .then(response => {
          if(response.status === 401){
              bus.$emit("logout");
          }
          else
          {
            bus.$emit("editarPessoa", response.data);
          }
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
        });
    },
    despromoverStaff(user)
    {
      this.$axios.delete("despromoverStaff/"+user.idPessoa)
        .then(response => {
          if(response.status === 401){
              bus.$emit("logout");
          }
          else if(response.status === 200)
          {
            this.$toasted.success("Staff removido com sucesso");
            this.getAllStaffs();
          }
          else
          {
            this.$toasted.error("Erro ao remover staff");
          }
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
        });
    },
    despromover(user) {
      this.title = "Remover Staff";
      this.message = "Tem a certeza que pretende remover o staff - " + user.nome +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.despromoverStaff(user);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.registar {
    width: 100%;
    margin-bottom: 20px;
}

.promover-staff{
  margin-left: 5px;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.bi-person-x-fill::before {
    content: "\f4df";
    /* font-weight: 600 !important; */
    font-size: 19px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.bi.bi-chevron-left::before{
  font-weight: 600 !important;
}

.tabelaStaff h1 {
  text-align: center;
}

.tabelaStaff {
  font-family: "Open Sans";
}

.tabelaStaff .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}
.tabelaStaff .tabela {
  width: 100%;
  margin-bottom: 20px;
}

.tabelaStaff #tabela {
  width: 70%;
  margin: auto;
}
.tabelaStaff .dados .idPessoa {
  width: 3%;
}
.tabelaStaff .tabela table td {
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}

.tabelaStaff .dados table td {
  width: 25%;
}

.tabelaStaff .tabela table th {
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}

.tabelaStaff .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabelaStaff .verButton {
  cursor: pointer;
}
.tabelaStaff .verButton a:hover {
  font-weight: bold;
}

.tabelaStaff .editarButton {
  cursor: pointer;
}

.tabelaStaff .editarButton a:hover {
  font-weight: bold;
  font-size: 13px;
}

.tabelaStaff .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}

.tabelaStaff .buttonAdicionarApoio {
  margin-bottom: 5px;
  margin-left: 15%;
}

.tabelaStaff .buttonAdicionarApoio button {
  text-align: center;
  cursor: pointer;
  background-color: #a7ec82;
  border: none;
  border-radius: 4px;
}

.tabelaStaff .buttonAdicionarApoio button:hover {
  background-color: #72d33d;
}
</style>