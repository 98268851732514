<template>
  <div class="formAddCantor">
    <div class="form">
        <div class="buttonsIntervalos-group">
          <h5><b>Associar Cantores</b></h5>
          <div class="buttonsIntervalos">
              <div class="buttonAdicionarCantor">
                  <button class="bi bi-plus-lg" @click="adicionarValor()"></button>
              </div>
              <div class="buttonEliminarCantor">
                  <button class="bi bi-trash-fill" @click="deleteValor()"></button>
              </div>
          </div>
        </div>
        <div  class="intervalo-opcoes" v-for="(valor, counter) in allCantores" v-bind:key="counter">
            <label class="label-valor" for="valor">{{counter + 1}}º Cantor: <i class="obrigatorio">*</i></label>
            <template v-if="!valor.novoCantor">
              <vue-single-select
                v-model.lazy="allCantores[counter].cantor"
                :options="allCantoresRegisted"
                :placeholder="disabledCantor ? 'A carregar dados...' : 'Procurar por nome'"
                option-label="nome"
                style="width: 50% !important;"
                :disabled="disabledCantor"
              ></vue-single-select>
            </template>
            <template v-else>
              <input id="valor" type="text" v-model.lazy="allCantores[counter].cantor" required>
            </template>
            <div class="novoCantor-Checkbox">
              <input type="checkbox" class="novoCantor" :id="'novoCantor_' + counter" @click="novoCantor(counter)" title="Novo Cantor"> 
              <label :for="'novoCantor_' + counter">Novo Cantor</label>
            </div>
        </div>
      <div class="registar">
          <button type="submit" v-on:click.prevent="registarCantores()" :disabled="submiting"><i class="bi bi-file-earmark-check-fill"></i> Registar</button>
      </div>
    </div>
  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  props: {
    idEvento: {
      required: true
    },
    cantores:{
      required: true,
      type: Array
    },
  },
  data() {
    return {
      allCantores:[{cantor:"", novoCantor: false}],
      allCantoresRegisted: [],
      disabledCantor: true,
      submiting: false,
    };
  },
  activated() {
    this.$axios.get("getAllCantoresIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allCantoresRegisted = response.data;
          this.disabledCantor = false;
        }
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
  },
  methods: {
    novoCantor(index)
    {
      this.allCantores[index].novoCantor = !this.allCantores[index].novoCantor;
      this.allCantores[index].cantor = "";
    },
    adicionarValor() {
      this.allCantores.push({cantor:"", novoCantor: false});
    },
    deleteValor() {
        if(this.allCantores.length === 1){
          this.$toasted.error("Para registar cantores, é necessário adicionar pelo menos um cantor");
        }
        else
        {
          this.allCantores.splice(this.allCantores.length-1, 1);
        }
    },
    registarCantores() {
      if(this.allCantores.filter(element => (element.cantor == null || typeof element.cantor != "object" && element.cantor.trim().length == 0)).length > 0){
          this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
          return;
      }

      let jaRegistado = false;
      let idCantores = this.allCantores.filter(cantor => !cantor.novoCantor).map(element => element.cantor.id);

      this.cantores.forEach(cantor => {
        if(idCantores.find(cantor_aux => cantor_aux == cantor.idCantor))
        {
          this.$toasted.error("Um ou mais cantores, já se encontra associado ao evento");
          jaRegistado = true;
          return;
        }
      });

      if (jaRegistado) return;

      let novosCantores = this.allCantores.filter(cantor => cantor.novoCantor);
      let idEvento = this.idEvento;

      this.submiting = true;
      if(novosCantores.length > 0)
      {
        this.$axios.post("postCantor",
          {
            cantores: novosCantores.map(cantor => cantor.cantor),
            
          }).then(response => {
            if(response.status == 401)
            {
              bus.$emit("logout");
            }
            else if (response.status === 201) {
              let idCantores = [...response.data, ...new Set(this.allCantores.filter(cantor => !cantor.novoCantor).map(cantor => cantor.cantor.id))];
              this.postConcerto(idCantores, idEvento);
            }
            else
            {
              this.$toasted.error("Erro ao registar cantores");
              this.submiting = false;
            }
          }).catch((errors) => {
            if (errors.response.status === 400) {
              this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
            }
            else if (errors.response.status === 401) {
              bus.$emit("logout");
            }
          });
      }
      else
      {
        this.postConcerto([...new Set(this.allCantores.map(cantor => cantor.cantor.id)) ], idEvento);
      }
    },
    postConcerto(idCantores, idEvento)
    {
      this.$axios.post("postConcerto",
      {
        idConcerto: idEvento,
        idCantores: idCantores
      }).then(response => {
        if (response.status === 401) 
        {
          bus.$emit("logout");
        }
        else if (response.status === 201) 
        {
          this.$toasted.success("Cantores inseridos com sucesso");
          bus.$emit("submitCantores");
        }
        else if(response.data == "duplicated")
        {
          this.$toasted.error("Concerto com os cantores selecionados já existente");
        }
        else
        {
          this.$toasted.error("Erro na inserção de concerto");
        }
        this.submiting = false;
      }).catch((errors) => {
        if (errors.response.status === 400) {
          this.$toasted.error("Campos incorrectos ou incompletos");
        }
        if (errors.response.status === 401) {
          bus.$emit("logout");
        }
      });
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.registar button:hover:disabled{
  background-color: #e99d72;
  cursor: not-allowed;
}

.novoCantor-Checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

input.novoCantor {
  width: 14px;
  height: 14px;
}

.buttonsIntervalos-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input#valor {
    width: 50%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
}

.intervalo-opcoes {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
}

.obrigatorio{
  color: red;
}

.formAddCantor .form {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  background-color: oldlace;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}

.buttonAdicionarCantor{
  float: left;
}

.buttonsIntervalos{
  margin-top:10px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.buttonsIntervalos button{
  background-color: lightgreen;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 4px;
}

.buttonsIntervalos button:hover{
  background-color: green;
}

.intervalo-opcoes {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

input#valor {
    width: 50%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
}

.formNovoParticipante {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.formNovoParticipante .form {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  background-color: oldlace;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.formNovoParticipante .form .obrigatorio{
  color: red;
}

.formNovoParticipante .novoParticipante {
  width: 80%;
}

.formNovoParticipante .registar {
  margin-bottom: 15px;
  margin-top: 10px;
}

.formNovoParticipante .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.formNovoParticipante .registar button:hover {
  background-color: #fab995;
}
</style>