<template>
  <div id="page-container">
    <div id="content-wrap">
      <div class="inscricaoEvento">
        <top-bar></top-bar>
        <br>
        <div class="conteudo" v-if="isloading">                
          <div class="loader">
            <div class="spinner">
              <div class="double-bounce1"></div>
              <div class="double-bounce2"></div>
            </div> 
            <h3>A carregar evento ...</h3>
          </div>
        </div>
        <div class="conteudo" v-else-if="isEventoValido">
          <div id="inscricaoEvento" class="w3-panel">
            <h1>Inscrição em Evento</h1>
            <div class="informacoesEvento">
                <h3><b>Informações do Evento</b></h3>
                <p><b>Nome:</b> {{evento.nomeEvento}}</p>
                <p><b>Data:</b> {{evento.dataEvento}}</p>
                <p><b>Local:</b> {{evento.localEvento}}</p>
                <p><b>Tipo de Evento:</b> {{evento.tipoEvento}}</p>   
            </div>
            <br>
            <div class="buttons">
              <div class="primeiroEvento">
                <button class="primeiroEvento" type="submit" v-on:click.prevent="isPrimeiroEvento = 1">
                  <i class="bi bi-plus-lg"></i> É o Primeiro Evento</button>
              </div>
                <div class="jaParticipou">
                <button class="jaParticipou" type="submit" v-on:click.prevent="isPrimeiroEvento = 0">
                  <i class="bi bi-person-fill"></i> Já Participei
                </button>
              </div>
            </div>
            <div id="fields" v-if="isPrimeiroEvento == 0" class="campos">
              <label class="info">Preencha um dos campos para se inscrever</label>
              <div class="form">
                Email:
                <label>
                  <input type="email" name="email" v-model="input.email">
                </label>
                <br>Telemóvel:
                <label>
                  <input type="number" name="telemovel" v-model="input.telemovel">
                </label>
                <br v-if="!isRegisting">
                <div class="loader" v-if="isRegisting">
                  <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                  </div> 
                  <label>A efectuar a inscrição, aguarde ...</label>
                </div>
                <div class="registarInscricao">
                  <button type="submit" :disabled="isRegisting" v-on:click.prevent="registar()">
                    <i class="bi bi-file-earmark-check-fill"></i> Registar Inscrição
                  </button>
                </div>
            </div>
            </div>
            <div class="novaPessoa" v-if="isPrimeiroEvento == 1">
            <div class="form">
              <div class="nome">
                <label>Nome:<i class="obrigatorio">*</i></label>
                <br>
                <input type="text" v-model="inputPessoa.nome" name="nome">
              </div>
              <div class="email">
                <label>Email:<i class="obrigatorio">*</i></label>
                <br>
                <input type="text" v-model="inputPessoa.email" name="email">
              </div>
              <div class="telefone">
                <label>Telefone:<i class="obrigatorio">*</i></label>
                <br>
                <input type="text" v-model="inputPessoa.telefone" name="telefone">
              </div>
              <div class="genero">
                <label>Género:<i class="obrigatorio">*</i></label>
                <br>
                <input type="radio" v-model="inputPessoa.genero" name="m" value="M" id="m"><label for="m" class="label-genero"> Masculino </label>
                <br>
                <input type="radio" v-model="inputPessoa.genero" name="f" value="F" id="f"><label for="f" class="label-genero"> Feminino </label>
                <br>
                <input type="radio" v-model="inputPessoa.genero" name="o" value="O" id="o"><label for="o" class="label-genero"> Outro </label>
                <br>
              </div>
              <div class="receberEmail-group">
                <label>Receber Emails informativos:<i class="obrigatorio">*</i></label>
                <input type="checkbox" class="receberEmail" v-model="inputPessoa.receberEmail">
              </div>
              <div class="zona">
                <label>Zona:<i class="obrigatorio">*</i></label>
                <br>
                <input type="text" v-model="inputPessoa.zona" name="zona">
              </div>
              <div class="idade">
                <label>Data Nasc.:<i class="obrigatorio">*</i></label>
                <br>
                <input type="date" v-model="inputPessoa.dataNasc" name="idade" :max="maxDate">
              </div>
              <div class="nif">
                <label>NIF:</label>
                <br>
                <input type="text" v-model="inputPessoa.nif" name="nif">
              </div>
              <div class="comoConheceu">
                <label>Como Conheceu:<i class="obrigatorio">*</i></label>
                <div class="comoConheceuInputs">
                  <select v-model="inputPessoa.comoConheceu" @change="changeSelect">
                    <option>Pessoa</option>
                    <option value="Local">Folheto em Local</option>
                    <option>Redes Sociais</option>
                    <option>E-Mail</option>
                  </select>
                  <input type="text" v-if="showLocal" v-model="inputPessoa.atravesDe" name="local">
                </div>
              </div>
              <div class="loader" v-if="isRegisting">
                <div class="spinner">
                  <div class="double-bounce1"></div>
                  <div class="double-bounce2"></div>
                </div> 
                <label>A efectuar a inscrição, aguarde ...</label>
              </div>
            <div class="registar">
              <button type="submit" :disabled="isRegisting" v-on:click.prevent="registarPessoa()">
                <i class="bi bi-file-earmark-check-fill"></i> Registar Inscrição
              </button>
            </div>
        </div>
            </div>
          </div>
        </div>
        <div v-else class="conteudo-invalido">
            <h1>Link Inválido!</h1>
            <button type="button" class="btn btn-secondary" @click="pushHome">Voltar à Página Inicial</button>
        </div>
      </div>
    </div>
      <Footer></Footer>
  </div>
</template>

<script>

export default {
  data() {
    return {
      input: {
        email: "",
        telemovel: ""
      },
      inputPessoa:{
        nome: "",
        email: "",
        genero: "",
        telefone: "",
        zona: "",
        nif: null,
        dataNasc: "",
        comoConheceu: "Pessoa",
        atravesDe: "",
        idInserido : null,
        receberEmail: 1
      },
      isEventoValido: false,
      isloading: true,
      evento : null,
      pessoa : null,
      isPrimeiroEvento :-1,
      showLocal: false,
      isRegisting: false,
      maxDate: null
    };
  },
    created() {
      this.$axios.get(`getDadosEventosByToken/${this.$route.params.token}`)
      .then((response) => {
        if(response.status === 404 || response.status === 410){
            this.isEventoValido = false   
        }
        else
        {
          this.evento = response.data
          this.isEventoValido = true   
        }
        this.isloading = false;
      })
      .catch(error => {
          if(error.response.status === 404 || error.response.status === 410){
              this.isEventoValido = false   
          }
          this.isloading = false;
      });

      let MyDate = new Date();
      this.maxDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                        ('0' + MyDate.getDate()).slice(-2);
  
  },
  methods: {
    changeSelect()
    {
      if(this.inputPessoa.comoConheceu !== "Local")
      {
        this.showLocal = false;
      }
      else
      {
        this.showLocal = true;
      }
    },
    pushHome(){
      this.$router.push('/');
    },
    validarEmail(email) {

      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!email.match(validRegex)) {

        return false;
      }
      return true;
    },
    validar(email,telemovel){
        if(email != ""){
          if(!this.validarEmail(email)){
            this.$toasted.error("Formato de email inválido")
            return false;
          }
        }else if(telemovel != ""){
            if(this.input.telemovel.length < 9){
                this.$toasted.error("Número de telemóvel tem de conter no mínimo 9 dígitos");
                return false;
            }
           if(!/^\d+$/.test(telemovel)){
                this.$toasted.error("Número de telemóvel só pode conter dígitos");
                return false;
           }
        }else{
            this.$toasted.error("Por favor insira um endereço de email ou um número de telemóvel");
            return false;
        }

        return true;
    },
    registar: function() {
        if(!this.validar(this.input.email,this.input.telemovel)){
            return;
        }
        this.isRegisting = true;
        if(this.input.email != ""){
               this.$axios.post("postParticipacaoEventoEmail",
                  {
                    email : this.input.email,
                    idEvento : this.evento.idEvento
                  })
              .then(() => {
                this.$toasted.show("Confirme a inscrição no seu email");
                this.clearFields();
                this.isPrimeiroEvento = -1;
                this.isRegisting = false;
              })
              .catch(() => {
                this.$toasted.error("Erro a registar a inscrição");
              });
               
        }else {
          this.$axios.post("postParticipacaoEventoTelemovel",
            {
              telemovel: this.input.telemovel,
              idEvento: this.evento.idEvento
            })
            .then(() => {
              this.$toasted.show("Confirme a inscrição no seu email");
              this.clearFields();
              this.isPrimeiroEvento = -1;
              this.isRegisting = false;
            })
            .catch(() => {
              this.$toasted.error("Erro a registar a inscrição");
            });
        }

    },
     clearFields(){
      this.inputPessoa.jaFreq = true,
      this.inputPessoa.nome = "",
      this.inputPessoa.inputPessoa = "",
      this.inputPessoa.email = "",
      this.inputPessoa.genero = "",
      this.inputPessoa.telefone = "",
      this.inputPessoa.zona = "",
      this.inputPessoa.nif = "",
      this.inputPessoa.dataNasc = "",
      this.inputPessoa.comoConheceu = "Pessoa",
      this.inputPessoa.atravesDe = "",
      this.input.email = "",
      this.input.telemovel = "",
      this.inputPessoa.receberEmail = 1
      
    },
    registarPessoa(){
       if (
          this.inputPessoa.nome == "" ||
          this.inputPessoa.nome == null ||
          this.inputPessoa.email == "" ||
          this.inputPessoa.email == null ||
          this.inputPessoa.telefone == "" ||
          this.inputPessoa.telefone == null ||
          this.inputPessoa.zona == "" ||
          this.inputPessoa.zona == null ||
          this.inputPessoa.genero == "" ||
          this.inputPessoa.genero == null ||
          this.inputPessoa.dataNasc == "" ||
          this.inputPessoa.dataNasc == null ||
          this.inputPessoa.receberEmail == null
        ) {
           this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
          return;
        }

      if(this.inputPessoa.nome.trim().length == 0 || this.inputPessoa.zona.trim().length == 0){
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
        return;
      }

      if(!this.validar(this.inputPessoa.email,this.inputPessoa.telefone)){
        return;
      }

      if(this.inputPessoa.nif != null){
        if(this.inputPessoa.nif != "" && this.inputPessoa.nif.trim().length == 0)
        {
          this.$toasted.error("NIF inválido");
          return;
        }
        else if(this.inputPessoa.nif != "" && this.inputPessoa.nif.length != 9)
        {
          this.$toasted.error("NIF de tamanho inválido");
          return;
        }
      }

      if(this.inputPessoa.genero != "M" && this.inputPessoa.genero != "F" && this.inputPessoa.genero != "O"){
        this.$toasted.error("Campo Género inválido");
        return;
      }

      if(this.inputPessoa.comoConheceu != "Pessoa" && this.inputPessoa.comoConheceu != "Local" && this.inputPessoa.comoConheceu != "Redes Sociais" && this.inputPessoa.comoConheceu != "E-Mail"){
        this.$toasted.error("Campo Como Conheceu inválido");
        return;
      }

      if(new Date(this.inputPessoa.dataNasc).getTime() > new Date().getTime() ){
        this.$toasted.error("Data de nascimento inválida");
        return;
      }


      if(this.inputPessoa.comoConheceu === "Local" && (this.inputPessoa.atravesDe == null || this.inputPessoa.atravesDe == "")){
          this.$toasted.error("É obrigatório indicar o local onde conheceu");
          return;
      }

      this.isRegisting = true;
      
      this.$axios.post(`postPessoaInscricao/${this.$route.params.token}`,
            {
              nome: this.inputPessoa.nome,
              email: this.inputPessoa.email,
              telefone: this.inputPessoa.telefone,
              zona: this.inputPessoa.zona,
              dataNasc: this.inputPessoa.dataNasc,
              genero: this.inputPessoa.genero,
              nif: this.inputPessoa.nif == "" ? null : this.inputPessoa.nif,
              comoConheceu:
                this.inputPessoa.comoConheceu === "Local"
                  ? this.inputPessoa.atravesDe
                  : this.inputPessoa.comoConheceu,
              atravesQuem: null,
              receberEmail: this.inputPessoa.receberEmail == true ? 1 : this.inputPessoa.receberEmail == false ? 0 : this.inputPessoa.receberEmail
            }
          )
          .then(response => {
            if(response.status == 400 && response.data === "duplicated"){
              this.$toasted.error("Numero telemóvel, nif ou email já existentes! Possível pessoa duplicada");
              this.isRegisting = false;
            }
            else if(response.status == 200){

              this.inputPessoa.idInserido = response.data;

              /*REGISTA A SUA INSCRICAO*/
              this.$axios.post("postParticipacaoEventoEmail",
                {
                  email: this.inputPessoa.email,
                  idEvento: this.evento.idEvento
                }).then(() => {

                  this.$toasted.show("Confirme a inscrição no seu endereço de email");
                  this.clearFields();
                  this.isPrimeiroEvento = -1;
                  this.isRegisting = false;

                }).catch(() => {
                  this.$toasted.error("Erro a registar a inscrição");
                });
            }
            else
            {
              this.$toasted.error("Erro a registar a inscrição, verifique todos os campos");
              this.isRegisting = false;
            }

          }).catch(() => {
             this.$toasted.error("Erro a registar a sua inscrição");
          })
    }
    }
  }
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");
@import url("https://fonts.googleapis.com/css?family=Actor");

.inscricaoEvento .registar button:hover:disabled{
  cursor: not-allowed;
}

.inscricaoEvento .registarInscricao button:hover:disabled{
  cursor: not-allowed;
}

.receberEmail-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

input.receberEmail {
    width: 14px;
    height: 14px;
}

label.label-genero {
    margin-left: 10px;
}

.spinner {
  width: 20px;
  height: 20px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.info{
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}

.obrigatorio{
  color: red;
}

.bi-plus-lg::before {
    font-weight: 600 !important;
}

.spinner {
  width: 25px;
  height: 25px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.w3-panel{
  padding: 0;
}

.informacoesEvento {
    margin: auto;
    width: 30%;
    padding: 10px;
    border: 3px solid lightgray;
    overflow: hidden;
}

.inscricaoEvento{
  height: 100%;
}

.inscricaoEvento .campos{
  display: flex;
  flex-direction: column;
  background-color: oldlace;
  width: 100%;
  align-items: center;
  margin-bottom: 40px;
}

.inscricaoEvento .novaPessoa{
  display: flex;
  background-color: oldlace;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-content: center;
}

.inscricaoEvento .conteudo{

  min-height: calc(100vh - 425px);
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.inscricaoEvento .conteudo-invalido{
  min-height: calc(100vh - 425px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inscricaoEvento .genero {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 3;
}

.inscricaoEvento .informacoesEvento{
  margin: auto;
  width:30%;
  padding: 10px;
  border: 3px solid lightgray;
  overflow: hidden;
}

.inscricaoEvento .informacoesEvento h3
{
     text-align: left;
}

#fields {
  padding: 3px;
  margin-top: 20px;
}


.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.primeiroEvento button {
    height: 50px;
    width: 150px;
    font-size: medium;
    cursor: pointer;
    border: none;
    background-color: #e99d72;
    border-radius: 6px;
}

.primeiroEvento button:hover {
  background-color: #fab995;
}

.jaParticipou button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  width: 150px;
}
.jaParticipou button:hover {
  background-color: #fab995;
}

.inscricaoEvento .form{
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
}

.inscricaoEvento .nome {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 10px;
  
}

.inscricaoEvento .nome input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .email {
  grid-column-start: 2;
  grid-row-start: 1;
  margin-top: 10px;
  
}

.inscricaoEvento .email input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .telefone {
  
  grid-column-start: 1;
  grid-row-start: 2;
}

.inscricaoEvento .telefone input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .zona {
  
  grid-column-start: 2;
  grid-row-start: 2;
}

.inscricaoEvento .zona input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .nif {
  
  grid-column-start: 1;
  grid-row-start: 3;
}

.inscricaoEvento .nif input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .genero {
  
  grid-column-start: 2;
  grid-row-start: 3;
}


.inscricaoEvento .idade {
  
  grid-column-start: 1;
  grid-row-start: 4;
}

.inscricaoEvento .idade input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .campos input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .comoConheceuInputs input {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.comoConheceuInputs{
  margin-bottom: 20px;
}

.inscricaoEvento .comoConheceu {
  display: -webkit-box;
  display: flex;
  flex-direction: column;
}

.inscricaoEvento .comoConheceuInputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
}



.inscricaoEvento .comoConheceu select {
  width: 100%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.inscricaoEvento .registar {
  
  margin-bottom: 30px;
  grid-column-start: 2;
  grid-row-start: 5;
  justify-self: end;
  text-align: center;
}

.inscricaoEvento .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px; 
  margin: auto;
}

.inscricaoEvento .registarInscricao {
  margin-bottom: 30px;
  grid-column-start: 2;
  grid-row-start: 5;
  justify-self: end;
  text-align: center;
}

.inscricaoEvento .registarInscricao button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px; 
  margin: auto;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 60px;    /* Footer height */
}

@media (max-width: 620px)
{
  .informacoesEvento{
      width: 70% !important;
  }

  .inscricaoEvento .form{
    width: 100%;
  }
}

@media (max-width: 495px)
{
  .inscricaoEvento{
    padding-bottom: 90px;
  }
}

@media (max-width: 360px)
{
  .buttons{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>