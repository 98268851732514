
<template>
  <div class="inqueritos">
    <v-confirm
      v-model="dialog"
      :title="titlePopUp"
      :message="message"
      :btns="btns"
    />
    <br>
    <h1>{{this.title}}</h1>
      <div class="filtrar-evento" v-if="!criarInquerito">
        <label class="label-filtrar">Filtrar por evento:</label>
        <vue-single-select
          v-model="evento"
          :options="eventos"
          :placeholder="disabledEvento ? 'A carregar dados...' : 'Filtrar por nome, local, data ou tipo'"
          option-label="nome"
          :disabled="disabledEvento"
          style="width: 30%"
          :classes="{
              pointer: -1,
              wrapper: 'single-select-wrapper',
              input: 'search-input-inquerito',
              icons: 'icons',
              required: 'required',
              activeClass: 'active',
              dropdown: 'dropdown'
          }"
        ></vue-single-select>
      </div>
    <br>
    <div class="loader" v-if="isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar inquéritos ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="buttonsRegisto">
            <div class="buttonAdicionarInquerito">
                <button v-on:click="toggleCriarInquerito()">
                    <i :class="criarInquerito ? 'bi bi-chevron-left' : 'bi bi-plus-circle'"></i> {{this.criarInqueritoText}}
                </button>
            </div>
        </div>
          <template v-if="criarInquerito">
              <keep-alive>
                  <FormAddInquerito/>
              </keep-alive>
          </template>
        
        <div class="tabela-paginate" v-show="this.criarInquerito === false">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID Inquérito</th>
              <th>ID Evento</th>
              <th>Nome Evento</th>
              <th>Data Evento</th>
              <th class="link">Link</th>
              <th>Nº Questões</th>
              <th>Nº Respostas</th>
              <th>Estado</th>
              <!--<th>Dados </th>-->
            </tr>
            <!--ID, nome, tipo, data, local -->
            <tr class="conteudo" v-for="(inquerito, id) in allInqueritos" :key="id">
              <td class="idEvento">{{ inquerito.id }}</td>
              <td>{{ inquerito.idEvento }}</td>
              <td>{{ inquerito.nomeEvento }}</td>
              <td>{{ inquerito.dataEvento }}</td>
              <td><a :href="link + 'inquerito/' + inquerito.link" target="Inquerito">Link</a><div class="mt-2"><button class="btn btn-sm btn-outline-secondary" @click="copiarLink(inquerito)">Copiar Link</button></div></td>
              <td>{{ inquerito.nrQuestoes }}</td>
              <td>{{ inquerito.nrRespostas }}</td>
              <td class="estado"><span :class="inquerito.estado ? 'badge text-bg-success' : 'badge text-bg-danger'">{{inquerito.estado == 1 ? 'Ativo' : 'Fechado'}}</span></td>
              <td class="buttons">
                <div class="estadoToggleButton">
                  <a class="bi bi-check-lg" v-on:click="alterarEstado(inquerito)" title="Alterar Estado"></a>
                </div>
                <div class="apagarButton">
                  <a class="bi bi-eraser-fill" v-on:click="desassociar(inquerito)" role="button" title="Desassociar Inquérito"></a>
                </div>
                <div class="listarRespostasButton">
                  <a class="bi bi-file-earmark-medical" v-on:click="listarRespostas(inquerito)" title="Listar Respostas"></a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=page :total=total @pagination="getAllInqueritos"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormAddInquerito from "../../registos/FormAddInquerito";

import { bus } from "../../../main.js";

export default {
  components: {FormAddInquerito},
  data() {
    return {
      allInqueritos: [],
      title: "Inquéritos Associados",
      criarInquerito:false,
      criarInqueritoText : "Criar novo inquérito",
      page: 1,
      total: 0,
      dialog: false,
      titlePopUp: "",
      message: "",
      btns: [],
      isLoading: true,
      disabledEvento: true,
      eventos: [],
      evento: null,
    };
  },
  created() {
    bus.$on('CriarInquerito', () =>{
      this.toggleCriarInquerito();
    })
  },
  watch:{
    evento(){
      this.getAllInqueritos();
    }
  },
  activated() {
    this.getAllInqueritos();
    this.title = "Inquéritos Associados";
    this.getEventos();
  },
  computed: {
    link(){
      return window.location.href;
    }
  },
  methods: {
    getAllInqueritos(page) {
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllinqueritosEvento?pageURL="+(page ? page : 1)+"&idEvento="+(this.evento ? this.evento.id : ''))
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allInqueritos = response.data.inqueritos;
          this.total = response.data.total;
        }
        this.isLoading = false;
      })
      .catch(() => {

      });
    },
    getEventos() {
      this.disabledEvento = true;
      this.$axios.get("getAllEventosIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.eventos = response.data;
          this.disabledEvento = false;
        }
      })
      .catch(() => {

      });
    },
    copiarLink(inquerito)
    {
      navigator.clipboard.writeText(this.link + "inquerito/" + inquerito.link);
      this.$toasted.show("Link copiado para a área de transferência");
    },
    toggleCriarInquerito(){
      this.criarInquerito = !this.criarInquerito;
      if(this.criarInquerito){
          this.criarInqueritoText = "Voltar";
      }else {
        this.criarInqueritoText = "Criar novo inquérito";
      }
    },
    listarRespostas(inquerito) {
      bus.$emit("listarRespostasInquerito", inquerito);
    },
    alterarEstado(inquerito) {
      this.$axios.patch(`editEstadoInquerito/${inquerito.id}/${inquerito.idEvento}`)
              .then(response => { //TODO: VALIDAR ESTADO
                if(response.status == 401)
                {
                  bus.$emit("logout");
                }
                else
                {
                  inquerito.estado = 1-inquerito.estado;
                  if( inquerito.estado === 0) {
                    this.$toasted.show("Inquérito fechado com sucesso");
                  }
                  else {
                    this.$toasted.show("Inquérito ativado com sucesso");
                  }

                  if(response.status == 409){
                    this.$toasted.error("Ocorreu um erro");
                  }
                }
              })
              .catch(error => {
                if(error.response.status === 401){
                  bus.$emit("logout");
                }
              });
    },
    desassociarInquerito(inquerito)
    {
      this.$axios.delete(`desassociarInquerito/${inquerito.id}/${inquerito.idEvento}`)
        .then(response => {
          if(response.status == 401)
          {
            bus.$emit("logout");
          }
          else if(response.status == 200)
          {
            this.allInqueritos.splice(this.allInqueritos.findIndex((inqueritoAux) => { return inqueritoAux.id == inquerito.id && inqueritoAux.idEvento == inquerito.idEvento }), 1);
            this.$toasted.success("Inquérito desassociado com sucesso");
            if(this.allInqueritos.length === 0)
            {
              this.getAllInqueritos(this.page > 1 ? this.page - 1 : 1);
            }
          }
          else
          {
            this.$toasted.error("Ocorreu um erro ao desassociar o inquérito");
          }
        })
        .catch(error => {
          if(error.response.status === 401){
            bus.$emit("logout");
          }
        });
    },
    desassociar(inquerito)
    {
      this.titlePopUp = "Desassociar Inquérito";
      this.message = "Tem a certeza que pretende desassociar o inquérito ? \n ** Atenção que apagará as respostas deste inquérito para este evento **";
      this.btns = [
        {
          text: "Desassociar",
          function: () => 
          {
            this.desassociarInquerito(inquerito);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.search-input:focus-visible{
  border-color: #5bb1b3 !important;
  outline: 0 !important;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(17 144 147) !important;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(141 207 208) !important;
}

.label-filtrar{
  min-width: 130px;
}

.filtrar-evento {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
}

.estado{
  min-width: 120px;
  text-align: center;
  vertical-align: middle;
}

.link{
  min-width: 120px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.bi-chevron-left::before{
  font-weight: 600 !important;
}

a.bi.bi-check-lg, a.bi.bi-file-earmark-medical, a.bi.bi-eraser-fill {
    font-size: 17px !important;
}

a.bi.bi-check-lg:hover, a.bi.bi-file-earmark-medical:hover, a.bi.bi-eraser-fill:hover {
    font-size: 16px !important;
}
.bi-check-lg::before, .bi-file-earmark-medical::before {
  font-weight: 600 !important;
}

.inqueritos h1 {
  text-align: center;
}

.inqueritos {
  font-family: "Open Sans";
}


.inqueritos .tabela {
  width: 100%;
  margin-bottom: 20px;
}

.inqueritos #tabela{
  width: 80%;
  margin: auto;
}
.inqueritos .dados .idEvento {
  width: 5%;
}
.inqueritos .tabela table td {
  border: 1px solid #ccc;
}

.inqueritos .dados table td {
  width: 15%;
  text-align: center;
  vertical-align: middle;
}

.inqueritos .tabela table th {
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}
.inqueritos .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}

.inqueritos .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inqueritos .verButton {
  cursor: pointer;
}
.inqueritos .verButton a:hover {
  font-weight: bold;
}

.inqueritos .editarButton {
  cursor: pointer;
}

.inqueritos .editarButton a:hover {
  font-weight: bold;
  font-size: 13px;

}

.inqueritos .estadoToggleButton {
  cursor: pointer;
}

.inqueritos .estadoToggleButton a:hover {
  font-weight: bold;
  font-size: 13px;

}

.inqueritos .estadoToggleButton {
  cursor: pointer;
}

.inqueritos .estadoToggleButton a:hover {
  font-weight: bold;
  font-size: 13px;

}

.inqueritos .listarRespostasButton {
  cursor: pointer;
}

.inqueritos .listarRespostasButton a:hover {
  font-weight: bold;
  font-size: 13px;

}

.inqueritos .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
}


.inqueritos .buttonAdicionarInquerito {
  margin-bottom: 5px;

}

.inqueritos .buttonsRegisto{
  margin-bottom: 5px;
  display: -webkit-box;
  margin-left:10%;
}

.inqueritos .buttonsRegisto button {
  text-align: center;
  cursor: pointer;
  background-color: rgba(246, 136, 72, 0.91);
  border: none;
  border-radius: 4px;
}

.inqueritos .buttonAdicionarInquerito button:hover {
  background-color: rgba(217, 107, 44, 0.91);
}
</style>