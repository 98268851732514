<template>
    <div>
        <div class="registarInquerito">
            <div class="fields">
                <div class="questoes-group">
                    <div class="titulo">
                        <h3>Questões</h3>
                    </div>

                    <div class="campos">
                        <div class="questao">
                            <div class="valorQuestao">
                                <label class ="valorQuestao-label" for="questao">Questão:<i class="obrigatorio">*</i></label>
                                <input id="questao" type="text" v-model.lazy="questao.questao" required>
                            </div>
                            <div class="tipoQuestao">
                                <div class="tipoQuestao-select">
                                    <label class="tipoQuestao-label">Tipo de Questao:<i class="obrigatorio">*</i></label>
                                    <select v-model="questao.tipoVar">
                                        <option value="string">Texto</option>
                                        <option value="long">Intervalo Numérico</option>
                                        <option value="intervalo">Várias Opções</option>
                                    </select>
                                </div>
                                <div class="intervalo" v-if="questao.tipoVar=='intervalo'">
                                    <h5><b>Adicionar Valor</b></h5>
                                    <div class="buttonsIntervalos">
                                        <div class="buttonAdicionarValor">
                                            <button class="bi bi-plus-lg" @click="adicionarValor()"></button>
                                        </div>
                                        <div class="buttonEliminarValor">
                                            <button class="bi bi-trash-fill" @click="deleteValor(questao.intervalo)"></button>
                                        </div>
                                    </div>
                                    <div  class="intervalo-opcoes" v-for="(valor,counter) in questao.intervalo"
                                        v-bind:key="counter">
                                        <label class="label-valor" :for="'valor_' + counter">{{counter + 1}}º Opção: <i class="obrigatorio">*</i></label>
                                        <input class="valor" :id="'valor_' + counter" type="text" v-model.lazy="questao.intervalo[counter]" required>
                                    </div>
                                </div>
                                <div v-if="questao.tipoVar=='long'" class="intervalo-numerico">
                                    <h5><b>Limites do intervalo</b></h5>
                                    <div class="intervalo-numerico-opcao">
                                        <label class="label-numerico" for="valor-inferior">Limite Inferior: <i class="obrigatorio">*</i></label>
                                        <input class="valor" id="valor-inferior" type="number" v-model="questao.valorInferior" required>
                                    </div>
                                    <div class="intervalo-numerico-opcao">
                                        <label class="label-numerico" for="valor-superior">Limite Superior: <i class="obrigatorio">*</i></label>
                                        <input class="valor" id="valor-superior" type="number" v-model="questao.valorSuperior" required>
                                    </div>
                                </div>
                            </div>
                            <div class="buttonAdicionarQuestao">
                                <button @click="adicionarQuestao(questao)"><i class="bi bi-plus-lg"/>Adicionar Questão</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabela-group">
                    <h3 class="table-title">Questões Adicionadas</h3>
                    <table class="w3-table-all" id="tabela">
                        <tr>
                            <th>ID Questão</th>
                            <td>Questão</td>
                            <th>Tipo Questão</th>
                            <th>Valores Possíveis</th>
                        </tr>
                        <tr class="conteudo" v-for="(questaoTabela, tableKey) in questoes" :key="tableKey">
                            <td>{{tableKey+1}}</td>
                            <td>{{questaoTabela.questao}}</td>
                            <td v-if="questaoTabela.tipoVar=='string'">Texto</td>
                            <td v-if="questaoTabela.tipoVar=='intervalo'">Escolha Múltipla</td>
                            <td v-if="questaoTabela.tipoVar=='long'">Intervalo Numérico</td>
                            <td v-if="questaoTabela.tipoVar=='intervalo'">{{constroiIntervalo(questaoTabela.intervalo)}}</td>
                            <td v-else-if="questaoTabela.tipoVar=='long'">
                                <div>Limite Inferior: {{questaoTabela.valorInferior}}</div>
                                <div>Limite Superior: {{questaoTabela.valorSuperior}}</div>
                            </td>
                            <td v-else>NA</td>
                            <td class="buttonsRemover">
                                <a class="bi bi-trash-fill" v-on:click="deleteOpcao(tableKey)" title="Remover pergunta"></a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="registar">
                    <button type="submit" v-on:click.prevent="submit()" :disabled="submiting"><i class="bi bi-file-earmark-check-fill"></i> Criar Inquérito</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
    data() {
        return {
            idQuestao: 0,
            counter: 0,
            questao: {
                id: '',
                questao: '',
                tipoVar: '',
                intervalo: [""],
                valorInferior: null,
                valorSuperior: null
            },
            questoes: [],
            submiting: false,
        }

    }, methods: {
        deleteOpcao(counter) {
            this.questoes.splice(counter, 1);
        },
        constroiIntervalo(array) {
            var string = '';
            array.forEach(element => {
                string += " " + element + ",";
            });
            let str = string.slice(0, -1);
            return str;
        },
        adicionarValor() {
            this.questao.intervalo.push('')
        },
        deleteValor(intervalo) {
            if(intervalo.length === 1){
                this.$toasted.error("Este tipo de questão, requer no mínimo de uma opção");
                return;
            }
            intervalo.splice(intervalo.length-1, 1);
        },
        adicionarQuestao(questao) {
            if (questao.questao === '' || questao.tipoVar === '' || (questao.tipoVar === "long" && (questao.valorInferior == null || questao.valorInferior == '' || questao.valorSuperior == null || questao.valorSuperior == '')) || (questao.tipoVar == 'intervalo' && questao.intervalo.length == 0)) {
                this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
                return;
            }
            if(questao.questao.trim().length <= 0 || (questao.tipoVar === "intervalo" && questao.intervalo.filter(element => element.trim().length <= 0).length > 0)){
                this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
                return;
            }

            if(questao.tipoVar == 'long'){
                if(questao.valorInferior % 1 !== 0 || questao.valorSuperior % 1 !== 0){
                    this.$toasted.error("Os valores do intervalo devem ser inteiros");
                    return;
                }else if(parseInt(questao.valorInferior) >= parseInt(questao.valorSuperior)){
                    this.$toasted.error("O limite inferior deve de ser menor que o limite superior");
                    return;
                }
                else if(parseInt(questao.valorInferior) < 0 || parseInt(questao.valorSuperior) < 0){
                    this.$toasted.error("Os limites devem ser positivos");
                    return;
                }
            }

            this.questoes.push({
                questao: questao.questao,
                tipoVar: questao.tipoVar,
                intervalo: questao.intervalo,
                valorInferior: parseInt(questao.valorInferior),
                valorSuperior: parseInt(questao.valorSuperior)
            });
            this.questao = {
                questao: '',
                tipoVar: '',
                intervalo: [""],
                valorInferior: null,
                valorSuperior: null
            };
            this.$toasted.show("Questão adicionada");
        },
        deleteCampo(counter) {
            this.questao.intervalo.splice(counter, 1);
        },
        submit() {
            if(this.questoes.length <= 0){
                this.$toasted.error("Um inquérito requer no mínimo 1 questão")
                return;
            }

            let questoes = '{ \n "questoes":' + JSON.stringify(this.questoes) + "\n }";
            let msg = JSON.parse(questoes);
            this.submiting = true;
            this.$axios.post('postInquerito',msg,)
                .then((response) => {
                    if(response.status == 401)
                    {
                        bus.$emit("logout");
                    }
                    else if (response.status == 201)
                    {
                        this.questoes = [];
                        this.$toasted.success("Inquérito criado com sucesso </br> O mesmo já pode ser associado a um evento");
                        bus.$emit("CriarInquerito");
                    }
                    else
                    {
                        this.$toasted.error("Erro ao criar inquérito");
                    }
                    this.submiting = false;
                }).catch(() => {
            })
        }


    }
}
</script>
<style scoped>

.registarInquerito .registar button:hover:disabled
{
    cursor: not-allowed;
    background-color: #e99d72;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.intervalo-numerico {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.label-numerico {
    min-width: 130px;
}

.intervalo-numerico-opcao {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.buttonsRemover a.bi.bi-trash-fill{
    font-size: 16px;
}

.buttonsRemover a.bi.bi-trash-fill:hover{
    font-size: 15px !important;
}

.tabela-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.obrigatorio{
  color: red;
}

.label-valor{
    min-width: 90px;
}

.intervalo-opcoes {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

input.valor {
    width: 20%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
}

label.tipoQuestao-label, label.valorQuestao-label{
    min-width: 130px;
    margin-right: 30px;
}

.table-title{
    text-align: center;
}

.bi-plus-lg::before {
    content: "\f64d";
    font-weight: 600 !important;
}

.inqueritos .dados table td {
    width: 15%;
    font-weight: bold;
}

.registarInquerito {
    font-family: "Open Sans";
    display: flex;
}

.registarInquerito .titulo {
    margin-left: 16.4%;
    margin-bottom: 30px;
}
.registarInquerito .titulo h3 {
    text-align: left;
}

.registarInquerito table {
    margin-bottom:20px;
    width: 80%;
}
.registarInquerito .fields {
    display: inline-grid;
    grid-row-gap: 10px;
    grid-column-gap: 50px;
    width: 100%;
    height: 100%;
}

.questoes-group{
    background-color: oldlace;
    padding: 10px 0 20px 0;
}

.registarInquerito .campos {
    grid-column-start: 1;
    margin-top: 10px;
    margin-left: 10%;
    margin-bottom: 10px;
}

.registarInquerito .valorQuestao {
    height: 10%;
    margin-bottom: 10px;
    display: flex;
}

.registarInquerito .valorQuestao input {
    width: 40%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
}


.registarInquerito .tipoQuestao-select {
    height: 10%;
    margin-bottom: 10px;
    display: flex;
}

.registarInquerito .tipoQuestao-select select {
    width: 40%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
}

.registarInquerito .buttonAdicionarQuestao {
    margin-top: 30px;
}

.registarInquerito .buttonAdicionarQuestao button {
    text-align: center;
    cursor: pointer;
    background-color: rgba(246, 136, 72, 0.91);
    border: none;
    border-radius: 4px;
}

.registarInquerito .buttonAdicionarQuestao button:hover {
    background-color: rgba(217, 107, 44, 0.91);
}

.registarInquerito .intervalo {
    margin-top: 30px;
}

.registarInquerito .conteudo .buttonsRemover {
    cursor: pointer;
    background-color: white;
    text-align: center;
    width: 2%;
    border-top:none;
}

.registarInquerito .conteudo .buttonsRemover a:hover {
    font-weight: bold;
    font-size: 13px;
}

.registarInquerito .buttonsIntervalos{
    /* width:3%; */
    /* margin-left:8%; */
    /* margin-bottom:50px; */
    margin-top:10px;
    /* height:10%; */
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.registarInquerito .buttonsIntervalos button{
    background-color: lightgreen;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 4px;

}

.registarInquerito .buttonsIntervalos button:hover{
    background-color: green;
}

.registarInquerito .buttonsIntervalos .buttonAdicionarValor{
    float:left;
}

.registarInquerito .buttonsIntervalos .buttonEliminarValor{
    float:right;
}

.registarInquerito .registar {
    margin-top:30px;
    margin-bottom:30px;
    display: flex;
    justify-content: center;
}

.registarInquerito .registar button {
    height: 50px;
    font-size: medium;
    cursor: pointer;
    border: none;
    background-color: #e99d72;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;

}
.registarInquerito .registar button:hover {
    background-color: #fab995;
}

</style>