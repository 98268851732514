
<template>
  <div class="mensagens">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <div class="dados">
      <div class="loader" v-if="loading">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div> 
        <h3>A carregar mensagens ...</h3>
      </div>
      <div id="fields" class="tabela" v-else>
        <div class="buttonsRegisto">
            <div class="buttonAdicionarMensagem">
                <button v-on:click="toggleCriarMensagem()">
                    <i :class="criarMensagem ? 'bi bi-chevron-left' : 'bi bi-plus-circle'"></i> {{criarMensagemText}}
                </button>
            </div>
        </div>
          <template v-if="criarMensagem">
              <keep-alive>
                  <FormAddEditMensagem :mensagemEdit="mensagemEdit" :tipoMensagem="'AS'" :acao="acao"/>
              </keep-alive>
          </template>
        
        <div v-if="allMensagens.length > 0">
          <div class="tabela-paginate" v-show="this.criarMensagem === false">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="titulo-mensagem">Título de Mensagem</th>
                  <th scope="col">Mensagem</th>
                  <th scope="col" class="estado-mensagem">Estado</th>
                  <th class="buttons"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(mensagem, key) in allMensagens" :key="key">
                  <td class="titulo-mensagem-td">{{mensagem.titulo}}</td>
                  <td><pre class="mensagem" contenteditable="false">{{mensagem.mensagem}}</pre></td>
                  <td class="estado-mensagem-td"><span :class="mensagem.estado ? 'badge text-bg-success' : 'badge text-bg-danger'">{{mensagem.estado == 1 ? 'Ativa' : 'Desativa'}}</span></td>
                  <td class="text-center botoes">
                    <div class="grupo-botoes">
                      <a class="bi bi-check-lg" role="button" v-on:click="alterarEstado(mensagem)" title="Alterar Estado"></a>
                      <a class="bi bi-pencil-square" role="button" v-on:click="editar(mensagem)" title="Editar Mensagem"></a>
                      <a class="bi bi-trash-fill" role="button" v-on:click="apagar(mensagem)" title="Eliminar Mensagem"></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination :page=page :total=total @pagination="getAllMensagensAmigosSocios"></Pagination>
          </div>
        </div>
        <div class="sem-mensagens" v-else-if="this.criarMensagem == false">
          <h3>Não existem mensagens de Amigos Sócios</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
    mounted(){
    bus.$on('registoEdicaoAmigoSocio', ()=>{
        this.toggleCriarMensagem();
        this.getAllMensagensAmigosSocios();
    })
  },
  data() {
    return {
      allMensagens: [],
      criarMensagem: false,
      criarMensagemText : "Criar nova mensagem",
      mensagemEdit: {},
      acao: "C",
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      loading: true
    };
  },
  activated() {
    this.getAllMensagensAmigosSocios();
  },
  methods: {
    getAllMensagensAmigosSocios(page) {
      this.page = page || 1;
      this.$axios.get("getAllMensagens/AS?pageURL="+(page ? page : 1))
      .then((response) => {
        if(response.status === 401){
          bus.$emit('logout');
        }
        else
        {
          this.allMensagens = response.data.mensagens;
          this.total = response.data.total;
        }
        this.loading = false;
      });
    },
    toggleCriarMensagem(edit){
      if(edit == null)
      {
        this.mensagemEdit = {};
        this.acao = "C";
      }
      else
      {
        this.acao = "E";
      }
      this.criarMensagem = !this.criarMensagem;
      if(this.criarMensagem){
        this.criarMensagemText = "Voltar";
        bus.$emit("criarMensagem", {tipo: "AS", status: true, acao: this.acao});
      }else {
        this.criarMensagemText = "Criar nova Mensagem";
        bus.$emit("criarMensagem", {tipo: "AS", status: false});
      }
    },
    apagarMensagem(mensagem)
    {
      this.$axios.delete("deleteMensagem/"+mensagem.id)
      .then((response) => {
        if(response.status === 401){
          bus.$emit('logout');
        }
        else if(response.status == 200)
        {
          let index = this.allMensagens.findIndex((element) => { return element.id === mensagem.id; });
          this.allMensagens.splice(index, 1);
          this.$toasted.success("Mensagem eliminada com sucesso");
          if(this.allMensagens.length === 0)
          {
            this.getAllMensagensAmigosSocios(this.page > 1 ? this.page - 1 : 1);
          }
        }
        else
        {
          this.$toasted.error("Erro ao eliminar mensagem");
        }
      });
    },
    apagar(mensagem)
    {
      this.title = "Eliminar Mensagem";
      this.message = "Tem a certeza que pretende eliminar a mensagem - " + mensagem.titulo +" ?";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.apagarMensagem(mensagem);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    editar(mensagem)
    {
      this.mensagemEdit = mensagem;
      this.toggleCriarMensagem("edit");
    },
    alterarEstado(mensagem)
    {
      let mensagemAtiva = this.allMensagens.find((element) => { return element.estado == 1; });

      this.$axios.patch("patchEstadoMensagem/" +  ((mensagemAtiva != null && mensagemAtiva.id != mensagem.id) ? mensagemAtiva.id : 0) + "/" + mensagem.id, {
        estado: !mensagem.estado
      }).then((response) => {
        if(response.status === 401){
          bus.$emit('logout');
        }
        else if(response.status == 200)
        {
          if(mensagemAtiva != null && mensagemAtiva.id != mensagem.id)
          {
            mensagemAtiva.estado = 0;
          }
          mensagem.estado = mensagem.estado == 0 ? 1 : 0;

          this.$toasted.success("Estado da mensagem alterado com sucesso");
        }
        else
        {
          this.$toasted.error("Erro ao alterar estado da mensagem");
        }
      });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.spinner {
  width: 25px;
  height: 25px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

a.bi.bi-pencil-square, a.bi.bi-trash-fill, a.bi.bi-check-lg {
    font-size: 17px !important;
}

a.bi.bi-pencil-square:hover, a.bi.bi-trash-fill:hover, a.bi.bi-check-lg:hover {
    font-size: 16px !important;
}

.bi.bi-check-lg::before {
    font-weight: 600 !important;
}

.table.table-striped
{
  width: 80%;
  margin: auto;
}

.bi-chevron-left::before {
  font-weight: 600 !important;
}

.mensagens .buttonAdicionarMensagem {
  margin-bottom: 5px;

}

.mensagens .buttonsRegisto{
  margin-bottom: 15px !important;
  margin: auto;
  width: 80%;
} 

.mensagens .buttonsRegisto button {
  text-align: center;
  cursor: pointer;
  background-color: #21d2d6;
  border: none;
  border-radius: 4px;
}

.mensagens .buttonAdicionarMensagem button:hover {
  background-color: #119093;
}

.buttons{
  background-color: #f2f2f2;
}

.bi.bi-pencil-square, .bi.bi-trash-fill {
  font-size: 17px !important;
}

.bi.bi-pencil-square:hover, .bi.bi-trash-fill:hover {
  font-size: 16px !important;
}

td{
    vertical-align: middle;
}

td.text-center.botoes {
    padding: 0px 20px;
}

th{
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
}

.grupo-botoes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.mensagem{
  white-space: pre-line;
}

th.titulo-mensagem {
    width: 190px !important;
}

.titulo-mensagem-td, .estado-mensagem-td{
  text-align: center;
}

.sem-mensagens{
  display: flex;
  justify-content: center;
}

th.titulo-mensagem {
    min-width: 190px;
}

th.estado-mensagem {
    min-width: 120px;
}
</style>