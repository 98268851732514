
<template>
<div class="fichaCantor">
    <br>
    <div class="voltar">
      <button  v-on:click="switchComponent('PersonsTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <h1>{{cantor.nomeCantor}}</h1>
    <br>

    <template v-if="this.concertos.length > 0">
      <div class="apoiosRealizou">
        <h2>Concertos em que participou: {{this.paginateConcertos.total}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th>Nome do Concerto:</th>
                  <th>Data:</th>
                  <th>Local:</th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(concerto, componentKey) in this.concertos"
                  :key="componentKey"
                >
                  <td class="verButton" v-on:click="verEvento(concerto.idEvento)" title="Ver Ficha de Evento">{{concerto.nomeEvento}}</td>
                  <td>{{concerto.dataEvento}}</td>
                  <td>{{concerto.localEvento}}</td>
                  <td>                    
                    <div class="apagarButton">
                      <a class="bi bi-trash-fill" v-on:click="remover(concerto)" title="Remover Participação"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateConcertos.page :total=paginateConcertos.total @pagination="getConcertosCantor"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  props: {
    cantor: {
      type: Object,
      required: true
    }
  },
  activated() {
    this.getConcertosCantor();
  },
  deactivated() {
    this.concertos = [];
  },
  data() {
    return {
      concertos: [],
      paginateConcertos: {
        page: 1,
        total: 0
      },
      dialog: false,
      title: "",
      message: "",
      btns: []
    };
  },
  methods: {
    getConcertosCantor(page)
    {
      this.paginateConcertos.page = page || 1;
      this.$axios.get("getAllConcertosCantor/" + this.cantor.idCantor+"?pageURL="+(page ? page : 1))
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.concertos = response.data.concertos;
          this.paginateConcertos.total = response.data.total;
        }
      })
      .catch(error => {
        this.error = error;
      });
    },
    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    verEvento(id) {
      bus.$emit("viewEvento", id);
    },
    async removerParticipacao(concerto)
    {
      await this.$axios.get("getAllCantoresConcerto/" + concerto.idEvento + "?pageURL=1")
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.totalCantorConcerto = response.data.total;
          }
        })
        .catch(() => {

        });
      if(this.totalCantorConcerto == 1)
      {
        this.$toasted.error("É necessário pelo menos um cantor neste evento");
      }
      else
      {
        this.$axios.delete("deleteCantorConcerto/" + concerto.idEvento + "/" + this.cantor.idCantor)
          .then((response) => {
            if (response.status === 401) {
              bus.$emit("logout");
            }
            else if(response.status === 200){
              this.$toasted.success("Participação do evento " + concerto.nomeEvento + " removida com sucesso");
              this.getConcertosCantor();
            }
            else
            {
              this.$toasted.error("Erro a remover participação")
            }
          })
          .catch(() => {
            this.$toasted.error("Erro a remover participação")
          });
      }
    },
    remover(concerto)
    {
      this.title = "Remover Participação";
      this.message = "Tem a certeza que pretende remover a participação do concerto - " + concerto.nomeEvento +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.removerParticipacao(concerto);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.apagarButton {
  cursor: pointer;
}

.apagarButton a:hover {
  font-weight: bold;
  font-size: 14px;
}

.observacoes{
  max-width: 180px;
}

.observacao{
  max-width: 650px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.apagarButton {
  cursor: pointer;
}

.apagarButton a:hover {
  font-weight: bold;
  font-size: 14px;
}

.fichaCantor h1 {
  text-align: center;
}
.fichaCantor h2 {
  text-align: center;
  background-color: linen;
}

.fichaCantor .voltar button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fichaCantor .voltar i {
  font-size: 17px;
  margin-right: 3px;
}
.fichaCantor .voltar button:hover {
  background-color: #fdf5e6
}

.fichaCantor {
  font-family: "Open Sans";
}

.fichaCantor .tabela {
  width: 70%;
}


.fichaCantor .tabela table th {
  border: 1px solid #ccc;
}
.fichaCantor .tabela table td {
  border: 1px solid #ccc;
}
.fichaCantor .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}

.fichaCantor .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fichaCantor .verButton {
  cursor: pointer;
}
.fichaCantor .verButton a:hover {
  font-weight: bold;
}


.fichaCantor .apagarButton {
  cursor: pointer;
}

.fichaCantor .apagarButton a:hover {
  font-weight: bold;
}

.fichaCantor .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}

</style>