
<template>
  <div class="mensagens">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <div class="dados">
      <div class="loader" v-if="loading">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div> 
        <h3>A carregar listas emails ...</h3>
      </div>
      <div id="fields" class="tabela" v-else>
        <div class="buttonsRegisto">
            <div class="buttonAdicionarMensagem">
                <button v-on:click="toggleCriarNovaLista()">
                    <i :class="criarLista ? 'bi bi-chevron-left' : 'bi bi-plus-circle'"></i> {{criarNovaLista}}
                </button>
            </div>
        </div>
          <template v-if="criarLista">
              <keep-alive>
                  <FormAddEditListasEmails :listaEdit="listaEdit" :acao="acao"/>
              </keep-alive>
          </template>
        <div v-if="allListas.length > 0">
          <div class="tabela-paginate" v-show="this.criarLista === false">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                    <th scope="col" class="nome-lista">Nome da Lista</th>
                    <th scope="col">Quantidade de Contactos</th>
                    <th class="buttons"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lista,key) in  allListas" :key="key">
                  <td class="nome-lista-td">{{lista.nomeLista}}</td>
                  <td class="quantidade-contactos-td">{{lista.contactos.length}}</td>
                  <td class="text-center botoes">
                    <div class="grupo-botoes">
                      <a class="bi bi-pencil-square" role="button" v-on:click="editar(lista)" title="Editar Lista"></a>
                      <a class="bi bi-trash-fill" role="button" v-on:click="apagar(lista)" title="Eliminar Lista"></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination :page=page :total=total @pagination="getAllListas"></Pagination>
          </div>
        </div>
        <div class="sem-mensagens" v-else-if="this.criarLista == false">
          <h3>Não existem listas de emails</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
    mounted(){
      bus.$on('registoEdicaoLista', ()=>{
          this.toggleCriarNovaLista();
          this.getAllListas();
      })
  },
  data() {
    return {
      allListas: [],
      criarLista: false,
      criarNovaLista : "Criar nova lista",
      listaEdit: {},
      acao: "C",
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      loading: true 
    };
  },
  activated() {
    this.getAllListas();
  },
  methods: {
    getAllListas(page) {
      this.page = page || 1;
      this.$axios.get("getAllListas?pageURL="+(page ? page : 1))
      .then((response) => {
        if(response.status === 401){
          bus.$emit('logout');
        }
        else
        {
          this.allListas = response.data.listas;
          this.total = response.data.total;
        }
        this.loading = false;
      });
    },
    toggleCriarNovaLista(edit){
      if(edit == null)
      {
        this.listaEdit = {};
        this.acao = "C";
      }
      else
      {
        this.acao = "E";
      }
      this.criarLista = !this.criarLista;
      if(this.criarLista){
        this.criarNovaLista = "Voltar";
        bus.$emit("criarLista", {status: true, acao: this.acao});
      }else {
        this.criarNovaLista = "Criar nova lista";
        bus.$emit("criarLista", {status: false});
      }
    },
    apagarLista(lista)
    {
      this.$axios.delete("deleteLista/"+lista.id)
      .then((response) => {
        if(response.status === 401){
          bus.$emit('logout');
        }
        else if(response.status == 200)
        {
          let index = this.allListas.findIndex((element) => { return element.id === lista.id; });
          this.allListas.splice(index, 1);
          this.$toasted.success("Lista eliminada com sucesso");
          if(this.allListas.length === 0)
          {
            this.getAllListas(this.page > 1 ? this.page - 1 : 1);
          }
        }
        else
        {
          this.$toasted.error("Erro ao eliminada lista");
        }
      });
    },
    apagar(lista)
    {
      this.title = "Eliminar Lista Emails";
      this.message = "Tem a certeza que pretende eliminar a lista - " + lista.nomeLista +" ?";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.apagarLista(lista);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    editar(lista)
    {
      this.listaEdit = lista;
      this.toggleCriarNovaLista("edit"); 
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.spinner {
  width: 25px;
  height: 25px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

a.bi.bi-pencil-square, a.bi.bi-trash-fill {
    font-size: 17px !important;
}

a.bi.bi-pencil-square:hover, a.bi.bi-trash-fill:hover {
    font-size: 16px !important;
}

.table.table-striped
{
  width: 80%;
}

.bi-chevron-left::before {
  font-weight: 600 !important;
}

.mensagens .buttonAdicionarMensagem {
  margin-bottom: 5px;

}

.mensagens .buttonsRegisto{
  margin-bottom: 15px !important;
  margin: auto;
  width: 80%;
} 

.mensagens .buttonsRegisto button {
  text-align: center;
  cursor: pointer;
  background-color: #21d2d6;
  border: none;
  border-radius: 4px;
}

.mensagens .buttonAdicionarMensagem button:hover {
  background-color: #119093;
}

.buttons{
  background-color: #f2f2f2;
}

.bi.bi-pencil-square, .bi.bi-trash-fill {
  font-size: 17px !important;
}

.bi.bi-pencil-square:hover, .bi.bi-trash-fill:hover {
  font-size: 16px !important;
}

td{
  vertical-align: middle;
}

td.text-center.botoes {
    padding: 0px 20px;
}

th{
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
}

.grupo-botoes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.nome-lista-td, .quantidade-contactos-td{
  text-align: center;
}

.sem-mensagens{
  display: flex;
  justify-content: center;
}


th.nome-lista {
    min-width: 190px;
}
</style>