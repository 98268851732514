
<template>
<div class="fichaEmpresa">
    <br>
    <div class="voltar">
      <button  v-on:click="switchComponent('AllEmpresasTable')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <h1>{{this.empresaData.nomeEmpresa}}</h1>
    <br>
    <div class="dadosEmpresa">
      <div class="dadosGerais">
        <div class="nome">
          <label>Morada:</label>
          <br>
          {{this.empresaData.moradaEmpresa}}
        </div>

        <div class="telefoneEmpresa">
          <label>Telefone:</label>
          <br>
          {{this.empresaData.telefoneEmpresa? this.empresaData.telefoneEmpresa : "N.A."}}
        </div>

        <div>
          <label>Email:</label>
          <br>
          {{this.empresaData.emailEmpresa? this.empresaData.emailEmpresa : "N.A."}}
        </div>

        <div>
          <label>NIF:</label>
          <br>
          {{this.empresaData.nifEmpresa}}
        </div>

        <div class="observacao">
          <label>Obs:</label>
          <br>
          {{this.empresaData.observacoes}}
        </div>

      </div>
    </div>

    <br>
    <div class="buttonAdicionarEmpresario">
      <button v-if="!showFormEmpresarios" type="button" v-on:click="toggleFormEmpresarios()">
        <i class="bi bi-person-plus-fill"></i> Inserir Empresário
      </button>
    </div>

    <div v-if="showFormEmpresarios">
      <div class="adicionarEmpresarioCancelar">
        <button type="button" v-on:click="toggleFormEmpresarios()">
          <i class="bi bi-x"></i> Cancelar
        </button>
      </div>
      <FormNovoEmpresario
        :idEmpresa="idEmpresa"
      ></FormNovoEmpresario>
    </div>
    <br>
    <br>

    <template v-if="this.countEmpresarios">
      <div class="empresarios">
        <h2>Representantes / Empresários: {{this.countEmpresarios}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th>Nome</th>
                  <th></th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(parts, componentKey) in this.empresarioData"
                  :key="componentKey"
                >
                  <td class="verButton" v-on:click="verPessoa(parts.idEmpresario)" title="Ver Ficha de Pessoa">{{ parts.nome }}</td>
                  <td class="text-center">
                    <div class="apagarButton">
                      <a class="bi bi-trash-fill" v-on:click="apagar(parts)" title="Remover Empresário"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateEmpresarios.page :total=paginateEmpresarios.total @pagination="getDadosEmpresarios"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <br>
    <br>

    <template v-if="this.countDonativosEmpresa > 0">
      <div class="apoiosRealizou">
        <h2>Donativos que realizou: {{this.countDonativosEmpresa}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th>Quantidade:</th>
                  <th>Data:</th>
                  <th>Empresário:</th>
                  <th>Obs.:</th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(donativo, componentKey) in this.donativosEmpresaData"
                  :key="componentKey"
                >
                  <td>€{{ donativo.quantidadeDonativo}}</td>
                  <td>{{ donativo.dataDonativo}}</td>
                  <td>{{ donativo.nomeEmpresario}}</td>
                  <td class="observacoes">{{ donativo.observacoes}}</td>
                </tr>
              </table>
              <Pagination :page=paginateDonativos.page :total=paginateDonativos.total @pagination="getDonativosEmpresa"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  props: {
    idEmpresa: {
      type: Number,
      required: true
    }
  },
  mounted()
  {
    bus.$on('EmpresarioRegisto', () =>{
      this.toggleFormEmpresarios();
      this.getDadosEmpresarios();
    })
  },
  activated() {
    this.$axios.get("getDadosEmpresa/" + this.idEmpresa)
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.empresaData = response.data;
        }
      })
      .catch(error => {
        this.error = error;
      });

    this.getDadosEmpresarios();
    this.getDonativosEmpresa();

  },
  deactivated() {
    this.empresaData = [],
    this.empresarioData = [],
    this.donativosEmpresaData = [],
    this.showFormEmpresarios = false,
    this.countEmpresarios = 0,
    this.countDonativosEmpresa = 0
  },
  data() {
    return {
      empresaData: [],
      empresarioData: [],
      donativosEmpresaData: [],
      showFormEmpresarios: false,
      countEmpresarios: 0,
      countDonativosEmpresa: 0,
      paginateDonativos: {
        page: 1,
        total: 0
      },
      paginateEmpresarios: {
        page: 1,
        total: 0
      },
      dialog: false,
      title: "",
      message: "",
      btns: []
    };
  },
  methods: {
    getDadosEmpresarios(page)
    {
      this.paginateEmpresarios.page = page || 1;
      this.$axios.get("getDadosEmpresariosEmpresa/" + this.idEmpresa + "?pageURL="+(page ? page : 1))
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.empresarioData = response.data.empresarios;
            this.countEmpresarios = response.data.total;
            this.paginateEmpresarios.total = response.data.total;
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    getDonativosEmpresa(page)
    {
        this.paginateDonativos.page = page || 1;
        this.$axios.get("getDonativosEmpresa/" + this.idEmpresa + "?pageURL="+(page ? page : 1))
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.donativosEmpresaData = response.data.donativos;
            this.countDonativosEmpresa = response.data.total;
            this.paginateDonativos.total = response.data.total;
          }
        })
        .catch(() => {

        });
    },
    toggleFormEmpresarios() {
      this.showFormEmpresarios = !this.showFormEmpresarios;
    },
    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    verPessoa(id) {
      bus.$emit("viewPessoa", id);
    },
    apagarEmpresario(empresario)
    {
      this.$axios.delete("deleteEmpresario/" + empresario.idEmpresario)
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status == 200)
          {
            this.$toasted.success("Empresário removido com sucesso")
            this.getDadosEmpresarios();
            this.getDonativosEmpresa();
          }
          else
          {
            this.$toasted.error("Erro ao remover empresário")
          }
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
        });
    },
    apagar(empresario)
    {
      this.title = "Remover Empresário";
      this.message = "Tem a certeza que pretende remover o empresário - " + empresario.nome +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.apagarEmpresario(empresario);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.observacoes{
  max-width: 180px;
}

.observacao{
  max-width: 650px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.apagarButton {
  cursor: pointer;
}

.apagarButton a:hover {
  font-weight: bold;
  font-size: 14px;
}

.fichaEmpresa h1 {
  text-align: center;
}
.fichaEmpresa h2 {
  text-align: center;
  background-color: linen;
}

.fichaEmpresa .voltar button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fichaEmpresa .voltar i {
  font-size: 17px;
  margin-right: 3px;
}
.fichaEmpresa .voltar button:hover {
  background-color: #fdf5e6
}

.fichaEmpresa {
  font-family: "Open Sans";
}
.fichaEmpresa .dadosEmpresa {
  background-color: rgb(238, 222, 192);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fichaEmpresa .dadosGerais {
  display: inline-grid;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: oldlace;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 65px;
  padding-right: 65px;
  grid-row-gap: 20px;
}

.fichaEmpresa .dadosGerais label {
  font-weight: bold;
}

.fichaEmpresa .nome {
  grid-column-start: 1;
}

.fichaEmpresa .telefoneEmpresa {
  grid-column-start: 2;
}

.fichaEmpresa .tabela {
  width: 70%;
}


.fichaEmpresa .tabela table th {
  border: 1px solid #ccc;
}
.fichaEmpresa .tabela table td {
  border: 1px solid #ccc;
}
.fichaEmpresa .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}

.fichaEmpresa .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fichaEmpresa .verButton {
  cursor: pointer;
}
.fichaEmpresa .verButton a:hover {
  font-weight: bold;
}


.fichaEmpresa .apagarButton {
  cursor: pointer;
}

.fichaEmpresa .apagarButton a:hover {
  font-weight: bold;
}

.fichaEmpresa .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}

.fichaEmpresa .buttonAdicionarEmpresario {
  float: right;
  margin-right: 18%;
}

.fichaEmpresa .buttonAdicionarEmpresario button {
  text-align: center;
  cursor: pointer;
  background-color: #f7ae94;
  border: none;
  border-radius: 4px;
}

.fichaEmpresa .buttonAdicionarEmpresario button:hover {
  background-color: #f88e68;
}

.fichaEmpresa .adicionarEmpresarioCancelar {
  text-align: end;
  padding: 10px;
  margin-right: 18%;
}

.fichaEmpresa .adicionarEmpresarioCancelar button {
  text-align: center;
  cursor: pointer;
  background-color: #f7ae94;
  border: none;
  border-radius: 4px;
}

.fichaEmpresa .adicionarEmpresarioCancelar button:hover {
  background-color: #f88e68;
}

</style>