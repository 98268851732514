
<template :componentAux="componentAux">
  <div class="menuTabelaMensagens">
    <div class="menuMensagens">
      <button class="menuM" id="menuM" v-on:click="switchTable('MensagensAniversarioTable')" :disabled="componentAux === 'MensagensAniversarioTable'">Mensagens Aniversários</button>
      <button class="menuM" id="menuM" v-on:click="switchTable('MensagensAmigoSocioTable')" :disabled="componentAux === 'MensagensAmigoSocioTable'">Mensagens Amigos Sócios</button>
      <button class="menuM" id="menuM" v-on:click="switchTable('MensagensEventoTable')" :disabled="componentAux === 'MensagensEventoTable'">Mensagens Eventos</button>
      <button class="menuM" id="menuM" v-on:click="switchTable('ListasEmailsTable')" :disabled="componentAux === 'ListasEmailsTable'">Listas de Emails</button>
      <button class="menuM" id="menuM" v-on:click="switchTable('MensagensPersonalizadasTable')" :disabled="componentAux === 'MensagensPersonalizadasTable'">Mensagens Personalizadas</button>
    </div>
    <br>
    <h1>{{this.title}}</h1>
    <br>
    <keep-alive>
    <component :is="componentAux"></component>
    </keep-alive>
    </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  mounted(){
    bus.$on('criarMensagem', (val)=>{
      if(val.tipo === "A" && val.status == true){
        this.title = (val.acao == 'C' ? 'Criar ' : 'Editar ') + 'Mensagem Aniversário';
      }
      else if(val.tipo === "A" && val.status == false)
      {
        this.title = 'Mensagens de Aniversário';
      }

      if(val.tipo === "AS" && val.status == true){
        this.title = (val.acao == 'C' ? 'Criar ' : 'Editar ') + 'Mensagem Amigos Sócios';
      }
      else if(val.tipo === "AS" && val.status == false)
      {
        this.title = 'Mensagens de Amigos Sócios';
      }      
      
      if(val.tipo === "E" && val.status == true){
        this.title = (val.acao == 'C' ? 'Criar ' : 'Editar ') + 'Mensagem Evento';
      }
      else if(val.tipo === "E" && val.status == false)
      {
        this.title = 'Mensagens de Eventos';
      }
    })
    bus.$on('criarLista', (val)=>{
      if(val.status){
        this.title = (val.acao == 'C' ? 'Criar ' : 'Editar ') + 'Lista de Emails';
      }
      else
      {
        this.title = 'Listas de Emails';
      }
    })
  },
  data() {
    return {
      componentAux: "MensagensAniversarioTable",
      title: 'Mensagens de Aniversário'
    };
  },
  methods: {
    switchTable: function(table){
      switch(table){
        case 'MensagensAniversarioTable': this.title = 'Mensagens de Aniversário'
        break;
        case 'MensagensAmigoSocioTable': this.title = 'Mensagens de Amigos Sócios'
        break;
        case 'MensagensEventoTable': this.title = 'Mensagens de Eventos'
        break;
        case 'ListasEmailsTable': this.title = 'Listas de Emails'
        break;
        case 'MensagensPersonalizadasTable': this.title = 'Mensagens Personalizadas'
        break;
      }
      this.componentAux = table;
    },
  }
};


</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");


.menuTabelaMensagens {
  font-family: "Open Sans";
}
.menuTabelaMensagens .menuMensagens{
  text-align: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.menuTabelaMensagens .menuMensagens button{
  border: 1px solid #50bec0;
  padding: 3px;
  cursor: pointer;
  color: white;
}

.menuTabelaMensagens .menuMensagens .menuM{
  background-color: #119093;
  flex-grow: 1;
}
.menuTabelaMensagens .menuMensagens #menuM:hover{
  background-color: #50bec0;
  
}
.menuTabelaMensagens .menuMensagens .menuM:disabled {
  background-color: #50bec0;
  color: white;
}

.menuTabelaMensagens .menuMensagens .menuM:enabled {
  background-color: #119093;
}


</style>