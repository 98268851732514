
<template>
  <div id="page-container">
    <div id="content-wrap">
      <div class="novaPass">
        <top-bar :user="user"></top-bar>
        <div class="conteudo" v-if="!isTokenValid">                
          <div class="loader">
            <div class="spinner">
              <div class="double-bounce1"></div>
              <div class="double-bounce2"></div>
            </div> 
            <h3>A carregar ...</h3>
          </div>
        </div>
        <div v-else class="conteudo" style="display: flex; justify-content: center; align-items: center">
          <form id="login" class="w3-panel">
            <div>
              <h1>Alterar Password</h1>
              <label class="mt-3 mb-3"><b>A password terá de cumprir os seguintes requisitos:</b></label>
              <ul>
                <li>Pelo menos 1 caracter maiúsculo</li>
                <li>Pelo menos 1 símbolo</li>
                <li>Caracteres minúsculos</li>
                <li>Números</li>
              </ul>  
              <div id="fields" class="campos mt-4">
                Nova Password:
                <label  class="mb-2">
                  <input type="password" class="form-control" name="passNova" placeholder="Nova Password" v-model="input.newPassword">
                </label>
                Confirmar Password:
                <label>
                  <input type="password" class="form-control" name="passConfirm" placeholder="Nova Password" v-model="input.confPassword">
                </label>
                <div class="text-center mt-2 d-flex justify-content-center gap-4">
                  <button type="submit" class="btn btn-secondary" v-on:click.prevent="altPassword()" :disabled="submit">Submeter</button>
                  <button type="button" class="btn btn-secondary" v-on:click.prevent="push()" :disabled="submit">Cancelar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
      <Footer></Footer>
  </div>
</template>

<script>
import {bus} from "../main.js";

export default {
  data() {
    return {
      input: {
        newPassword: "",
        confPassword: ""
      },
      isTokenValid: false,
      submit: false,
    };
  },
  props: {
    auth: {
      type: Boolean,
      required: false,
      default: false
    },
    user: {
      type: Object,
      required: false,
      default: null
    }
  },
  mounted() {
    if(!this.auth)
    {
      this.$axios.get("validatePasswordResetToken/" + this.$route.params.token)
      .then(response => {
        if(response.status == 200)
        {
          this.isTokenValid = true
        }
        else
        {
          this.$router.push("/");
          this.$toasted.error("Token inválido");
        }
      });
    }
    else
    {
      this.isTokenValid = true;
    }    
  },
  methods: {
    push(){
      this.$router.push("/");
    },
    altPassword: function() {
      if (
        this.input.newPassword === "" ||
        this.input.newPassword === null ||
        this.input.confPassword === "" ||
        this.input.newPassword === null
      ) {
        this.$toasted.error("Todos os campos são obrigatórios");
        return;
      }

      if(this.input.newPassword.trim().length == 0 || this.input.confPassword.trim().length == 0)
      {
        this.$toasted.error("Todos os campos são obrigatórios");
        return;
      }

      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&_*])");
      if(!strongRegex.test(this.input.newPassword))
      {
        this.$toasted.error("A password deve ter pelo menos 1 caracter maiúsculo, 1 símbolo \" !@#$%^&_ \", 1 número e caracteres minúsculos");
        return;
      }

      if (this.input.newPassword === this.input.confPassword) {
        let password = this.input.confPassword.replace(/[""'"]/g, "");
        this.submit = true;
        if(!this.auth)
        {
          this.$axios.put("changePassword/" + this.$route.params.token, {
              passwordChange: password
            })
            .then(response => {
              if (response.status === 201) {
                this.$toasted.success("Password alterada com sucesso");
                this.$router.push("/");
              }
              else
              {
                this.$toasted.error("Erro ao alterar password");
              }
              this.submit = false;
            })
            .catch(() => {
              this.$toasted.error("Erro a contactar o servidor");

            });
        }
        else
        {
            this.$axios.put("putPassword", {
              password: password
            })
            .then(response => {
              if (response.status === 200) {
                this.$toasted.success("Password alterada com sucesso");
                this.$router.push("/");
              }
              else if(response.status == 400)
              {
                this.$toasted.error("Password inválida");
              }
              else if(response.status == 401)
              {
                bus.$emit("logout");
              }
              else
              {
                this.$toasted.error("Erro a contactar o servidor");
              }
              this.submit = false;
            })
            .catch(() => {
              this.$toasted.error("Erro a contactar o servidor");
            });
        }
      } else {
        this.$toasted.error("As passwords introduzidas não correspondem");
      }
    }
  }
};
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");
@import url("https://fonts.googleapis.com/css?family=Actor");

.btn.btn-secondary:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.spinner {
  width: 25px;
  height: 25px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 90px;    /* Footer height */
}

.form-control:focus {
    border-color: #ed0677;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(237 6 119 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(237 6 119 / 60%);
}

.novaPass .atempharlogo {
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  height: 100%;
}

.novaPass .atempharlogo .imagem {
  grid-column-start: 1;
  grid-row-start: 2;
  margin-left: 10px;
}

.novaPass .atempharlogo .imagem img {
  width: 90%;
  margin-top: 10px;
}
.novaPass .atempharlogo .txtTitle {
  grid-column-start: 2;
  grid-row-start: 3;
  color: #ffffff;
  font-family: "Berlin Sans FB";
  font-size: 5vw;
  margin-right: 20px;
}

#login {
    margin: auto;
    width: 25%;
    padding: 10px;
    overflow: hidden;
    width: 30%;
    padding: 15px;
    -webkit-box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%) !important;
    box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%) !important;
    border-radius: 7px !important;
  
}

.novaPass .conteudo{
  min-height: calc(100vh - 360px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

.btn.btn-secondary
{
  width: 40%;
}

#fields {
  padding: 3px;
  display: flex;
  flex-direction: column;
}

.novaPass .campos input {
  margin-bottom: 15px;
  width: 100%;
}

@media (max-width: 910px) {
  #login {
    width: 60%;
  }
}

@media (max-width: 400px) {
  .novaPass {
    padding-bottom: 90px;
  }
}


</style>