<template>
  <div>
    <div class="registarEvento">
      <div id="fields" class="fields">
        <div class="nomeEvento">
          <label>Nome:<i class="obrigatorio">*</i></label>
          <br>
          <input type="text" v-model="input.nome" name="nome">
        </div>
        <div class="tipo">
          <label>Tipo de Evento:<i class="obrigatorio">*</i></label>
          <br>
          <select v-model="input.tipoEvento">
            <!--Palestra, Simposio, Workshop, Piquenique, Jantar Solidariedade, Mini-Gala, Apoio Solidariedade, Tertulia -->
            <option value="Palestra">Palestra</option>
            <option value="Simposio">Simpósio</option>
            <option value="Concerto">Concerto</option>
            <option value="Workshop">Workshop</option>
            <option value="Congresso">Congresso</option>
            <option value="Piquenique">Piquenique</option>
            <option value="Jantar Solidariedade">Jantar de Solidariedade</option>
            <option value="Mini-Gala">Mini-Gala</option>
            <option value="Apoio Solidariedade">Apoio de Solidariedade</option>
            <option value="Tertulia">Tertúlia</option>
          </select>
        </div>

        <div class="data">
          <label>Data:<i class="obrigatorio">*</i></label>
          <br>
          <input type="datetime-local" v-model="input.data" :min="minDate" name="data">
        </div>
        <div class="localEvento">
          <label>Local:<i class="obrigatorio">*</i></label>
          <br>
          <input type="text" v-model="input.local" name="local">
        </div>
        <div class="observacoes">
          <label>Observações:</label>
          <br>
          <textarea type="text" v-model="input.observacoes" name="observacoes"></textarea>
        </div>

        <template v-if="input.tipoEvento === 'Workshop'">
          <div class="custo">
            <label>Custo:<i class="obrigatorio">*</i></label>
            <br>
            <input type="number" v-model="input.custo" name="custo" min="0.01" step="0.01">
          </div>
        </template>

        <template v-if="input.tipoEvento === 'Workshop' || input.tipoEvento === 'Palestra'">
          <div class="botaoOradorExistente">
              <button type="button" class="btn btn-secondary staff" v-on:click="toggleInsert()"><i class="bi bi-person-plus-fill"></i> {{buttonText}}</button>
          </div>
          <template v-if="input.oradorRecorrente">
            <div class="orador">
              <label>Orador:<i class="obrigatorio">*</i></label>
              <br>
              <vue-single-select
                v-model="input.inputOrador"
                :options="allOrators"
                :placeholder="disabledOrador ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
                option-label="nome"
                :disabled="disabledOrador"
              ></vue-single-select>
            </div>
          </template>
          
          <template v-else>
            <div class="novoOrador">
              <div class="listaPessoas">
                <label>Novo Orador:<i class="obrigatorio">*</i></label>
                <br>
                <vue-single-select
                  v-model="input.inputPessoa"
                  :options="allPersons"
                  :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
                  option-label="nome"
                  :disabled="disabledPessoa"
                ></vue-single-select>
              </div>
            
            <div class="especialidade">
              <label>Especialidade:<i class="obrigatorio">*</i></label>
              <br>
              <input name="inputEspecialidade" v-model="input.especialidade">
            </div>
            </div>
          </template>
        </template>
        <template v-if="input.tipoEvento === 'Concerto'">
          <div>
            <h5><b>Associar Cantores</b></h5>
            <div class="buttonsIntervalos">
                <div class="buttonAdicionarCantor">
                    <button class="bi bi-plus-lg" @click="adicionarValor()"></button>
                </div>
                <div class="buttonEliminarCantor">
                    <button class="bi bi-trash-fill" @click="deleteValor()"></button>
                </div>
            </div>
            <div  class="intervalo-opcoes" v-for="(valor, counter) in allCantores" v-bind:key="counter">
                <label class="label-valor" for="valor">{{counter + 1}}º Cantor: <i class="obrigatorio">*</i></label>
                <template v-if="!valor.novoCantor">
                  <vue-single-select
                    v-model.lazy="allCantores[counter].cantor"
                    :options="allCantoresRegisted"
                    :placeholder="disabledCantor ? 'A carregar dados...' : 'Procurar por nome'"
                    option-label="nome"
                    style="width: 50% !important;"
                    :disabled="disabledCantor"
                  ></vue-single-select>
                </template>
                <template v-else>
                  <input id="valor" type="text" v-model.lazy="allCantores[counter].cantor" required>
                </template>
                <label class="novoCantor-Checkbox" role="button" :for="'novoCantor_' + counter"><input role="button" type="checkbox" class="novoCantor" :id="'novoCantor_' + counter" @click="novoCantor(counter)" title="Novo Cantor"> Novo Cantor</label>
            </div>
          </div>
        </template>
        <div class="registar">
          <button type="submit" v-on:click.prevent="submit()" :disabled="submiting"><i class="bi bi-file-earmark-check-fill"></i> Registar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  props: {
    tipoEvento: {
      type: String,
      required: true
    },
  },
  watch: {
    tipoEvento: function(novoTipo) {
      this.input.tipoEvento = novoTipo;
    }
  },
  mounted()
  {
    this.input.tipoEvento = this.tipoEvento;
  },
  activated() {
    this.$axios.get("getAllPessoasNaoOradoresIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allPersons = response.data;
          this.disabledPessoa = false;
        }
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      });

      this.$axios.get("getAllCantoresIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allCantoresRegisted = response.data;
          this.disabledCantor = false;
        }
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      });


    this.$axios.get("getAllOradoresIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allOrators = response.data;
          this.disabledOrador = false;
        }
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      });

      let MyDate = new Date();
      MyDate.setDate(MyDate.getDate())
      this.minDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                      ('0' + MyDate.getDate()).slice(-2) + 'T00:00:00';  
  },
  data() {
    return {
      input: {
        nome: "",
        tipoEvento: "",
        oradorRecorrente: true,
        inputOrador: "",
        inputPessoa: "",
        data: "",
        local: "",
        observacoes: "",
        custo: "",
        especialidade: "",
        cantor: ""
      },
      allPersons: [],
      allOrators: [],
      allCantores:[{cantor:"", novoCantor: false}],
      allCantoresRegisted: [],
      buttonText: "Novo Orador",
      minDate: "",
      disabledPessoa: true,
      disabledOrador: true,
      disabledCantor: true,
      submiting: false,
    };
  },
  methods: {
    clearFields: function() {
      bus.$emit('refreshEventos');
      (this.input.nome = ""),
        (this.input.tipoEvento = ""),
        (this.input.oradorRecorrente = true),
        (this.input.inputOrador = ""),
        (this.input.inputPessoa = ""),
        (this.input.data = ""),
        (this.input.local = ""),
        (this.input.observacoes = ""),
        (this.input.custo = ""),
        (this.input.especialidade = "");
    },
    toggleInsert: function() {
      if (this.input.oradorRecorrente) {
        this.buttonText = "Cancelar";
      } else {
        this.buttonText = "Novo Orador";
      }
      this.input.oradorRecorrente = !this.input.oradorRecorrente;
    },
    novoCantor(index)
    {
      this.allCantores[index].novoCantor = !this.allCantores[index].novoCantor;
      this.allCantores[index].cantor = "";
    },
    adicionarValor() {
      this.allCantores.push({cantor:"", novoCantor: false});
    },
    deleteValor() {
        if(this.allCantores.length === 1){
            this.$toasted.error("Este evento requer no mínimo um cantor");
            return;
        }
        this.allCantores.splice(this.allCantores.length - 1, 1);
    },
    submit: function() {
      if (this.input.tipoEvento === "") {
        this.$toasted.error("O tipo de evento não pode estar vazio");
        return;
      }

      if(this.input.tipoEvento != "Palestra" && this.input.tipoEvento != "Simposio" && this.input.tipoEvento != "Concerto" && this.input.tipoEvento != "Congresso" && this.input.tipoEvento != "Workshop" && this.input.tipoEvento != "Piquenique" && this.input.tipoEvento != "Jantar Solidariedade" && this.input.tipoEvento != "Mini-Gala" && this.input.tipoEvento != "Apoio Solidariedade" && this.input.tipoEvento != "Tertulia"){
        this.$toasted.error("Tipo de evento inválido");
        return;
      }
      //verificar se há outros campos obrigatorios vazios
      if (
        this.input.nome === "" || this.input.nome == null ||
        this.input.oradorRecorrente === "" || this.input.oradorRecorrente == null ||
        this.input.data === "" || this.input.data == null ||
        this.input.local === "" || this.input.local == null ||
        (this.input.tipoEvento == "Workshop" && (this.input.custo === "" || this.input.custo == null))
      ) {
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
        return;
      }

      if(new Date(this.input.data.replace("T", " ")).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)){
        this.$toasted.error("Data do evento inválida, não pode criar eventos no passado");
        return;
      }

      if(this.input.tipoEvento == "Workshop"){
        if(this.input.custo < 0){
          this.$toasted.error("O custo do evento não pode ser menor que 0");
          return;
        }
      }
      //validar a existencia do ID escolhido de Pessoa (para prevenir inserções manuais incorrectas)

      //verificar se orador é novo ou antigo, inserir orador novo na tabela.-
      var oradorAux = this.input.oradorRecorrente ? this.input.inputOrador.id : this.input.inputPessoa.id;
      //se a palestra for uma palestra com orador
      if (
        this.input.tipoEvento === "Palestra" ||
        this.input.tipoEvento === "Workshop"
      ) {
        //se for um orador novo
        if (!this.input.oradorRecorrente) {
            //se não for uma pessoa nova
            //post orador
            if(this.input.especialidade.trim() == 0){
              this.$toasted.error("Especialidade inválida");
              return;
            }
            
            this.submiting = true;

            this.$axios.post("postOrador",
                {
                  idOrador: this.input.inputPessoa.id,
                  especialidade: this.input.especialidade
                })
              .then(response => {
                if(response.status == 401)
                {
                  bus.$emit('logout');
                }
                else if(response.status == 400)
                {
                  this.$toasted.error("Erro ao inserir orador");
                  this.submiting = false;
                }
              })
              .catch((errors) => {
                if (errors.response.status === 400) {
                  this.$toasted.error("Campos incorrectos ou incompletos");
                }
                if (errors.response.status === 401) {
                  bus.$emit("logout");
                }
              });
        } else {
          //se for um orador já existente
          if (this.input.inputOrador == null) {
            this.$toasted.error("ID de Orador introduzido inexistente ou vazio");
            return;
          }
        }
      }

      //---------------------------------------------------------------------------------------------------

      //INSERÇÕES DE EVENTO
      //Evento: idOrador, nome, data, local, tipoEvento, observacoes
      switch (this.input.tipoEvento) {
        case "Palestra":
          this.submiting = true;

          this.$axios.post("postEvento",
              {
                idOrador: oradorAux,
                nomeEvento: this.input.nome,
                dataEvento: this.input.data.replace("T", " "),
                localEvento: this.input.local,
                tipoEvento: this.input.tipoEvento,
                observacoes: this.input.observacoes
              })
            .then((response) => {
              if(response.status == 401)
              {
                bus.$emit('logout');
              }
              else
              {
                this.$toasted.success("Evento " + this.input.nome + " inserido com sucesso");
                this.clearFields();
              }
              this.submiting = false;
            })
            .catch(error =>{
              if(error.response.status === 401){
                bus.$emit("logout");
              }
              this.$toasted.error("Erro na inserção de evento!")
            });
          break;
        case "Workshop":
          //inserir workshop e custo
          this.submiting = true;
          this.$axios.post("postEvento",
              {
                idOrador: oradorAux,
                nomeEvento: this.input.nome,
                dataEvento: this.input.data.replace("T", " "),
                localEvento: this.input.local,
                tipoEvento: this.input.tipoEvento,
                observacoes: this.input.observacoes

            }).then(response => {

              if (response.status === 201) {
                this.$axios.post("postWorkshop",
                  {
                      idWorkshop: response.data,
                      custo: this.input.custo
                  }).then(response => {
                    if (response.status === 201) {
                      this.$toasted.success("Evento " + this.input.nome + " inserido com sucesso");
                      this.clearFields();
                    }
                    else if(response.status === 400){
                      this.$toasted.error("Erro na inserção do workshop");
                    }
                    else if(response.status === 401){
                      bus.$emit("logout");
                    }
                    this.submiting = false;
                }).catch((errors) => {
                  if (errors.response.status === 400) {
                    this.$toasted.error("Campos incorrectos ou incompletos");
                  }
                  if (errors.response.status === 401) {
                    bus.$emit("logout");
                  }
                });
              }
              else if (response.status == 401) {
                bus.$emit("logout");
              }
              else {
                this.$toasted.error("Erro na inserção do evento");
                this.submiting = false;
              }
            })
            .catch((errors) => {
              if (errors.response.status === 400) {
                this.$toasted.error("Campos incorrectos ou incompletos");
              }
              if (errors.response.status === 401) {
                bus.$emit("logout");
              }
            });
          break;
        default:
          if(this.input.tipoEvento == "Concerto")
          {
            if(this.allCantores.filter(element => (element.cantor == null || typeof element.cantor != "object" && element.cantor.trim().length == 0)).length > 0){
                this.$toasted.error("Campos de cantores obrigatórios vazios");
                return;
            }
          }
          this.submiting = true;
          //isto significa que é um evento sem orador, sem custo.
          this.$axios.post("postEvento",
              {
                idOrador: null,
                nomeEvento: this.input.nome,
                dataEvento: this.input.data.replace("T", " "),
                localEvento: this.input.local,
                tipoEvento: this.input.tipoEvento,
                observacoes: this.input.observacoes
              })
            .then(response => {
              if (response.status === 201) {
                if(this.input.tipoEvento == "Concerto")
                {
                  let novosCantores = this.allCantores.filter(cantor => cantor.novoCantor);
                  let idEvento = response.data;

                  if(novosCantores.length > 0)
                  {
                    this.$axios.post("postCantor",
                    {
                      cantores: novosCantores.map(cantor => cantor.cantor),
                    }).then(response => {
                      if (response.status === 201) {
                        let idCantores = [...response.data, ...new Set(this.allCantores.filter(cantor => !cantor.novoCantor).map(cantor => cantor.cantor.id))];
                        this.$toasted.success("Novos cantores registados com sucesso");
                        this.postConcerto(idCantores, idEvento);
                      }
                      else if(response.status == 401)
                      {
                        bus.$emit("logout");
                      }
                      else
                      {
                        this.$toasted.error("Erro na inserção de novos cantores");
                        this.submiting = false;
                      }
                    }).catch((errors) => {
                      if (errors.response.status === 400) {
                        this.$toasted.error("Campos incorrectos ou incompletos");
                      }
                      else if (errors.response.status === 401) {
                        bus.$emit("logout");
                      }
                    });
                  }
                  else
                  {
                    this.postConcerto([...new Set(this.allCantores.map(cantor => cantor.cantor.id)) ], idEvento);
                  }
                }
                else
                {
                  this.$toasted.success("Evento " + this.input.nome + " inserido com sucesso");
                  this.clearFields();
                }
              }
              else if (response.status == 401) {
                bus.$emit("logout");
              }
              else
              {
                this.submiting = false;
              }
            })
            .catch(error =>{
              if(error.response.status === 401){
                bus.$emit("logout");
              }
            });
          break;
      }
    },
    postConcerto(idCantores, idEvento)
    {
      this.$axios.post("postConcerto",
      {
        idConcerto: idEvento,
        idCantores: idCantores
      }).then(response => {
        if (response.status === 401) 
        {
          bus.$emit("logout");
        }
        else if (response.status === 201) 
        {
          this.$toasted.success("Evento " + this.input.nome + " inserido com sucesso");
          this.clearFields();
        }
        else
        {
          this.$toasted.error("Erro na inserção de concerto");
        }
        this.submiting = false;
      }).catch((errors) => {
        if (errors.response.status === 400) {
          this.$toasted.error("Campos incorrectos ou incompletos");
        }
        if (errors.response.status === 401) {
          bus.$emit("logout");
        }
      });
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.registarEvento .registar button:hover:disabled{
  background-color: #e99d72;
  cursor: not-allowed;
}

.novoCantor-Checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

input.novoCantor {
  width: 14px;
  height: 14px;
}

.buttonAdicionarCantor{
  float: left;
}

.buttonsIntervalos{
  margin-top:10px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.buttonsIntervalos button{
  background-color: lightgreen;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 4px;
}

.buttonsIntervalos button:hover{
  background-color: green;
}

.intervalo-opcoes {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

input#valor {
    width: 50%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
}

.registarEvento {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.registarEvento .fields .obrigatorio{
  color: red;
}

.registarEvento .fields {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  background-color: oldlace;
  width: 100%;
}
.registarEvento .nomeEvento {
  grid-column-start: 1;
  margin-top: 10px;
  margin-left: 115px;
}

.registarEvento .nomeEvento input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarEvento .tipo {
  margin-right: 115px;
  margin-top: 10px;
}
.registarEvento .tipo select {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarEvento .data {
  margin-left: 115px;
}
.registarEvento .data input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarEvento .localEvento {
  margin-right: 115px;
}
.registarEvento .localEvento input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarEvento .observacoes {
  margin-left: 115px;
}
.registarEvento .observacoes textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #a1a0a0;
  resize: none;
  border-radius: 4px;
}

.registarEvento .custo {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 3;
}
.registarEvento .custo input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarEvento .orador {
  height: 15px;
  margin-left: 115px;
  grid-row-start: 4;
  grid-column-start: 1;
  margin-bottom: 55px;
}

.registarEvento .botaoOradorExistente {
  margin-right: 115px;
  justify-content: center;
  display: flex;
  align-items: center;
  grid-row-start: 3;
  margin-top: 70px;
  grid-column-start: 2;
}

.registarEvento .botaoOradorExistente input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.registarEvento .botaoOradorExistente a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.registarEvento .novoOrador {
  grid-row-start: 5;
  margin-left: 115px;
  display: contents;
}

.registarEvento .novoOrador .listaPessoas {
  grid-column-start: 1;
  grid-row-start: 5;
  margin-left: 115px;
}

.registarEvento .novoOrador .botaoPessoaExistente {
  margin-right: 115px;
  justify-self: end;
  margin-top: 30px;
  grid-column-start: 2;
  grid-row-start: 5;
}

.registarEvento .novoOrador .botaoPessoaExistente input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.registarEvento .novoOrador .botaoPessoaExistente a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.registarEvento .novoOrador .nome {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 6;
}
.registarEvento .novoOrador .nome input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarEvento .novoOrador .email {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 6;
}
.registarEvento .novoOrador .email input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}
.registarEvento .novoOrador .telefone {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 7;
}
.registarEvento .novoOrador .telefone input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarEvento .novoOrador .genero {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 7;
}


.registarEvento .novoOrador .zona {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 8;
}
.registarEvento .novoOrador .zona input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarEvento .novoOrador .idade {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 8;
}
.registarEvento .novoOrador .idade input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarEvento .novoOrador .especialidade {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 9;
}
.registarEvento .novoOrador .especialidade input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}
.registarEvento .novoOrador .nif {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 9;
}
.registarEvento .novoOrador .nif input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}
.registarEvento .novoOrador .comoConheceu {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 10;
  width: 50%;
  display: -webkit-box;
  height: 35px;
}

.registarEvento .novoOrador .comoConheceu select {
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 30px;
}

.registarEvento .novoOrador .comoConheceu .atravesQuem {
  margin-top: 11px;
  width: 117%;
  margin-left: 9px;
}

.registarEvento .novoOrador .comoConheceu .local {
  margin-top: 16px;
  width: 100%;
  
}

.registarEvento .novoOrador .comoConheceu .local input{
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  width: 122%;
  height: 35px;
  margin-left: 9px;
}

.registarEvento .registar {
  margin-right: 115px;
  margin-bottom: 30px;
  grid-column-start: 2;
  grid-row-start: 11;
  align-self: end;
  justify-self: end;
}

.registarEvento .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.registarEvento .registar button:hover {
  background-color: #fab995;
}

</style>