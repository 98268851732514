<template>
  <div class="eventos">
    <v-confirm
      v-model="dialog"
      :title="titlePopUp"
      :message="message"
      :btns="btns"
    />
    <div class="menuEventos" >
      <button class="menuE" id="menuE" v-on:click="switchTable('Todos')" :disabled="tipo === 'Todos'">Todos</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Palestra')" :disabled="tipo === 'Palestra'">Palestras</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Simposio')" :disabled="tipo === 'Simposio'">Simpósios</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Concerto')" :disabled="tipo === 'Concerto'">Concertos</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Workshop')" :disabled="tipo === 'Workshop'">Workshops</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Congresso')" :disabled="tipo === 'Congresso'">Congressos</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Piquenique')" :disabled="tipo === 'Piquenique'">Piqueniques</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Jantar Solidariedade')" :disabled="tipo === 'Jantar Solidariedade'">Jantares de Solidariedade</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Mini-Gala')" :disabled="tipo === 'Mini-Gala'">Mini-Galas</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Apoio Solidariedade')" :disabled="tipo === 'Apoio Solidariedade'">Apoios de Solidariedade</button>
      <button class="menuE" id="menuE" v-on:click="switchTable('Tertulia')" :disabled="tipo === 'Tertulia'">Tertúlias</button>
    </div>
    <br>
    <h1>{{this.title}}</h1>
    <br>
    <div class="loader" v-if="isLoading && !criarInscricaoEvento">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar eventos ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" :class="!registarEvento ? 'tabela' : 'registar'">
        <div class="search" v-if="!registarEvento">
          <form class="search">
              <input type="text" class="form-control" placeholder="Filtrar por nome, local, data" v-model="filtro">
              <button type="submit" class="btn btn-secondary" @click.prevent="getEventos(null)"><i class="bi bi-search"></i> Filtrar</button>
          </form>
        </div>
        <div class="buttonsRegisto">
          <div class="buttonAdicionarEvento">
            <button v-on:click="toggleRegistarEvento()">
              <i :class="registarEvento ? 'bi bi-chevron-left' : 'bi bi-plus-circle'"></i> {{this.registarText}}
            </button>
          </div>
          <div class="buttonInscricaoEvento">
            <button v-on:click="toggleInscricaoEvento()">
              <i :class="criarInscricaoEvento ? 'bi bi-chevron-left' : 'bi bi-plus-circle'"></i> {{this.criarInscricaoEventoText}}
            </button>
          </div>

        </div>
        <template v-if="registarEvento">
          <keep-alive>
            <RegistarEvento :tipoEvento=tipo />
          </keep-alive>
        </template>

        <template v-if="criarInscricaoEvento">
          <keep-alive>
          <RegistoCriacaoInscricaoEvento/>
          </keep-alive>
        </template>
        <div class="tabela-paginate" v-show="!registarEvento">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Tipo</th>
              <th>Data</th>
              <th>Local</th>
              <th>Inquérito</th>
              <th>Preço</th>
              <th class="link">Link Inscrição </th>
              <!--<th>Dados </th>-->
            </tr>
            <!--ID, nome, tipo, data, local -->
            <tr class="conteudo" v-for="(event, tableKey) in allEvents" :key="tableKey">
              <td class="idEvento">{{ event.idEvento }}</td>
              <td class="verButton" v-on:click="ver(event.idEvento)" title="Ver Ficha de Evento">
                {{ event.nomeEvento }}
              </td>
              <td>{{ event.tipoEvento == 'Simposio'? 'Simpósio' : event.tipoEvento}}</td>
              <td>{{ event.dataEvento }}</td>
              <td>{{ event.localEvento }}</td>
              <td class="estatuto"><span :class="event.idInquerito != -1 ? 'badge text-bg-success' : 'badge text-bg-danger'">{{event.idInquerito != -1 ? 'Sim' : 'Não'}}</span></td>
              <td>{{event.custo}} €</td>

              <td v-if="event.tokenRegisto !== '' && event.tokenRegisto != null "><a v-bind:href="link + 'inscricaoEvento/' + event.tokenRegisto" target="_blank">Link</a><div class="mt-2"><button class="btn btn-sm btn-outline-secondary" @click="copiarLink(event)">Copiar Link</button></div></td>
              <td v-else>Sem Link</td>
              <td class="buttons">
                <div class="editarButton">
                  <a class="bi bi-pencil-square" v-on:click="editar(event)" title="Editar"></a>
                </div>
                <div class="verInscricoesButton">
                  <a class="bi bi-person-lines-fill" v-on:click="editarInscricoes(event)" title="Consultar Inscrições"></a>
                </div>
                <div class="associarInqueritoButton">
                  <a class="bi bi-file-earmark-medical" v-on:click="associarInquerito(event.idEvento)" title="Associar Inquérito"></a>
                </div>
                <div class="deleteEventoButton">
                  <a class="bi bi-trash-fill" v-on:click="eliminarEvento(event)" title="Eliminar Evento"></a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=page :total=total @pagination="getEventos"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  mounted(){
    bus.$on('refreshEventos', ()=>{
        this.switchTable('Todos');
        this.title = 'Eventos';
        this.toggleRegistarEvento();
    })
    bus.$on('refreshEventosLink', ()=>{
      this.getEventos();
    })
  },
  activated() {
    this.getEventos();
    this.title = "Eventos";
  },
  data() {
    return {
      allEvents: '',
      tableKey: 0,
      title: "Eventos",
      registarEvento: false,
      criarInscricaoEvento : false,
      registarText: "Registar Evento",
      criarInscricaoEventoText: "Criar inscrição para Evento",
      tipo: "Todos",
      page: 1,
      total: 0,
      filtro: "",
      dialog: false,
      titlePopUp: "",
      message: "",
      btns: [],
      isLoading: true,
    };
  },
  computed: {
    link(){
      return window.location.href;
    },
  },
  watch: {
    filtro(valor) {
        if(valor === ""){
            this.getEventos();
        }
    },
  },
  methods: {
    getEventos(page)
    {
      this.isLoading = true;
      this.page = page || 1;
      
      if(this.tipo === 'Todos'){
        this.title = 'Eventos';      

        this.$axios.get("getAllEventos?pageURL="+(page ? page : 1)+"&filtroURL="+this.filtro)
        .then(response => {
          if (response.status == 401) {
            bus.$emit("logout");
          }else{
            this.allEvents = response.data.eventos;
            this.total = response.data.total;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
        });
      }else{
        this.title = (this.tipo === "Simposio" ? "Simpósios" :
              this.tipo === "Tertulia" ? "Tertúlias" :
              this.tipo === "Jantar Solidariedade" ? "Jantares de Solidariedade" :
              this.tipo === "Apoio Solidariedade" ? "Apoios de Solidariedade" :
              this.tipo + 's' );

        this.$axios.get("getAllEventosOfTipo/" + this.tipo+"?pageURL="+(page ? page : 1)+"&filtroURL="+this.filtro)
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.allEvents = response.data.eventos;
            this.total = response.data.total;
            this.tableKey++;
            this.$forceUpdate();
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
        });    
      }
    },
    copiarLink(evento)
    {
      navigator.clipboard.writeText(this.link + "inscricaoEvento/" + evento.tokenRegisto);
      this.$toasted.show("Link copiado para a área de transferência");
    },
    associarInquerito(data){
      bus.$emit("associarInquerito", data);
    },
    toggleRegistarEvento(){
      if(this.criarInscricaoEvento){
        this.toggleInscricaoEvento();
      }
      this.registarEvento = !this.registarEvento;
      if(this.registarEvento){
          this.registarText = "Voltar";
      }else{
          this.registarText = "Registar Evento";
      }
    },
    toggleInscricaoEvento(){
      if(this.registarEvento){
        this.toggleRegistarEvento();
      }
      this.criarInscricaoEvento = !this.criarInscricaoEvento;
      if(this.criarInscricaoEvento){
          this.criarInscricaoEventoText = "Voltar";
      }else{
          this.criarInscricaoEventoText = "Criar inscrição para Evento";
      }
    },

    ver(idEvento) {
      bus.$emit("viewEvento", idEvento);
    },
    editar(data) {
      bus.$emit("editarEvento", data);
    },
    editarInscricoes(data) {
      bus.$emit("editInscricoes", data);
    },
    switchTable(tipo){
      this.tipo = tipo;
      this.getEventos();
      window.scrollTo(0, 0);
    },
    eliminarEvento(evento)
    {
      this.titlePopUp = "Eliminar Evento";
      this.message = "Tem a certeza que pretende eliminar o evento " + evento.nomeEvento +" ?";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.eliminarEventoConfirm(evento);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    eliminarEventoConfirm(evento)
    {
      this.$axios.delete("deleteEvento/" + evento.idEvento)
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Evento " + evento.nomeEvento + " eliminado com sucesso");
            this.getEventos();
          }
          else
          {
            this.$toasted.error("Erro a eliminar evento")
          }
        })
        .catch(() => {
          this.$toasted.error("Erro a eliminar evento")
        });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

th.link {
    min-width: 120px;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

a.bi.bi-person-lines-fill, a.bi.bi-pencil-square, a.bi.bi-file-earmark-medical, a.bi.bi-trash-fill{
  font-size: 17px !important;
}

a.bi.bi-person-lines-fill:hover, a.bi.bi-pencil-square:hover, a.bi.bi-file-earmark-medical:hover, a.bi.bi-trash-fill:hover {
  font-size: 16px !important;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.search {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 5px;
    gap: 15px;
}

.form-control {
    width: 25% !important;
}

.form-control:focus {
  border-color: #5babcd;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgb(91 171 205 / 37%);
  box-shadow: 0 0 0 0.25rem rgb(91 171 205 / 37%);
}

.bi-check-lg::before, .bi-file-earmark-medical::before{
  font-weight: 600 !important;
}

.eventos h1 {
  text-align: center;
}

.eventos {
  font-family: "Open Sans";
}

.eventos .menuEventos {
  text-align: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.eventos .menuEventos button {
  border: 1px solid #5BABCD;
  padding: 3px;
  cursor: pointer;
  color: white;
}

.eventos .menuEventos .menuE{
  background-color: rgb(39, 134, 175);
  flex-grow: 1;
}

.eventos .menuEventos #menuE:hover {
  background-color: #5BABCD;
}

.eventos .menuEventos .menuE:enabled{
  background-color: rgb(39, 134, 175);
}

.eventos .menuEventos .menuE:disabled {
  background-color: #5BABCD;
}
.eventos .tabela {
  width: 100%;
  margin-bottom: 20px;
}

.eventos .registar {
  width: 100%;
  margin-bottom: 20px;
}

.eventos #tabela{
  width: 80%;
  margin: auto;
}
.eventos .dados .idEvento {
  width: 5%;
}
.eventos .tabela table td {
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}

.eventos .dados table td {
  width: 15%;
}

.eventos .tabela table th {
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}
.eventos .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}

.eventos .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventos .verButton {
  cursor: pointer;
}
.eventos .verButton a:hover {
  font-weight: bold;
}

.eventos .editarButton {
  cursor: pointer;
}

.eventos .deleteEventoButton {
  cursor: pointer;
}

.eventos .verInscricoesButton {
  cursor: pointer;
}

.eventos .associarInqueritoButton {
  cursor: pointer;
}

.eventos .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
}

.eventos .buttonAdicionarEvento {
  margin-bottom: 5px;
}

.eventos .buttonAdicionarEvento button {
  text-align: center;
  cursor: pointer;
  background-color: #a2e3fb;
  border: none;
  border-radius: 4px;
}

.eventos .buttonInscricaoEvento {
  margin-bottom: 5px;

}

.eventos .buttonInscricaoEvento button:hover {
  background-color: #43cbfd;;
}

.eventos .buttonAdicionarPresenca button {
  text-align: center;
  cursor: pointer;
  background-color: #a2e3fb;
  border: none;
  border-radius: 4px;
}


.eventos .buttonsRegisto{
  margin-bottom: 5px;
  display: -webkit-box;
  margin-left:10%;
}

.eventos .buttonsRegisto button {
  text-align: center;
  cursor: pointer;
  background-color: #a2e3fb;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
}

.eventos .buttonAdicionarEvento button:hover {
  background-color: #43cbfd;;
}
.eventos .buttonAdicionarPresenca button:hover {
  background-color: #43cbfd;;
}
</style>