<template>
  <div>
    <br>
    <div class="voltarEvento">
      <button v-on:click="switchComponent('EventsTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <br>
    <h1>Editar Evento</h1>
    <br>
    <div class="editarEvento">
      <div id="fields" class="fields">
        <div class="nomeEvento">
          <label>Nome:<i class="obrigatorio">*</i></label>
          <br>
          <input type="text" v-model="input.nome" name="nome">
        </div>
        <div class="tipo">
          <label>Tipo de Evento:</label>
          <br>
          {{this.input.tipoEvento}}
        </div>

        <div class="data">
          <label>Data:<i class="obrigatorio">*</i></label>
          <br>
          <input type="datetime-local" v-model="input.data" :min="minDate" name="data" :disabled="this.input.tipoEvento == 'PalestraCongresso' || this.input.tipoEvento == 'PalestraSimposio'">
        </div>
        <div class="localEvento">
          <label>Local:<i class="obrigatorio">*</i></label>
          <br>
          <input type="text" v-model="input.local" name="local">
        </div>
        <div class="observacoes">
          <label>Observações:</label>
          <br>
          <textarea type="text" v-model="input.observacoes" name="observacoes"></textarea>
        </div>

        <template v-if="input.tipoEvento === 'Workshop'">
          <div class="custo">
            <label>Custo:<i class="obrigatorio">*</i></label>
            <br>
            <input type="number" v-model="input.custo" name="custo" min="0.01" step="0.01">
          </div>
        </template>

        <template v-if="input.tipoEvento === 'Workshop' || input.tipoEvento === 'Palestra' || input.tipoEvento === 'PalestraSimposio' || input.tipoEvento === 'PalestraCongresso'">
          <div class="botaoOradorExistente">
            <button type="button" class="btn btn-secondary staff" v-on:click="toggleInsert()"><i class="bi bi-person-plus-fill"></i> {{buttonText}}</button>
          </div>
          <template v-if="input.oradorRecorrente">
            <div class="orador">
              <label>Orador:<i class="obrigatorio">*</i></label>
              <br>
              <vue-single-select
                v-model="input.inputOrador"
                :options="allOrators"
                :placeholder="disabledOrador ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
                option-label="nome"
                :disabled="disabledOrador"
              ></vue-single-select>
            </div>
          </template>
          
          <template v-else>
            <div class="novoOrador">
              <div class="listaPessoas">
                <label>Novo Orador:<i class="obrigatorio">*</i></label>
                <br>
                <vue-single-select
                  v-model="input.inputPessoa"
                  :options="allPersons"
                  :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
                  option-label="nome"
                  :disabled="disabledPessoa"
                ></vue-single-select>
              </div>
            
            <div class="especialidade">
              <label>Especialidade:<i class="obrigatorio">*</i></label>
              <br>
              <input name="inputEspecialidade" v-model="input.especialidade">
            </div>
            </div>
          </template>
        </template>

        <div class="registar">
          <button type="submit" v-on:click.prevent="submit()" :disabled="submiting">
            <i class="bi bi-file-earmark-check-fill"></i> Editar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  props: {
    dadosEvento: {}
  },
  activated() {
    this.$axios.get("getAllPessoasNaoOradoresIdNome")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit('logout');
        }
        else
        {
          this.allPersons = response.data;
          this.disabledPessoa = false;
        }
      })
      .catch(error => {
        if(error.response.status === 401){
            bus.$emit("logout");
        }
      });

    this.$axios.get("getAllOradoresIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allOrators = response.data;
          this.input.inputOrador = this.allOrators.find(orador => orador.id === this.dadosEvento.idOrador);
          this.disabledOrador = false;
        }
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      }); 

      (this.input.nome = this.dadosEvento.nomeEvento),
      (this.input.tipoEvento = this.dadosEvento.tipoEvento),
      (this.input.inputOrador = this.dadosEvento.idOrador),
      (this.input.data = this.dadosEvento.dataEvento.replace(" ", "T")),
      (this.input.local = this.dadosEvento.localEvento),
      (this.input.observacoes = this.dadosEvento.observacoes),
      (this.input.custo = parseFloat(this.dadosEvento.custo));

      let MyDate = new Date();
      MyDate.setDate(MyDate.getDate())
      this.minDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                      ('0' + MyDate.getDate()).slice(-2) + 'T00:00:00';  
  },
  data() {
    return {
      input: {
        nome: "",
        tipoEvento: "",
        oradorRecorrente: true,
        inputOrador: "",
        inputPessoa: "",
        data: "",
        local: "",
        observacoes: "",
        custo: "",
        especialidade: "",
      },
      allPersons: [],
      allOrators: [],
      buttonText: "Novo Orador",
      minDate: "",
      disabledPessoa: true,
      disabledOrador: true,
      submiting: false,
    };
  },
  methods: {
    switchComponent(comp) {
      this.input.oradorRecorrente = true;
      this.buttonText = "Novo Orador";
      bus.$emit("switchComp", comp);
    },
    clearFields: function() {
      (this.input.nome = ""),
        (this.input.tipoEvento = ""),
        (this.input.oradorRecorrente = true),
        (this.input.inputOrador = ""),
        (this.buttonText= "Novo Orador"),
        (this.input.inputPessoa = ""),
        (this.input.data = ""),
        (this.input.local = ""),
        (this.input.observacoes = ""),
        (this.input.custo = ""),
        (this.input.especialidade = "");
        this.switchComponent('EventsTablesMenu');
    },
    toggleInsert: function() {
      if (this.input.oradorRecorrente) {
        this.buttonText = "Cancelar";
      } else {
        this.buttonText = "Novo Orador";
      }
      this.input.oradorRecorrente = !this.input.oradorRecorrente;
    },
    submit: function() {
      //axios.post

      //Eventos com orador: Palestras e Workshops
      //Eventos com custo: Workshops

      //verificar se o tipo de palestra está vazio:
      if (this.input.tipoEvento === "") {
        this.$toasted.error("O tipo de evento não pode estar vazio");
        return;
      }
      //verificar se há outros campos obrigatorios vazios
      if (
        this.input.nome === "" ||
        this.input.oradorRecorrente === "" ||
        this.input.data === "" ||
        this.input.data == null ||
        this.input.local === "" ||
        (this.input.tipoEvento == "Workshop" && (this.input.custo === "" || this.input.custo == null))
      ) {
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
        return;
      }

      if(this.input.nome.trim() == 0 || this.input.local.trim() == 0){
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
        return;
      }      

      if(new Date(this.input.data.replace("T", " ")).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)){
        this.$toasted.error("Data do evento inválida, não pode criar eventos no passado");
        return;
      }


      if(this.input.tipoEvento == "Workshop"){
        if(this.input.custo < 0){
          this.$toasted.error("O custo do evento não pode ser menor que 0");
          return;
        }
      }
      //validar a existencia do ID escolhido de Pessoa (para prevenir inserções manuais incorrectas)

      //verificar se orador é novo ou antigo, inserir orador novo na tabela.-
      var oradorAux;
      //se a palestra for uma palestra com orador
      if (
        this.input.tipoEvento === "Palestra" ||
        this.input.tipoEvento === "Workshop" ||
        this.input.tipoEvento === "PalestraSimposio" ||
        this.input.tipoEvento === "PalestraCongresso" 
      ) {
        oradorAux = this.input.inputPessoa.id;
        //se for um orador novo
        if (!this.input.oradorRecorrente && this.input.inputPessoa.length != 0) {
          //se também for uma pessoa nova
            //post orador
            if(this.input.especialidade.trim() == 0){
              this.$toasted.error("Especialidade do orador inválida");
              return;
            }

            this.submiting = true;

            this.$axios.post("postOrador",
                {
                  idOrador: this.input.inputPessoa.id,
                  especialidade: this.input.especialidade
                },
              ).then(response => {
                if (response.status == 401)
                {
                  bus.$emit("logout");
                }
                else if (response.status === 201) {
                  this.$toasted.success("Inserção de Orador realizada com sucesso");
                }
                else {
                  this.submiting = false;
                  this.$toasted.error("Erro ao inserir Orador");
                }
              })
              .catch((errors) => {
                if (errors.response.status === 400) {
                  this.$toasted.error("Campos incorrectos ou incompletos");
                }
                if (errors.response.status === 401) {
                  bus.$emit("logout");
                }
              });
          
        }else if(this.input.inputOrador == null){
            oradorAux = null;
        } 
        else {
          //se for um orador já existente
          oradorAux = this.input.inputOrador.id;
        }
      }

      //---------------------------------------------------------------------------------------------------

      //INSERÇÕES DE EVENTO
      //Evento: idOrador, nome, data, local, tipoEvento, observacoes
      switch (this.input.tipoEvento) {
        case "Palestra":
          this.submiting = true;
          this.$axios.put("editEvento/" + this.dadosEvento.idEvento,
              {
                idOrador: oradorAux,
                nomeEvento: this.input.nome,
                dataEvento: this.input.data.replace("T", " "),
                localEvento: this.input.local,
                tipoEvento: this.input.tipoEvento,
                observacoes: this.input.observacoes   
              },
            )
            .then(response => {
              if(response.status == 401)
              {
                bus.$emit("logout");
              }
              else if (response.status === 200) {
                this.$toasted.success("Evento " + this.input.nome + " editado com sucesso");
                this.clearFields();
              }
              else {
                this.$toasted.error("Erro ao editar evento");
              }
              this.submiting = false;
            })
            .catch(error => {
              if(error.response.status === 401){
                bus.$emit("logout");
              }
              this.$toasted.error("Erro na inserção de evento");
            });
          break;

        case "Workshop":

          if(this.input.custo == "" || this.input.custo == null){
            this.$toasted.error("O custo do evento é inválido");
            return;
          }
          //inserir workshop e custo
          this.submiting = true;
          this.$axios.put("editEvento/" + this.dadosEvento.idEvento,
              {
                idOrador: oradorAux,
                nomeEvento: this.input.nome,
                dataEvento: this.input.data.replace("T", " "),
                localEvento: this.input.local,
                tipoEvento: this.input.tipoEvento,
                observacoes: this.input.observacoes

              },
            )
            .then(response => {
              if(response.status == 401)
              {
                bus.$emit("logout");
              }
              else if (response.status === 200) 
              {

                this.$axios.put("editWorkshop/" + this.dadosEvento.idEvento,
                    {
                      custo: this.input.custo
                    },
                  )
                  .then(response => {
                    if(response.status == 401)
                    {
                      bus.$emit("logout");
                    }
                    else if (response.status === 200) {
                      this.$toasted.success("Evento " + this.input.nome + " editado com sucesso");
                      this.clearFields();
                    }
                    else {
                      this.$toasted.error("Erro ao editar evento");
                    }
                    this.submiting = false;
                  })
                  .catch((errors) => {
                    if (errors.response.status === 400) {
                      this.$toasted.error("Campos incorrectos ou incompletos");
                    }
                    if (errors.response.status === 401) {
                      bus.$emit("logout");
                    }


                  });
              }
              else
              {
                this.submiting = false;
              }
            })
            .catch((errors) => {
              if (errors.response.status === 400) {
                this.$toasted.error("Campos incorrectos ou incompletos");
              }
              if (errors.response.status === 401) {
                bus.$emit("logout");
              }
            });

          break;

        default:
          //isto significa que é um evento sem orador, sem custo.
          this.submiting = true;
          this.$axios.put("editEvento/" + this.dadosEvento.idEvento,
              {
                idOrador: this.input.tipoEvento == "PalestraSimposio" || this.input.tipoEvento == "PalestraCongresso" ? oradorAux : null,
                nomeEvento: this.input.nome,
                dataEvento: this.input.data.replace("T", " "),
                localEvento: this.input.local,
                tipoEvento: this.input.tipoEvento,
                observacoes: this.input.observacoes
              },
            )
            .then(response => {
              if(response.status == 401)
              {
                bus.$emit("logout");
              }
              else if (response.status === 200) 
              {
                this.$toasted.success("Evento " + this.input.nome + " editado com sucesso");
                this.clearFields();
              }
              else
              {
                this.$toasted.error("Erro ao editar evento");
              }
              this.submiting = false;
            })
            .catch(error => {
              if(error.response.status === 401){
                bus.$emit("logout");
              }
            });
          break;
      }
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.editarEvento .registar button:hover:disabled
{
  background-color: #e99d72;
  cursor: not-allowed;
}

h1 {
  text-align: center;
  font-family: "Open Sans";
}

.voltarEvento button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.voltarEvento i {
  font-size: 17px;
  margin-right: 3px;
}
.voltarEvento button:hover {
  background-color: #fdf5e6
}

.editarEvento {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.editarEvento .fields {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  background-color: oldlace;
  width: 100%;
}

.editarEvento .fields .obrigatorio{
  color: red;
}

.editarEvento .nomeEvento {
  grid-column-start: 1;
  margin-top: 10px;
  margin-left: 115px;
}

.editarEvento .nomeEvento input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.editarEvento .tipo {
  margin-right: 115px;
  margin-top: 10px;
}
.editarEvento .tipo select {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.editarEvento .data {
  margin-left: 115px;
}
.editarEvento .data input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.editarEvento .localEvento {
  margin-right: 115px;
}
.editarEvento .localEvento input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.editarEvento .observacoes {
  margin-left: 115px;
}
.editarEvento .observacoes textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #a1a0a0;
  resize: none;
  border-radius: 4px;
}

.editarEvento .custo {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 3;
}
.editarEvento .custo input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.editarEvento .orador {
  height: 15px;
  margin-left: 115px;
  grid-row-start: 4;
  grid-column-start: 1;
  margin-bottom: 55px;
}

.editarEvento .botaoOradorExistente {
  margin-right: 115px;
  justify-content: center;
  display: flex;
  align-items: center;
  grid-row-start: 3;
  grid-column-start: 2;
  margin-top: 70px;
}

.editarEvento .botaoOradorExistente input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.editarEvento .botaoOradorExistente a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.editarEvento .novoOrador {
  grid-row-start: 5;
  margin-left: 115px;
  display: contents;
}

.editarEvento .novoOrador .listaPessoas {
  grid-column-start: 1;
  grid-row-start: 5;
  margin-left: 115px;
}

.editarEvento .novoOrador .botaoPessoaExistente {
  margin-right: 115px;
  justify-self: end;
  margin-top: 30px;
  grid-column-start: 2;
  grid-row-start: 5;
}

.editarEvento .novoOrador .botaoPessoaExistente input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.editarEvento .novoOrador .botaoPessoaExistente a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.editarEvento .novoOrador .nome {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 6;
}
.editarEvento .novoOrador .nome input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.editarEvento .novoOrador .email {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 6;
}
.editarEvento .novoOrador .email input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}
.editarEvento .novoOrador .telefone {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 7;
}
.editarEvento .novoOrador .telefone input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.editarEvento .novoOrador .genero {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 7;
}

.editarEvento .novoOrador .zona {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 8;
}
.editarEvento .novoOrador .zona input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.editarEvento .novoOrador .idade {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 8;
}
.editarEvento .novoOrador .idade input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.editarEvento .novoOrador .especialidade {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 9;
}
.editarEvento .novoOrador .especialidade input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}
.editarEvento .novoOrador .nif {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 9;
}
.editarEvento .novoOrador .nif input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}
.editarEvento .novoOrador .comoConheceu {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 10;
  width: 50%;
  display: -webkit-box;
  height: 35px;
}

.editarEvento .novoOrador .comoConheceu select {
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 30px;
}

.editarEvento .novoOrador .comoConheceu .atravesQuem {
  margin-top: 11px;
  width: 118%;
  margin-left: 9px;
}

.editarEvento .novoOrador .comoConheceu .local {
  margin-top: 16px;
  width: 100%;
  margin-left: 9px;
}

.editarEvento .novoOrador .comoConheceu .local input {
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  width: 122%;
  height: 35px;
}

.editarEvento .registar {
  margin-right: 115px;
  margin-bottom: 30px;
  grid-column-start: 2;
  grid-row-start: 11;
  align-self: end;
  justify-self: end;
}

.editarEvento .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.editarEvento .registar button:hover {
  background-color: #fab995;
}
</style>