<template>
  <div>
    <br />
    <div class="voltarEvento">
      <button v-on:click="mudarComponente('EventsTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <div class="inqueritos">
      <h1>Associar Inquérito a Evento</h1>
      <div class="loader" v-if="isLoading">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div> 
        <h3>A carregar inquéritos ...</h3>
      </div>
      <div class="tabela mt-5" v-else>
        <div class="tabela-paginate" v-if="inqueritos.length != 0">
          <table class="w3-table-all" id="tabela">
            <tr class="conteudo">
              <th>ID</th>
              <th>Questões</th>
            </tr>
            <tr v-for="(inquerito, tableKey) in inqueritos" :key="tableKey">
              <td>{{ inquerito.idInquerito }}</td>
              <td class="questoes" style="white-space: pre-line">
                {{
                  concatenaQuestoes(inquerito.questoes).length > 0
                    ? concatenaQuestoes(inquerito.questoes)
                    : "Sem Questões"
                }}
              </td>
              <td class="buttons">
                <div class="associarInqueritoButton">
                  <a
                    class="bi bi-plug-fill"
                    v-on:click="submit(inquerito.idInquerito)"
                    title="Associar Inquérito ao Evento"
                  ></a>
                </div>
                <div class="apagarButton">
                  <a class="bi bi-trash-fill" v-on:click="apagar(inquerito)" title="Apagar Inquérito"></a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page="page" :total="total" @pagination="getInqueritos"></Pagination>
        </div>
        <div class="text-center" v-else>
          <h2>Sem inquéritos</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../main.js";

export default {
  props: {
    idEvento: {
      required: true,
    },
  },
  data() {
    return {
      inqueritos: [],
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      isLoading: true,
    };
  },
  activated() {
    this.getInqueritos();
  },
  mounted() {
    bus.$on("refreshInqueritosLink", () => {
      this.getInqueritos();
    });
  },
  methods: {
    getInqueritos(page) {
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllInqueritos?pageURL="+(page ? page : 1))
        .then((response) => {
          this.page = page || 1;
          if (response.status == 401) {
            bus.$emit("logout");
          } else {
            this.inqueritos = response.data.inqueritos;
            this.total = response.data.total;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.$toasted.error("Ocorreu um erro");
          }
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
        });
    },

    mudarComponente(comp) {
      bus.$emit("switchComp", comp);
    },
    concatenaQuestoes(questoes) {
        let aux = "";

        questoes.forEach((element) => {
            aux += element.questao + "\n";
        });
        
        return aux;
    },
    submit(idInquerito) {
      this.$axios.post("postAssociarInquerito", {
          idInquerito: idInquerito,
          idEvento: this.idEvento,
        })
        .then((response) => {
          if(response.status == 401)
          {
            bus.$emit("logout");
          }
          else if(response.status == 201)
          {
            this.$toasted.show("Inquerito associado com sucesso");
          }
          else if(response.status == 409)
          {
            this.$toasted.show("O inquérito já se encontra associado a este evento");
          }
          else
          {
            this.$toasted.error("Ocorreu um erro ao associar o inquérito");
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.$toasted.error("Ocorreu um erro");
          }
        });
    },
    apagarInquerito(inquerito)
    {
      this.$axios.delete("deleteInquerito/" + inquerito.idInquerito)
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status == 200)
          {
            this.$toasted.success("Inquérito eliminado com sucesso")
            this.getInqueritos();
          }
          else
          {
            this.$toasted.error("Erro ao eliminar inquérito")
          }
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
          else
          {
            this.$toasted.error("Erro ao eliminar inquérito")
          }
        });
    },
    apagar(inquerito)
    {
      this.title = "Eliminar Inquérito";
      this.message = "Tem a certeza que pretende eliminar o inquérito ? \n ** Atenção que apagará todas as respostas dos eventos associadas ao mesmo **";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.apagarInquerito(inquerito);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    }
  },
};
</script>
<style scoped>

.loader{
  margin-top: 90px;
}

td.questoes {
    text-align: start !important;
}

.apagarButton {
  cursor: pointer;
}

.apagarButton a {
  font-weight: bold;
  font-size: 16px;
}

.apagarButton a:hover {
  font-weight: bold;
  font-size: 14px;
}

.tabela-paginate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.voltarEvento button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
}

.voltarEvento i {
  font-size: 17px;
  margin-right: 3px;
}

.voltarEvento button:hover {
  background-color: #fdf5e6;
}

.inqueritos h1 {
  text-align: center;
}

.inqueritos {
  font-family: "Open Sans";
}

.inqueritos .tabela {
  width: 100%;
  margin-bottom: 20px;
}

.inqueritos #tabela {
  width: 80%;
  margin: auto;
}

.inqueritos .tabela table td {
  border: 1px solid #ccc;
}

.inqueritos .dados table td {
  width: 15%;
}

.inqueritos .tabela table th {
  border: 1px solid #ccc;
}

.inqueritos .tabela .buttons {
  width: 1px;
  border-color: white;
  background-color: white;
  border-top: none;
  vertical-align: middle;
  text-align: center;
}

.inqueritos .associarInqueritoButton {
  cursor: pointer;
}

.inqueritos .associarInqueritoButton a {
  font-weight: bold;
  font-size: 21px;
}

.inqueritos .associarInqueritoButton a:hover {
  font-weight: bold;
  font-size: 19px;
}
.inqueritos .nota {
  margin-left: 10%;
}

.inqueritos .nota:hover {
  color: black;
}
</style>