var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conteudo"},[_c('div',{staticClass:"conteudo-group"},[_c('label',{staticClass:"nota-mensagem"},[_c('label',{staticClass:"nota-mensagem-nota"},[_vm._v("Nota: ")]),_vm._v(_vm._s(_vm.tipoMensagem == 'A' || _vm.tipoMensagem == 'AS' ? ' Caso queira adicionar o nome da pessoa à mensagem coloque na mesma ' : ' Caso queira adicionar o nome do evento à mensagem, o link de inscrição ou a data do mesmo coloque na mesma ')),_c('label',{staticClass:"nota-mensagem-variavel"},[_vm._v(_vm._s(_vm.tipoMensagem == 'A' || _vm.tipoMensagem == 'AS' ? ' {nomePessoa}' : ' {nomeEvento} {linkEvento} {dataEvento}'))])]),_c('div',{staticClass:"form-group-titulo"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mensagem.titulo),expression:"mensagem.titulo"}],staticClass:"form-control",attrs:{"type":"text","name":"titulo","placeholder":"Título"},domProps:{"value":(_vm.mensagem.titulo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mensagem, "titulo", $event.target.value)}}})]),_c('div',{staticClass:"form-group-mensagem"},[_vm._m(1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.mensagem.mensagem),expression:"mensagem.mensagem"}],staticClass:"form-control",attrs:{"id":"mensagem","rows":"10","placeholder":_vm.placeholderMensagem},domProps:{"value":(_vm.mensagem.mensagem)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mensagem, "mensagem", $event.target.value)}}})]),(_vm.tipoMensagem=='E')?[_c('div',{staticClass:"form-group-evento"},[_vm._m(2),_c('vue-single-select',{attrs:{"options":_vm.allEvents,"placeholder":_vm.disabledEvento ? 'A carregar dados...' : 'Escolher Evento',"option-label":"nome","disabled":_vm.disabledEvento,"classes":{
                        pointer: -1,
                        wrapper: 'single-select-wrapper',
                        input: 'search-input-mensagem',
                        icons: 'icons',
                        required: 'required',
                        activeClass: 'active',
                        dropdown: 'dropdown'
                    }},on:{"input":_vm.onChangeEvento},model:{value:(_vm.mensagem.evento),callback:function ($$v) {_vm.$set(_vm.mensagem, "evento", $$v)},expression:"mensagem.evento"}})],1),_c('div',{staticClass:"form-group-data"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mensagem.dataEnvio),expression:"mensagem.dataEnvio"}],staticClass:"form-control",attrs:{"min":_vm.minDate,"max":_vm.maxDate,"type":"date","name":"data","disabled":!_vm.mensagem.evento},domProps:{"value":(_vm.mensagem.dataEnvio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mensagem, "dataEnvio", $event.target.value)}}})])]:_vm._e(),_c('div',{staticClass:"botoes"},[_c('button',{staticClass:"btn btn-secondary mensagem",attrs:{"type":"button","disabled":_vm.submiting},on:{"click":_vm.registarEditar}},[_c('i',{staticClass:"bi bi-device-ssd"}),_vm._v(" "+_vm._s(_vm.acao == 'E' ? 'Editar Mensagem' : 'Criar Mensagem'))])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_vm._v("Título da Mensagem:"),_c('i',{staticClass:"obrigatorio"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_vm._v("Mensagem:"),_c('i',{staticClass:"obrigatorio"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"mensagem"}},[_vm._v("Evento:"),_c('i',{staticClass:"obrigatorio"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_vm._v("Data de Envio:"),_c('i',{staticClass:"obrigatorio"},[_vm._v("*")])])}]

export { render, staticRenderFns }