<template>
<div>
    <RelatorioHistoricoParticipantes/>
    <RelatorioNumeroEventosTipo/>
    <RelatorioTopsPublico/>
    <RelatorioTopsOradores/>
    <RelatorioTopsEmpresario/>
</div>
</template>

<script>

//import VueApexCharts from 'vue-apexcharts'
export default {
    data() {
        return{

        }
    }
}
</script>