<template>
  <div>
    <div class="registarDonativo">
      <div class="fields">
        <template v-if="tipoDonativo == 'p'">
          <div class="novaPessoa">
            <button type="button" class="btn btn-secondary addPessoa" v-on:click="toggleInsert()"><i class="bi bi-person-plus-fill"></i> {{buttonText}}</button>
          </div>

          <template v-if="!input.novaPessoa">
            <!-- já frequentou, user existente -->
            <div class="listaPessoas">
              <label>
                Pessoa:
                <i class="obrigatorio">*</i>
              </label>
              <br />
              <vue-single-select
                      v-model="input.Pessoa"
                      :options="allPersons"
                      :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome,email,telemóvel ou nif'"
                      option-label="nome"
                      :disabled="disabledPessoa"
              ></vue-single-select>
            </div>
          </template>
          <template v-else>
            <!-- user novo -->
            <div class="nome">
              <label>
                Nome:
                <i class="obrigatorio">*</i>
              </label>
              <br />
              <input type="text" v-model="inputPessoa.nome" name="nome" />
            </div>
            <div class="email">
              <label>
                Email:
                <i class="obrigatorio">*</i>
              </label>
              <br />
              <input type="text" v-model="inputPessoa.email" name="email" />
            </div>
            <div class="telefone">
              <label>
                Telefone:
                <i class="obrigatorio">*</i>
              </label>
              <br />
              <input type="text" v-model="inputPessoa.telefone" name="telefone" />
            </div>
            <div class="receberEmail-group">
              <label>Receber Emails informativos:<i class="obrigatorio">*</i></label>
              <input type="checkbox" class="receberEmail" v-model="inputPessoa.receberEmail">
            </div>
            <div class="genero">
              <label>Género:<i class="obrigatorio">*</i></label>
              <br>
              <input type="radio" v-model="inputPessoa.genero" name="M" value="M"> Masculino
              <br>
              <input type="radio" v-model="inputPessoa.genero" name="F" value="F"> Feminino
              <br>
              <input type="radio" v-model="inputPessoa.genero" name="O" value="O"> Outro
              <br>
            </div>
            <div class="zona">
              <label>
                Zona:
                <i class="obrigatorio">*</i>
              </label>
              <br />
              <input type="text" v-model="inputPessoa.zona" name="zona" />
            </div>
            <div class="idade">
              <label>
                Data de Nascimento:
                <i class="obrigatorio">*</i>
              </label>
              <br />
              <input type="date" v-model="inputPessoa.dataNasc" name="idade" :max="maxDateNasc"/>
            </div>
            <div class="nif">
              <label>NIF:</label>
              <br />
              <input type="text" v-model="inputPessoa.nif" name="nif" />
            </div>
            <div class="comoConheceu">
              <label>Como Conheceu:<i class="obrigatorio">*</i></label>
              <div class="comoConheceuInputs">
                <select v-model="inputPessoa.comoConheceu">
                  <option>Pessoa</option>
                  <option value="Local">Folheto em Local</option>
                  <option>Redes Sociais</option>
                  <option>E-Mail</option>
                </select>
                <template v-if="inputPessoa.comoConheceu === 'Pessoa'">
                    <vue-single-select
                            v-model="inputPessoa.atravesDe"
                            :options="allPersons"
                            :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome,email,telemóvel ou nif'"
                            option-label="nome"
                            :disabled="disabledPessoa"
                    ></vue-single-select>
                </template>
                <template v-else-if="inputPessoa.comoConheceu === 'Local'">
                    <input type="text" class="local" v-model="input.atravesDe" placeholder="Local">
                </template>
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="tipoDonativo == 'e'">
          <div class="empresario">
            <label>
              Empresario:
              <i class="obrigatorio">*</i>
            </label>
            <br />
            <vue-single-select
                v-model="input.empresario"
                :options="allEmpresarios"
                :placeholder="disabledEmpresario ? 'A carregar dados...' : 'Procurar por nome,email,telemóvel ou empresa'"
                option-label="nome"
                :disabled="disabledEmpresario"
            ></vue-single-select>
          </div>
          <div class="empresa">
            <label>Empresa: {{this.empresaEmpresario}}</label>
          </div>
        </template>
        <div class="quantidade">
          <label>
            Quantidade:
            <i class="obrigatorio">*</i>
          </label>
          <br />
          <input
            type="number"
            v-model="input.quantidadeDonativo"
            name="quantidade"
            min="0.01"
            step="0.01"
          />
        </div>
        <div class="data">
          <label>
            Data:
            <i class="obrigatorio">*</i>
          </label>
          <br />
          <input type="date" v-model="input.dataDonativo" name="data" :max="maxDate"/>
        </div>
        <template v-if="(isNovoAmigoSocio || input.novaPessoa ) && tipoDonativo != 'e'">
          <div class="morada">
            <label>
              Morada:
              <i class="obrigatorio">*</i>
            </label>
            <br />
            <textarea type="text" v-model="input.morada" name="observacoes"></textarea>
          </div>
          <div class="codPostal">
            <label>
              Código Postal:
              <i class="obrigatorio">*</i>
            </label>
            <br />
            <input type="text" v-model="input.codigoPostal" name="observacoes" />
          </div>
        </template>
        <div class="observacoes">
          <label>Observações:</label>
          <br />
          <textarea type="text" v-model="input.observacoes" name="observacoes"></textarea>
        </div>
        <div class="registar">
          <button type="submit" v-on:click.prevent="submit()" :disabled="submiting">
            <i class="bi bi-file-earmark-check-fill"></i> Registar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  props: {
    tipoDonativo: {
      type: String,
      required: true
    }
  },
  mounted() {
    if (this.tipoDonativo === "p") {
      this.$axios.get("getAllPessoasIdNome/")
        .then(response => {
          if(response.status == 401)
          {
            bus.$emit('logout');
          }
          else
          {
            this.allPersons = response.data;
            this.disabledPessoa = false;
          }
        })
        .catch(() => {

        });
    } else {
      this.$axios.get("getAllEmpresariosIdNome")
        .then(response => {
          if(response.status == 401)
          {
            bus.$emit('logout');
          }
          else
          {
            this.allEmpresarios = response.data;
            this.disabledEmpresario = false;
          }
        })
        .catch(() => {

        });
    }
    
    let MyDate = new Date();
    this.maxDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                        ('0' + MyDate.getDate()).slice(-2);

    this.maxDateNasc = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                        ('0' + MyDate.getDate()).slice(-2);
          
  },
  data() {
    return {
      input: {
        novaPessoa: false,
        empresario: "",
        Pessoa: null,
        dataDonativo: "",
        morada: "",
        codigoPostal: "",
        quantidadeDonativo: 0.01,
        observacoes: ""
      },
      inputPessoa: {
        nome: "",
        email: "",
        genero: "",
        dataNasc: "",
        telefone: "",
        zona: "",
        nif: "",
        comoConheceu: "Pessoa",
        atravesDe: "",
        receberEmail: 1
      },
      isNovoAmigoSocio: true,
      allPersons: [],
      allEmpresarios: [],
      empresaEmpresario: "",
      buttonText: "Adicionar Pessoa",
      maxDate: null,
      maxDateNasc: null,
      disabledPessoa: true,
      disabledEmpresario: true,
      submiting: false,
    };
  },
  watch: {
    "input.Pessoa": function() {
      if(this.input.Pessoa)
      {
        this.$axios.get("getAmigoSocio/" + this.input.Pessoa.id)
          .then(response => {
            if(response.status == 401)
            {
              bus.$emit('logout');
            }
            else
            {
              this.checkIfAmigoSocio(response.data ? false : true);
            }
          }).catch(() => {

          });
      }
    },
    "input.empresario": function() {
      if(this.input.empresario)
      {
        this.empresaEmpresario = this.input.empresario.nomeEmpresa;
      }
      else
      {
        this.empresaEmpresario = "";
      }
    }
  },
  methods: {
    checkIfAmigoSocio: function(status) {
      if (status) {
        this.isNovoAmigoSocio = true;
      } else {
        this.isNovoAmigoSocio = false;
      }
    },
    clearFields: function() {
      switch (this.tipoDonativo) {
        case "p":
          bus.$emit("refreshAmigoSocio");
          break;
        case "e":
          bus.$emit("donativoRegisto");
          break;
      }
    },
    validarEmail(email) {

      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!email.match(validRegex)) {

        return false;
      }
      return true;
    },
    toggleInsert: function() {
      if (!this.input.novaPessoa) {
        this.buttonText = "Cancelar";
      } else {
        this.buttonText = "Adicionar Pessoa";
      }
      this.input.novaPessoa = !this.input.novaPessoa;
    },
    submit: function() { //TODO: Consolidar codigo de submissão
    
      if (this.input.dataDonativo == "" || this.input.dataDonativo == null) {
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
        return;
      }

      if(new Date(this.input.dataDonativo).getTime() > new Date().getTime())
      {
        this.$toasted.error("Data do donativo inválida");
        return;
      }

      if (this.input.quantidadeDonativo <= 0) {
        this.$toasted.error("Não se pode efetuar um donativo de quantidade inferior ou igual a 0");
        return;
      }

      switch (this.tipoDonativo) {
        case "p":
          //se a pessoa já for amigo sócio, não é necessário inserir na tabela. Se não for, tem de se inserir.
          //se for uma pessoa nova, está garantido não ser amigo sócio

          var idPessoaAux = this.input.Pessoa ? this.input.Pessoa.id : null;


          if (
            this.input.dataDonativo == "" ||
            this.input.dataDonativo == null
          ) {
            this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
            return;
          }

          if (this.input.novaPessoa) {
            if (
              this.inputPessoa.nome == "" ||
              this.inputPessoa.nome == null ||
              this.inputPessoa.email == "" ||
              this.inputPessoa.email == null ||
              this.inputPessoa.telefone == "" ||
              this.inputPessoa.telefone == null ||
              this.inputPessoa.zona == "" ||
              this.inputPessoa.zona == null ||
              this.inputPessoa.genero == "" ||
              this.inputPessoa.genero == null ||
              this.inputPessoa.dataNasc == "" ||
              this.inputPessoa.dataNasc == null ||
              this.inputPessoa.receberEmail == null
            ) {
              this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
              return;
            }

            if(this.inputPessoa.nome.trim().length == 0 || this.inputPessoa.zona.trim().length == 0){
              this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
              return;
            }

            if(
              this.input.codigoPostal == "" ||
              this.input.codigoPostal == null ||
              this.input.morada == "" ||
              this.input.morada == null
            ){
              this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
              return;
            }

            if(this.input.morada.trim().length == 0){
              this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
              return;
            }
            
            if (!/^\d{4}(-\d{3})?$/.test(this.input.codigoPostal)) {
              this.$toasted.error("Formato de código postal inválido");
              return;
            }

            if(this.inputPessoa.genero != "M" && this.inputPessoa.genero != "F" && this.inputPessoa.genero != "O"){
              this.$toasted.error("Campo Género inválido");
              return;
            }

            if(this.inputPessoa.comoConheceu != "Pessoa" && this.inputPessoa.comoConheceu != "Local" && this.inputPessoa.comoConheceu != "Redes Sociais" && this.inputPessoa.comoConheceu != "E-Mail"){
              this.$toasted.error("O campo Como Conheceu é inválido");
              return;
            }

            if(this.inputPessoa.telefone.length > 15){
              this.$toasted.error("Número de telefone com tamanho inválido");
              return;
            }

            if(!/^\d+$/.test(this.inputPessoa.telefone)){
              this.$toasted.error("Caracteres inválidos no número de telefone");
              return;
            }

            if(this.inputPessoa.nif != null){
              if(this.inputPessoa.nif != "" && this.inputPessoa.nif.trim().length == 0)
              {
                this.$toasted.error("NIF inválido");
                return;
              }
              else if(this.inputPessoa.nif != "" && this.inputPessoa.nif.length != 9)
              {
                this.$toasted.error("NIF de tamanho inválido");
                return;
              }
            }

            if(!this.validarEmail(this.input.email)){
              this.$toasted.error("Formato de email inválido");
              return;
            }

            if(new Date(this.inputPessoa.dataNasc).getTime() > new Date().getTime() ){
              this.$toasted.error("Data de nascimento inválida");
              return;
            }

            this.submiting = true;

            this.$axios.post("postPessoa/",
              {
                  nome: this.inputPessoa.nome,
                  email: this.inputPessoa.email,
                  telefone: this.inputPessoa.telefone,
                  zona: this.inputPessoa.zona,
                  dataNasc: this.inputPessoa.dataNasc,
                  genero: this.inputPessoa.genero,
                  nif: this.inputPessoa.nif ? this.inputPessoa.nif : null,
                  comoConheceu:
                    this.inputPessoa.comoConheceu === "Local"
                      ? this.inputPessoa.atravesDe
                      : this.inputPessoa.comoConheceu,
                  atravesQuem:
                    this.inputPessoa.comoConheceu === "Pessoa" ? this.inputPessoa.atravesDe.id : null,
                  receberEmail: this.inputPessoa.receberEmail == true ? 1 : this.inputPessoa.receberEmail == false ? 0 : this.inputPessoa.receberEmail

              }).then(response => {

                if (response.status == 401) {
                  bus.$emit("logout");
                
                }else if (response.status == 201) {
                  idPessoaAux = response.data;
                  this.$axios.post("postAmigoSocio",
                    {
                      idAmigoSocio: idPessoaAux,
                      morada: this.input.morada,
                      codPostal: this.input.codigoPostal,
                      dataExpiracao: this.input.dataDonativo.replace(new Date(this.input.dataDonativo).getFullYear(), new Date(this.input.dataDonativo).getFullYear() + 1)

                    },
                  ).then(response => {
                    if (response.status === 201) {
                      this.$toasted.success("Novo Amigo Sócio inserido");
                      //aqui inserimos o donativo
                      this.$axios.post("postDonativoPessoa/",
                          {
                            idPessoa: idPessoaAux,
                            dataDonativo: this.input.dataDonativo,
                            quantidadeDonativo: this.input.quantidadeDonativo,
                            observacoes: this.input.observacoes ? this.input.observacoes  : null
                          },
                        )
                        .then(response => {
                          if (response.status === 201) {
                            this.$toasted.success("Novo Donativo inserido");
                            this.clearFields();
                          }
                          else if (response.status === 401) {
                            bus.$emit("logout");
                          }
                          this.submiting = false;
                        })
                        .catch(error => {
                          if (error.response.status === 401) {
                            bus.$emit("logout");
                          }
                          this.$toasted.error("Erro a inserir Amigo Sócio");
                        });
                      }
                    })
                    .catch(error => {
                      if (error.response.status === 401) {
                        bus.$emit("logout");
                      }
                      this.$toasted.error("Erro a inserir Amigo Sócio");
                    });
                }
                else if(response.status == 400 && response.data == 'nif'){
                  this.$toasted.error("Nif já existente! Possível pessoa duplicada");
                  this.submiting = false;
                }
                else if(response.status == 400 && response.data == 'email'){
                  this.$toasted.error("Email já existente! Possível pessoa duplicada");
                  this.submiting = false;
                }
                else if(response.status == 400 && response.data == 'telefone'){
                  this.$toasted.error("Telefone já existente! Possível pessoa duplicada");
                  this.submiting = false;
                }
                else
                {
                  this.submiting = false;
                }

                }).catch(() => {
                    this.$toasted.error("Erro a registar Pessoa");
                })
            return;
          }

          //não é uma pessoa nova, verificamos se não é ainda um amigo sócio
          if (this.isNovoAmigoSocio) {

            if(
              this.input.codigoPostal == "" ||
              this.input.codigoPostal == null ||
              this.input.morada == "" ||
              this.input.morada == null
            ){
              this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
              return;
            }

            if(this.input.morada.trim().length == 0){
              this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
              return;
            }
            
            if (!/^\d{4}(-\d{3})?$/.test(this.input.codigoPostal)) {
              this.$toasted.error("Formato de código postal inválido");
              return;
            }

            this.submiting = true;

            this.$axios.post("postAmigoSocio",
                {
                  idAmigoSocio: idPessoaAux,
                  morada: this.input.morada,
                  codPostal: this.input.codigoPostal,
                  dataExpiracao: this.input.dataDonativo.replace(new Date(this.input.dataDonativo).getFullYear(), new Date(this.input.dataDonativo).getFullYear() + 1)

                },
              )
              .then(response => {
                if (response.status === 201) {
                  this.$toasted.success("Novo Amigo Sócio inserido");
                  //inserir o donativo
                  this.$axios.post("postDonativoPessoa/",
                      {
                        idPessoa: idPessoaAux,
                        dataDonativo: this.input.dataDonativo,
                        morada: this.input.morada,
                        codigoPostal: this.input.codigoPostal,
                        quantidadeDonativo: this.input.quantidadeDonativo,
                        observacoes: this.input.observacoes ? this.input.observacoes : null
                      },
                    )
                    .then(response => {
                      if (response.status === 201) {
                        this.$toasted.success("Novo Donativo inserido com sucesso");
                        this.clearFields();
                      }
                      else if (response.status === 401) {
                        bus.$emit("logout");
                      }
                      else
                      {
                        this.$toasted.error("Erro a inserir donativo");
                      }
                      this.submiting = false;
                    })
                    .catch(error => {
                      if (error.response.status === 401) {
                        bus.$emit("logout");
                      }
                      this.$toasted.error("Erro a inserir Amigo Sócio");
                    });
                }
                else if (response.status === 401) {
                  bus.$emit("logout");
                }
                else {
                  this.$toasted.error("Erro a inserir Amigo Sócio");
                  this.submiting = false;
                }
              })
              .catch(error => {
                if (error.response.status === 401) {
                  bus.$emit("logout");
                }
                this.$toasted.error("Erro a inserir Amigo Sócio");
              });
          } else {
            //já é amigo sócio,
            //basta inserir o donativo e atualizar o sócio
            this.submiting = true;

            this.$axios.post("postDonativoPessoa/",
                {
                  idPessoa: idPessoaAux,
                  dataDonativo: this.input.dataDonativo,
                  morada: this.input.morada,
                  codigoPostal: this.input.codigoPostal,
                  quantidadeDonativo: this.input.quantidadeDonativo,
                  observacoes: this.input.observacoes
                },
              )
              .then(response => {
                if(response.status == 401){
                  bus.$emit("logout");
                }
                else if (response.status === 201) {
                  //update Amigo Sócio
                  this.$axios.put("editAmigoSocio/" + idPessoaAux,
                      {
                        dataExpiracao: this.input.dataDonativo.replace(new Date(this.input.dataDonativo).getFullYear(), new Date(this.input.dataDonativo).getFullYear() + 1)
                      },
                    )
                    .then(() => {
                      if(response.status == 401)
                      {
                        bus.$emit("logout");
                      }
                      else
                      {
                        this.$toasted.success("Novo donativo inserido com sucesso");
                        this.clearFields();
                      }
                      this.submiting = false;
                    })
                    .catch(() => {

                    });
                }
              })
              .catch(() => {
                this.$toasted.error("Erro a inserir donativo");

              });
          }
  
        break;

        case "e":
          if(this.input.empresario == "" || this.input.empresario == null){
             this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
             return;
          }

          this.submiting = true;

          this.$axios.post("postDonativoEmpresa/",
              {
                idEmpresario: this.input.empresario.id,
                idEmpresa: this.input.empresario.idEmpresa,
                dataDonativo: this.input.dataDonativo,
                quantidadeDonativo: this.input.quantidadeDonativo,
                observacoes: this.input.observacoes
              },
            )
            .then(response => {
              if (response.status === 401) {
                bus.$emit("logout");
              }
              else  if (response.status === 201) 
              {
                this.$toasted.success("Novo donativo inserido");
                this.clearFields();
              }
              else
              {
                this.$toasted.error("Erro a inserir donativo");
              }
              this.submiting = false;

            })
            .catch(error => {
              if (error.response.status === 401) {
                bus.$emit("logout");
              }
              this.$toasted.error("Erro a inserir donativo");

            });
          break;
      }
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.registarDonativo .registar button:hover:disabled {
  background-color: #e99d72;
  cursor: not-allowed;
}

input.receberEmail {
    width: 14px;
    height: 14px;
}

.receberEmail-group {
    margin-left: 115px;
    grid-column-start: 1;
    grid-row-start: 3;
    margin-top: 80px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

button.btn.btn-secondary.addPessoa
{
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.registarDonativo {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.registarDonativo .fields {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  background-color: oldlace;
  width: 100%;
}

.registarDonativo .fields .obrigatorio {
  color: red;
}
.registarDonativo .empresario {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 10px;
  margin-left: 115px;
}

.registarDonativo .empresa {
  margin-right: 115px;
  grid-row-start: 1;
  grid-column-start: 2;
  margin-top: 30px;
  justify-self: start;
}
.registarDonativo .listaPessoas {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 10px;
  margin-left: 115px;
}

.registarDonativo .novaPessoa {
  margin-right: 115px;
  grid-row-start: 1;
  grid-column-start: 2;
  justify-self: end;
  margin-top: 30px;
}

.registarDonativo .novaPessoa input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.registarDonativo .novaPessoa a {
  border: 1px solid #a1a0a0;
  height: 20px;
}
.registarDonativo .nome {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 2;
}

.registarDonativo .nome input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarDonativo .email {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 2;
}

.registarDonativo .email input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarDonativo .telefone {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 3;
}

.registarDonativo .telefone input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarDonativo .genero {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 3;
}

.registarDonativo .idade {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 4;
}

.registarDonativo .idade input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarDonativo .zona {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 4;
}

.registarDonativo .zona input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarDonativo .nif {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 5;
}

.registarDonativo .nif input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.registarDonativo .comoConheceu {
  /* width: 50%; */
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 5;
  display: flex;
  flex-direction: column;
  margin-right: 115px;
}

.registarDonativo .comoConheceu select {
  height: 35px;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
}

.registarDonativo .comoConheceu .local {
  width: 100%;
  /* margin-left: 9px; */
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.registarDonativo .comoConheceu .local input {
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  width: 115%;
  height: 35px;
}

.registarDonativo .quantidade {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 6;
}
.registarDonativo .quantidade input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarDonativo .data {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 6;
}
.registarDonativo .data input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarDonativo .morada {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 7;
}
.registarDonativo .morada textarea {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 70px;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

.registarDonativo .codPostal {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 7;
}
.registarDonativo .codPostal input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.registarDonativo .observacoes {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 8;
}
.registarDonativo .observacoes textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #a1a0a0;
  resize: none;
  border-radius: 4px;
}

.registarDonativo .registar {
  margin-right: 115px;
  margin-bottom: 30px;
  grid-column-start: 2;
  grid-row-start: 9;
  align-self: end;
  justify-self: end;
}

.registarDonativo .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.registarDonativo .registar button:hover {
  background-color: #fab995;
}

.comoConheceuInputs{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: 10px;
}
</style>