
<template>
  <div>
    <div class="login mt-3">
      <template v-if="!this.changePassBool">
        <div id="fazerLogin" class="w3-panel shadow-lg p-3 mb-5 bg-body rounded">
          <h1>Login</h1>
          <div id="fields" class="campos">
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Introduza o seu email:</label>
                <input type="email" class="form-control" name="username" v-model="input.username" placeholder="Email">
              </div>
              <div class="form-group">
                  <label for="exampleInputPassword1">Introduza a sua Password:</label>
                  <input type="password" class="form-control" name="password" v-model="input.password" placeholder="Password">
              </div>           
                <div class="buttons-login"> 
                  <button type="submit" class="btn btn-secondary" @click.prevent="doLogin()" :disabled="submiting">Login</button>
                  <div class="loader" v-if="submiting">
                    <div class="spinner">
                      <div class="double-bounce1"></div>
                      <div class="double-bounce2"></div>
                    </div> 
                    <label>{{alertString}}</label>
                  </div>
                  <a @click.prevent="show()">Esqueci-me da password</a>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template v-else>
        <form id="mudarPass" class="w3-panel">
           <div id="fields" class="camposMudarPass">
              <h1>Recuperar Password</h1>
              <div class="form-group">
                  <label class="mb-2 mt-2">Introduza o seu email:</label>
                  <input type="email" class="form-control" v-model="input.username" placeholder="Email">
              </div>
              <div class="buttons-recuperarpass">
                  <button type="submit" class="btn btn-secondary" @click.prevent="alterPass()" :disabled="submiting">Enviar</button>
                  <button type="button" class="btn btn-secondary" @click.prevent="show()" :disabled="submiting">Cancelar</button>
              </div>
            </div>
        </form>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "LoginScreen",
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      changePassBool: false,
      alertString: "A efetuar login ...",
      submiting: false
    };
  },
  mounted() {
    if(sessionStorage.getItem("token"))
    {
      this.submiting = true;
      this.alertString = "A validar a sessão, aguarde ...";
      this.$axios.get("validateToken", { headers: { "Authorization": "Bearer " + sessionStorage.getItem("token") } })
        .then(response => {
          this.submiting = false;
          if (response.status === 401)
          {
            this.$emit("auth", false);
          
          }
          else if (response.status === 201) 
          {
            this.$axios.defaults.headers.common.Authorization = "Bearer " + sessionStorage.getItem("token");
            this.$emit("auth", true);
          }
        })         
        .catch(() =>{
            this.submiting = false;
            this.$emit("auth", false);
        });
    }
  },
  activated() {
    this.input.username = "";
    this.input.password = "";
  },
  methods: {
     alterPass: function() {
      this.submiting = true;
      this.$axios.post("requestPasswordReset", {
          username: this.input.username.replace(/[""'"]/g, "")},
        )
        .then(response => {
          if(response.status === 201)
          {
            this.changePassBool = false;
            this.input.username = "";
            this.$toasted.show("Caso o seu email se econtre registado, receberá um email com instruções");
          }
          else
          {
            this.$toasted.error("Ocorreu um erro ao processar o seu pedido");
          }
          this.submiting = false;
        })
         .catch(() =>{
            this.$toasted.error("Ocorreu um erro ao processar o seu pedido");
            this.submiting = false;
        });
    },
    show() {
      this.input.username = "";
      this.changePassBool = !this.changePassBool;
    },
    doLogin: function() {

      this.alertString = "A efetuar login ...";
      this.submiting = true;

      let data = {
        username: this.input.username.replace(/["""]/g, ""),
        password: this.input.password.replace(/[""'"]/g, "")
      };

      this.$axios.post("login", data)
        .then(response => {
          this.submiting = false;
          if(response.status == 200)
          {
            this.$axios.defaults.headers.common.Authorization = "Bearer " + response.data.token;
            sessionStorage.setItem("token", response.data.token);
            this.$emit("auth", true);
          }
          else
          {
            this.$toasted.error("Email ou Password incorrectos");
          }
        })
        .catch(() => {
          this.submiting = false;
          this.$toasted.error("Email ou Password incorrectos");
        });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.btn.btn-secondary:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.spinner {
  width: 20px;
  height: 20px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#fazerLogin {
  width: 30%;
  padding: 10px;
  box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%) !important;
  border-radius: 7px !important;
}

.form-control:focus {
    border-color: #ed0677;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(237 6 119 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(237 6 119 / 60%);
}

#mudarPass {
  width: 30%;
  padding: 10px;
  box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%) !important;
  border-radius: 7px !important;
}

.login {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

#fields {
  padding: 3px;
}

.login .campos input {
  margin-bottom: 15px;
  width: 100%;
}

.login .campos button {
  cursor: pointer;
}

.buttons-login {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.buttons-recuperarpass{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

button.btn.btn-secondary {
    width: 40%;
}



.login .campos a {
  cursor: pointer;
  display: inline-block;
  color: #0645ad;
  text-decoration: underline;
}

@media (max-width: 700px) {
  #fazerLogin {
    width: 65%;
  }
  #mudarPass {
    width: 65%;
  }
}
</style>