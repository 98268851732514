<template>
  <div>
    <div class="apoio">
      <div id="fields" class="fields">
        <template v-if="!isNovoStaff">
          <div class="staff">
            <label>Staff:<i class="obrigatorio">*</i></label>
            <br>
            <vue-single-select
                    v-model="input.inputStaff"
                    :options="allStaff"
                    :placeholder="disabledStaff ? 'A carregar dados...' : 'Escolher'"
                    option-label="nome"
                    :disabled="disabledStaff"
            ></vue-single-select>
          </div>
        </template>
        <template v-else>
          <div class="staff">
            <label>Novo Membro do Staff:<i class="obrigatorio">*</i></label>
            <vue-single-select
                    v-model="input.inputStaff"
                    :options="allPessoasNotStaff"
                    :placeholder="disabledNovoStaff ? 'A carregar dados...' : 'Escolher'"
                    option-label="nome"
                    :disabled="disabledNovoStaff"
            ></vue-single-select>
          </div>
          
        </template>
        <div class="botaoNovoStaff">
            <button type="button" class="btn btn-secondary staff" v-on:click="toggleNovoStaff()"><i class="bi bi-person-plus-fill"></i> {{buttonTextStaff}}</button>
        </div>

        <div class="evento">
          <label>Evento:<!-- Data: {{this.dataEvento = null ? "" : this.dataEvento }}--><i class="obrigatorio">*</i></label>
          <br>
          <vue-single-select
                    v-model="input.inputEventos"
                    :options="allEvents"
                    :placeholder="disabledEventos ? 'A carregar dados...' : 'Escolher'"
                    option-label="nome"
                    :disabled="disabledEventos"
          ></vue-single-select>
        </div>
        <div class="dataInicio">
          <label>Data Início:<i class="obrigatorio">*</i></label>
          <br>
          <input
            type="datetime-local"
            v-model="input.dataInicio"
            name="dataInicio"
            placeholder="Data Início"
          >
        </div>

        <div class="dataFim">
          <label>Data Fim:<i class="obrigatorio">*</i></label>
          <br>
          <input
            type="datetime-local"
            v-model="input.dataFim"
            name="dataFim"
            placeholder="Data Fim"
            :max="maxDate"
          >
        </div>

        <div class="descricao">
          <label>Descrição:</label>
          <br>
          <textarea type="text" v-model="input.descricao" name="descricao"></textarea>
        </div>

        <div class="pontos">
          <label>Pontos:<i class="obrigatorio">*</i></label>
          <br>
          <input type="number" v-model="input.pontos" name="pontos" min="0" max>
        </div>
        <div class="registar">
          <button class="registar" type="submit" v-on:click.prevent="submit()" :disabled="submiting">
            <i class="bi bi-file-earmark-check-fill"></i> Registar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { bus } from "../../main.js";

export default {
  activated() {
    this.$axios.get("getAllStaffIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allStaff = response.data;
          this.disabledStaff = false;
        }
      })
      .catch(error => {
        if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
    this.$axios.get("getAllEventosIdNome")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allEvents = response.data;
          this.disabledEventos = false;
        }
      })
      .catch(error => {
       if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
      this.$axios.get("getAllPessoasNaoStaff")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allPessoasNotStaff = response.data;
          this.disabledNovoStaff = false;
        }
      })
      .catch(error => {
        if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
      let MyDate = new Date();
      MyDate.setDate(MyDate.getDate())
      this.maxDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                      ('0' + MyDate.getDate()).slice(-2) + 'T00:00:00';  
  },
  deactivated() {
    this.clearfields();
  },
  data() {
    return {
      input: {
        inputStaff: "",
        inputEventos: "",
        descricao: "",
        dataInicio: "",
        dataFim: "",
        pontos: ""
      },
      allStaff: [],
      allEvents: [],
      allPessoasNotStaff: [],
      isNovoStaff: false,
      buttonTextStaff:  "Promover",
      submiting: false,
      disabledStaff: true,
      disabledEventos: true,
      disabledNovoStaff: true,
      maxDate: ""
    };
  },
  methods: {
    toggleNovoStaff: function(){
        this.isNovoStaff = !this.isNovoStaff
        this.input.inputStaff = "";
      if(this.isNovoStaff){
        this.buttonTextStaff = "Cancelar"
      }else{
        this.buttonTextStaff = "Promover"
      }
    },
    clearfields: function() {
      this.input.inputStaff = "";
      this.input.inputEventos = "";
      this.input.descricao = "";
      this.input.dataInicio = "";
      this.input.dataFim = "";
      this.input.pontos = "";
      this.isNovoStaff = false;
    },
    submit: function() {
     if(this.input.inputStaff === "" || this.input.inputStaff == null || this.input.inputEventos === "" || this.input.inputEventos == null ||
     this.input.dataInicio === "" || this.input.dataInicio == null || this.input.dataFim === "" || this.input.dataFim == null
      || this.input.pontos === "" || this.input.pontos == null) {
        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente");
        return;
      }

      if(this.input.pontos <= 0){
        this.$toasted.error("Os pontos não podem ser um valor menor ou igual a 0");
        return;
      }

      if(new Date(this.input.dataInicio).getTime() > new Date(this.input.dataFim).getTime()){
        this.$toasted.error("A data de início do apoio não pode ser posterior à data de fim");
        return;
      }

      this.submiting = true;
      if(this.isNovoStaff)
      {
        //Promover o staff
        this.$axios.post("promoverStaff/"+this.input.inputStaff.id)
        .then(response => {
          if(response.status == 401){
            bus.$emit("logout");

          }
          else if(response.status == 200){
            this.$toasted.success("Staff promovido com sucesso");
            this.registarApoio();
          }
          else if (response.status == 400) {
            this.$toasted.error("Erro ao promover o staff");
          }
          this.submiting = false;
        })
        .catch(error => {
          if(error.response.status === 401){
            bus.$emit("logout");
          }
          this.submiting = false;
        });
        //Chamar registar Apoio
      }
      else
      {
        this.registarApoio();
      }
    },
    registarApoio()
    {
      this.$axios.post("postApoio",
          {
            idStaff: this.isNovoStaff ?  this.input.inputStaff.id : this.input.inputStaff.id,
            idEvento: this.input.inputEventos.id,
            descricao: this.input.descricao,
            dataInicio: this.input.dataInicio.replace("T", " "),
            dataFim: this.input.dataFim.replace("T", " "),
            pontos: this.input.pontos
          },
        )
        .then(response => {
          if(response.status == 401)
          {
            bus.$emit("logout");
          }
          else if (response.status === 201) {
            this.$toasted.success("Apoio inserido com sucesso");
            this.clearfields();
            bus.$emit('refreshStaff');
          }
          this.submiting = false;
        })
        .catch((errors) => {
          if (errors.response.status === 400) {
            this.$toasted.error("Campos incorrectos ou incompletos");
          }
          if (errors.response.status === 401) {
            bus.$emit("logout");
          }
          this.submiting = false;
        });
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

button.btn.btn-secondary.staff
{
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.apoio {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.apoio .fields {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  background-color: oldlace;
  width: 100%;
}

.apoio .fields .obrigatorio{
  color: red;
}

.apoio .staff {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 10px;
  margin-left: 115px;
}

.apoio .botaoNovoStaff {
  margin-right: 115px;
  grid-row-start: 1;
  grid-column-start: 2;
  justify-self: end;
  margin-top: 30px;
}

.apoio .botaoNovoStaff input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.apoio .botaoNovoStaff a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.apoio .novoStaff {
  grid-column-start: 1;
  grid-row-start: 2;
  margin-left: 115px;
}
.apoio .botaoNovaPessoa {
  margin-right: 115px;
  grid-row-start: 2;
  grid-column-start: 2;
  justify-self: end;
  margin-top: 30px;
}

.apoio .botaoNovaPessoa input {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.apoio .botaoNovaPessoa a {
  border: 1px solid #a1a0a0;
  height: 20px;
}

.apoio .nome {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 3;
}

.apoio .nome input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.apoio .email {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 3;
}

.apoio .email input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.apoio .telefone {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 4;
}

.apoio .telefone input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.apoio .genero {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 4;
}

.apoio .idade {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 5;
}

.apoio .idade input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.apoio .zona {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 5;
}

.apoio .zona input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.apoio .nif {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 6;
}

.apoio .nif input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}

.apoio .comoConheceu {
  /*margin-right: 115px;*/
  grid-column-start: 2;
  grid-row-start: 6;
  width: 50%;
  display: -webkit-box;
  height: 35px;
}

.apoio .comoConheceu select {
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 30px;
}

.apoio .comoConheceu .atravesQuem {
  margin-top: 11px;
  width: 113%;
  margin-left: 9px;
}

.apoio .comoConheceu .local {
  margin-top: 16px;
  width: 100%;
  margin-left: 9px;
}

.apoio .comoConheceu .local input{
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  width: 113%;
  height: 35px;
}

.apoio .evento {
  grid-column-start: 1;
  grid-row-start: 7;
  margin-left: 115px;
}

.apoio .dataInicio {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 7;
}
.apoio .dataInicio input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}
.apoio .dataFim {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 8;
}
.apoio .dataFim input {
  width: 100%;
  height: 35px;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  border-radius: 4px;
}
.apoio .descricao {
  margin-left: 115px;
  grid-column-start: 1;
  grid-row-start: 9;
}
.apoio .descricao textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #a1a0a0;
  resize: none;
  border-radius: 4px;
}
.apoio .pontos {
  margin-right: 115px;
  grid-column-start: 2;
  grid-row-start: 8;
}
.apoio .pontos input {
  width: 100%;
  border: 1px solid #a1a0a0;
  padding-left: 3px;
  height: 35px;
  border-radius: 4px;
}

.apoio .registar {
  margin-right: 115px;
  margin-bottom: 15px;
  grid-column-start: 2;
  grid-row-start: 10;
  align-self: end;
  justify-self: end;
}

.apoio .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  margin-right: 15px;
}
.apoio .registar button:hover {
  background-color: #fab995;
}

.apoio .registar button:disabled {
  background-color: #e99d72;
  cursor: not-allowed;
}
</style>