<template>
<div>
  <br>
      <div class="voltarEvento">
            <button v-on:click="switchComponent('FormInscricoesEvento')">
                <i class="bi bi-reply-fill"></i>Voltar
            </button>
            </div>
    <div>
        <div class="registarPagamentoInscricao">
            <div id="fields" class="fields">
               
                <h2 class="text-center">Registar Pagamento de Inscrição </h2>
                <div class="participante">
                    <label>Participante:</label>
                    <input disabled type="text" v-model="dadosPessoa.nome">
                </div>
                <div class="valor">
                    <label>Valor (€):</label>
                    <input type="number"  step="0.01" v-model.number="input.valor">
               </div>
                <div class="recibo">
                  <label>Nº Recibo:</label>
                  <input type="number" v-model.number="input.recibo">
               </div>
               <div class="registar">
                <button class="registar" type="submit" v-on:click.prevent="submit()" :disabled="submiting">
                  <i class="bi bi-file-earmark-check-fill"></i> Guardar
                </button>
                <button v-if="this.dadosPessoa.valor != -1 && this.dadosPessoa.recibo != -1" class="registar" type="submit" v-on:click.prevent="apagarPagamento()" :disabled="submiting">
                  <i class="bi bi-trash-fill"></i> Apagar
                </button>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  props:{
      dadosPessoa: {
        type: Object,
        required: true
      }
  },
    data() {
    return {
        input: {
            valor : null,
            recibo: null,
        },
        submiting: false,
        
    };
  },
  activated() {
    this.input.valor = this.dadosPessoa.valor == -1 ? null : this.dadosPessoa.valor;
    this.input.recibo = this.dadosPessoa.recibo == -1 ? null : this.dadosPessoa.recibo;
  },

  methods: {
    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    apagarPagamento()
    {
      this.submiting = true;

      this.$axios.delete(`deletePagamentoEvento/${this.dadosPessoa.idEvento}/${this.dadosPessoa.idParticipante}`)
        .then(response => {
          if(response.status == 401)
          {
            bus.$emit("logout");
          }
          else
          {
            this.$toasted.show("Pagamento removido com sucesso");
            this.switchComponent('FormInscricoesEvento');
          }
          this.submiting = false;
        })
        .catch((errors) => {

            if (errors.response.status === 400) {
                this.$toasted.error('Erro ao remover pagamento');
            }
            if (errors.response.status === 401) {
                bus.$emit("logout");
            }

        });
    },
    submit: function() {
     
      if(this.input.valor == "" || this.input.recibo == "" || this.input.valor == null || this.input.recibo == null)
      {
        this.$toasted.error('Por favor preencha todos os campos obrigatórios corretamente corretamente');
        return;
      }
      
      if(this.dadosPessoa.valor != -1 || this.dadosPessoa.recibo != -1){
        /*NO CASO DE UPDATE, VERIFICA SE OS VALORES FORAM ALTERADOS*/
        if(this.dadosPessoa.valor == this.input.valor && this.dadosPessoa.recibo == this.input.recibo){
          this.$toasted.error("Os valores não foram alterados");
          return;
        }else{
          this.submiting = true;

          this.$axios.put(`editPagamentoEvento/${this.dadosPessoa.idEvento}/${this.dadosPessoa.idParticipante}`,
                  {
                    valor: this.input.valor,
                    recibo: this.input.recibo
                  })
                .then(response => {
                  if(response.status == 401)
                  {
                    bus.$emit("logout");
                  }
                  else
                  {
                    this.$toasted.show("Pagamento atualizado com sucesso");
                    this.switchComponent('FormInscricoesEvento');
                  }
                  this.submiting = false;
                })
                .catch((errors) => {

                  if (errors.response.status === 400) {
                  this.$toasted.error('Erro ao atualizar pagamento');
                  }
                  if (errors.response.status === 401) {
                      bus.$emit("logout");
                  }
              
                });

        }
      }else{
        
        this.submiting = true;

        this.$axios.post("postPagamentoEvento",
                  {
                    idParticipante: this.dadosPessoa.idParticipante,
                    idEvento:  this.dadosPessoa.idEvento,
                    valor: this.input.valor,
                    recibo: this.input.recibo
                  })
                .then(response => {
                  if(response.status == 401)
                  {
                    bus.$emit("logout");
                  }
                  else
                  {
                    this.$toasted.show("Pagamento registado com sucesso");
                    this.switchComponent('FormInscricoesEvento');
                  }
                  this.submiting = false;
                })
                .catch((errors) => {

                  if (errors.response.status === 400) {
                  this.$toasted.error('Erro ao registar pagamento');
                  }
                  if (errors.response.status === 401) {
                      bus.$emit("logout");
                  }
              
                });
      }
        this.input.valor = null;
        this.input.recibo = null;

      }
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.registarPagamentoInscricao .registar button:hover:disabled{
  background-color: #e99d72;
  cursor: not-allowed;
}

.participante input {
  width: 50%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.participante{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.participante label{
  width: 100px;
}

.valor input{
  width: 50%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.valor label{
  width: 100px;
}

.valor{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.recibo input{
  width: 50%;
  border: 1px solid #a1a0a0;
  border-radius: 4px;
  height: 35px;
}

.recibo label{
  width: 100px;
}

.recibo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}


.registarPagamentoInscricao {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.registarPagamentoInscricao .fields .obrigatorio{
  color: red;
}

.registarPagamentoInscricao .fields {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  background-color: oldlace;
  width: 100%;
  height: 80%;
  margin-bottom: 20px;
  margin-top: 30px;
}

.registarPagamentoInscricao .registar {
  grid-column-start: 1;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.registarPagamentoInscricao .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 10px;
}
.registarPagamentoInscricao .registar button:hover {
  background-color: #fab995;
}

</style>