<template>
    <div class="tabelaPessoas">
        <v-confirm
        v-model="dialog"
        :title="title"
        :message="message"
        :btns="btns"
        />
        <div class="loader" v-if="isLoading && !registarPessoa">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div> 
            <h3>A carregar pessoas ...</h3>
        </div>
        <div class="dados" v-else>
            <div id="fields" :class="!registarPessoa ? 'tabela' : 'registar'">
                <div class="filtro-group" v-if="!registarPessoa">
                    <form class="form-filtro">
                        <input type="text" class="form-control" placeholder="Filtrar por nome, email, telefone ou nif" v-model="filtro">
                        <button type="submit" class="btn btn-secondary" @click.prevent="getPessoas(null)"><i class="bi bi-search"></i> Filtrar</button>
                    </form>
                    <div class="tipo-pessoa">
                        <label class="tipo-pessoa-label">Tipo de Pessoa: </label>
                        <select class="form-select" v-model="filtroTipo" @change="getPessoas(null)">
                            <option selected value="todos">Todos</option>
                            <option value="staff">Staffs</option>
                            <option value="amigo">Amigos Sócios</option>
                            <option value="empresario">Empresários</option>
                        </select>
                    </div>
                </div>
                <div class="buttonAdicionarPessoa">
                    <button v-on:click="toggleRegistarPessoa()">
                        <template v-if="registarPessoa">
                            <i class="bi bi-chevron-left"></i> Cancelar
                        </template>
                        <template v-else>
                            <i class="bi bi-person-plus-fill"></i> Registar Pessoa
                        </template>
                    </button>
                </div>
                <div class="novaPessoa">
                    <template v-if="registarPessoa">
                        <keep-alive>
                            <RegistarPessoa/>
                        </keep-alive>
                    </template>
                </div>
                <div class="tabela-paginate" v-show="!registarPessoa">
                    <table class="w3-table-all" id="tabela">
                        <tr>
                            <th class="id"><div class="ordenar-id" :title="ordenacaoid == 'ASC' ? 'Ordenar Descendentemente' : 'Ordenar Ascendentemente'" @click="ordenarId">ID <i :class="ordenacaoid == 'ASC' ? 'bi bi-caret-down' : 'bi bi-caret-up' "></i></div></th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Telefone</th>
                            <th>Nif</th>
                            <th class="receberEmail">Receber Emails</th>
                            <!--<th>Dados</th>-->
                        </tr>
                        <tr class="conteudo" v-for="(users, key) in allPersons" :key="key">
                            <td class="idPessoa">{{ users.idPessoa }}</td>
                            <td class="verButton" v-on:click="ver(users.idPessoa)" title="Ver Ficha de Pessoa">
                                {{ users.nome }}
                            </td>
                            <td>{{ users.email}}</td>
                            <td>{{ users.telefone}}</td>
                            <td>{{ users.nif ? users.nif : "Sem Nif"}}</td>
                            <td><span :class="users.receberEmail ? 'badge text-bg-success' : 'badge text-bg-danger'">{{ users.receberEmail ? 'Sim' : 'Não'}}</span></td>

                            <td class="buttons">
                                <!--<div class="verButton">
                                    <a v-on:click="ver(users.idPessoa)"> Ver </a>
                                </div>-->
                                <div class="editarButton">
                                    <a class="bi bi-pencil-square" v-on:click="editar(users)" title="Editar"></a>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <Pagination :page=page :total=total @pagination="getPessoas"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "../../../main.js";

export default {
    activated() {
        this.getPessoas();
    },
    created() {
        bus.$on('PessoaRegisto', () =>{
            this.getPessoas();
        })
    },
    name: "LoginScreen",
    data() {
        return {
            allPersons: null,
            registarPessoa: false,
            page: 1,
            total: 0,
            filtro: "",
            filtroTipo: "todos",
            dialog: false,
            title: "",
            message: "",
            btns: [],
            isLoading: true,
            ordenacaoid: 'ASC',
        };
    },
    watch: {
        filtro(valor) {
           if(valor === ""){
               this.getPessoas();
           }
        },
    },
    methods: {
        ordenarId() {
            this.ordenacaoid = this.ordenacaoid == 'ASC' ? 'DESC' : 'ASC';
            this.getPessoas();
        },
        getPessoas(page) {
            this.isLoading = true;
            this.page = page || 1;
            this.$axios.get("getAllPessoas?pageURL="+(page ? page : 1)+"&filtroURL="+this.filtro+"&filtroTipoURL="+this.filtroTipo+"&ordenacaoidURL="+this.ordenacaoid)
                .then(response => {
                    if (response.status === 401) {
                        bus.$emit("logout");
                    }
                    else
                    {
                        this.allPersons = response.data.pessoas;
                        this.total = response.data.total;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        bus.$emit("logout");
                    }
                });
        },
        toggleRegistarPessoa() {
            this.registarPessoa = !this.registarPessoa;
            this.$emit("registar", this.registarPessoa);
        },
        ver(idPessoa) {
            bus.$emit("viewPessoa", idPessoa);
        },
        editar(data) {
            bus.$emit("editarPessoa", data);
        },
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");


.ordenar-id {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
}

.ordenar-id:hover{
  text-decoration: underline;
}

.id {

  cursor: pointer;
}

.bi.bi-caret-down:before, .bi.bi-caret-up:before {
  font-size: 19px !important;
  margin-top: 2px;
  cursor: pointer;
}

.receberEmail{
    min-width: 150px;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.form-filtro {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    padding-top: 5px;
}

.tipo-pessoa {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

select.form-select {
    width: 185px !important;
}

label.tipo-pessoa-label {
    min-width: 120px;
}

.filtro-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-control {
    width: 25% !important;
}

.form-control:focus, .form-select:focus {
    border-color: #8ca714cf;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(140 167 20 / 64%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(140 167 20 / 64%);
}

.bi.bi-chevron-left::before{
    font-weight: 600 !important;
}

.tabelaPessoas h1 {
    text-align: center;
}

.tabelaPessoas {
    font-family: "Open Sans";
}

.tabelaPessoas .tabela .conteudo:hover {
    font-weight: bold;
    background-color: #e2e2e2

}

.tabelaPessoas .tabela, .registar {
    width: 100%;
    margin-bottom: 20px;
}

.tabelaPessoas #tabela {
    width: 80%;
    margin: auto;
}

.tabelaPessoas .dados .idPessoa {
    width: 3%;
}

.tabelaPessoas .tabela table td {
    border: 1px solid #ccc;
    text-align: center;
    vertical-align: middle;
}

.tabelaPessoas .dados table td {
    width: 25%;
}

.tabelaPessoas .tabela table th {
    border: 1px solid #ccc;
    text-align: center;
    vertical-align: middle;
}

.tabelaPessoas .dados {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabelaPessoas .verButton {
    cursor: pointer;
}

.tabelaPessoas .verButton a:hover {
    font-weight: bold;
}

.tabelaPessoas .editarButton {
    cursor: pointer;
}

.tabelaPessoas .editarButton a:hover {
    font-weight: bold;
    font-size: 13px;
}

.tabelaPessoas .dados .buttons {
    border-color: white;
    background-color: white;
    border-top: none;
    width: 1%;
}

.tabelaPessoas .buttonAdicionarPessoa {
    margin-bottom: 5px;
    margin-left: 10%;
}

.tabelaPessoas .buttonAdicionarPessoa button {
    text-align: center;
    cursor: pointer;
    background-color: #a7ec82;
    border: none;
    border-radius: 4px;
}

.tabelaPessoas .buttonAdicionarPessoa button:hover {
    background-color: #72d33d;
}
</style>