<template>
    <div>
        <div class="registarCriacaoinscricaoEvento">
            <div id="fields" class="fields">
              <template  v-if="!semEventos">
                <div class="evento">
                    <label>Evento: <i class="obrigatorio">*</i></label>
                    <div class="autocomplete">          
                        <vue-single-select 
                            v-model="input.evento"
                            :options="allEventos"
                            :placeholder="disabledEvento ? 'A carregar dados...' : 'Procurar por nome, data ou local'"
                            option-label="nome"
                            :disabled="disabledEvento"
                        ></vue-single-select>  
                    </div>
                </div>
                <div class="linkEvento">
                   <label>Link para Inscrição: <i class="obrigatorio">*</i></label>
                   <a v-bind:href="linkInscricao" target="_blank">{{linkInscricao}}</a>
                </div>
                <div class="registar">
                  <button type="submit" v-on:click.prevent="submit()" :disabled="submiting"><i class="bi bi-file-earmark-check-fill"></i> Criar Link</button>
                </div>
              </template>
              <template  v-else>
                <h2 class="text-center mt-5 mb-5">Todos os eventos existentes já possuem link de inscrição</h2>
              </template>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
    data() {
    return {
        input: {
            evento : null
        },
        allEventos : [],
        linkInscricao :"",
        semEventos : false,
        disabledEvento : true,
        submiting : false,
    };
  },
  computed: {
     link(){
      return window.location.href;
    }
  },
  activated() {
    this.$axios.get("getAllEventosSemLink")
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.allEventos = response.data
          this.semEventos = response.data.length == 0 ? true : false;
          this.disabledEvento = false;
        }
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
  
  },

  methods: {
    clearFields: function() {
      this.input.evento = null;
    },
    removerEvento()
    {
      let idEvento = this.allEventos.findIndex(evento => evento.id === this.input.evento.id);
      this.allEventos.splice(idEvento, 1);
    },
    submit: function() {
      if(this.input.evento == null){
         this.$toasted.error('É necessário selecionar um evento');
         return;
      }

      if(this.input.evento.tokenRegisto != ""){
        this.$toasted.error('O evento já possui link para inscrição');
        return;
      }

      this.submiting = true;
      
      this.$axios.get(`getLinkRegistoEvento/${this.input.evento.id}`)
      .then(response => {
        
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.linkInscricao = this.link +  "inscricaoEvento/" + response.data;
          this.input.evento.tokenRegisto = response.data;

          this.$toasted.success("Link criado com sucesso");
          this.removerEvento();
          this.clearFields();
          bus.$emit('refreshEventosLink');
        }
        this.submiting = false;
      })
      .catch(error => {
         if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
    }
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.registarCriacaoinscricaoEvento .registar button:hover:disabled{
  background-color: #e99d72;
  cursor: not-allowed;
}

.registarCriacaoinscricaoEvento {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.registarCriacaoinscricaoEvento .fields .obrigatorio{
  color: red;
}

.registarCriacaoinscricaoEvento .fields {
  display: inline-grid;
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  background-color: oldlace;
  width: 100%;
  height: 80%;
  margin-bottom: 20px;
}
.registarCriacaoinscricaoEvento .evento {
  grid-column-start: 1;
  margin-top: 10px;
  margin-left: 15%;
}


.registarCriacaoinscricaoEvento .evento .autocomplete{
   display:inline-block;
   width: 30%;
   margin-left:7%;
}

.registarCriacaoinscricaoEvento .linkEvento {
  grid-column-start: 1;
  margin-top: 10px;
  margin-left: 15%;
}


.registarCriacaoinscricaoEvento .linkEvento a{
   display:inline-block;
   width: 30%;
   margin-left:1%;
}

.registarCriacaoinscricaoEvento .registar {
  margin-top: 30px;
  margin-left: 30%;
  margin-bottom: 15px;
}

.registarCriacaoinscricaoEvento .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.registarCriacaoinscricaoEvento .registar button:hover {
  background-color: #fab995;
}

</style>