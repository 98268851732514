<template>
    <div class="conteudo">
        <div class="conteudo-group">
            <label class="nota-mensagem"><label class="nota-mensagem-nota">Nota: </label>{{tipoMensagem == 'A' || tipoMensagem == 'AS' ? ' Caso queira adicionar o nome da pessoa à mensagem coloque na mesma ' : ' Caso queira adicionar o nome do evento à mensagem, o link de inscrição ou a data do mesmo coloque na mesma ' }}<label class="nota-mensagem-variavel">{{tipoMensagem == 'A' || tipoMensagem == 'AS' ? ' {nomePessoa}' : ' {nomeEvento} {linkEvento} {dataEvento}'}}</label></label>
            <div class="form-group-titulo">
                <label>Título da Mensagem:<i class="obrigatorio">*</i></label>
                <input type="text" class="form-control" name="titulo" v-model="mensagem.titulo" placeholder="Título">
            </div>
            <div class="form-group-mensagem">
                <label>Mensagem:<i class="obrigatorio">*</i></label>
                <textarea class="form-control" v-model="mensagem.mensagem" id="mensagem" rows="10" :placeholder="placeholderMensagem"></textarea>
            </div>
            <template v-if="tipoMensagem=='E'">
                <div class="form-group-evento">
                    <label for="mensagem">Evento:<i class="obrigatorio">*</i></label>
                    <vue-single-select
                        v-model="mensagem.evento"
                        :options="allEvents"
                        :placeholder="disabledEvento ? 'A carregar dados...' : 'Escolher Evento'"
                        option-label="nome"
                        :disabled="disabledEvento"
                        :classes="{
                            pointer: -1,
                            wrapper: 'single-select-wrapper',
                            input: 'search-input-mensagem',
                            icons: 'icons',
                            required: 'required',
                            activeClass: 'active',
                            dropdown: 'dropdown'
                        }"
                        @input="onChangeEvento"
                    ></vue-single-select>
                </div>
                <div class="form-group-data">
                    <label>Data de Envio:<i class="obrigatorio">*</i></label>
                    <input class="form-control" v-model="mensagem.dataEnvio" :min="minDate" :max="maxDate" type="date" name="data" :disabled="!mensagem.evento">
                </div>
            </template>
            <div class="botoes">
                <button type="button" class="btn btn-secondary mensagem" @click="registarEditar" :disabled="submiting"><i class="bi bi-device-ssd"></i> {{acao == 'E' ? 'Editar Mensagem' : 'Criar Mensagem'}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
    props: {
        mensagemEdit: {
            type: Object,
            default: null,
            required: true,
        },
        tipoMensagem: {
            type: String,
            default: null,
            required: true
        },
        acao:{
            type: String,
            default: 'C',
            required: true
        }
    }, 
    data() {
        return {
            allEvents: [],
            mensagem: {
                titulo: "",
                mensagem: "",
                evento: null,
                dataEnvio: null
            },
            minDate: null,
            maxDate: null,
            disabledEvento: true,
            submiting: false,
        };
    },
    computed: {
        placeholderMensagem() {
            if(this.tipoMensagem == 'A')
            {
                return 'Caro/a {nomePessoa}\n\n A ATEMPHAR deseja-lhe muitas felicidades. \n\nAtenciosamente, \nATEMPHAR';
            }
            else if(this.tipoMensagem == 'AS')
            {
                return 'Caro/a {nomePessoa}\n\n A ATEMPHAR recorda-lhe que o seu status de Amigo Sócio expira dentro de 1 mês. \n\nAtenciosamente, \nATEMPHAR';
            }
            else if(this.tipoMensagem == 'E')
            {
                return 'A ATEMPHAR recorda-lhe que o evento {nomeEvento} se realizará no dia {dataEvento}, para participar ou inscrever-se carregue no link abaixo! \n\nLink: {linkEvento} \n\nAtenciosamente, \nATEMPHAR';
            }
            return '';
        }
    },
    activated() {
        if(this.acao == 'E'){
           this.mensagem = {...this.mensagemEdit};
        }
        if(this.tipoMensagem == 'E') {
            this.firstOpen = true;
            this.$axios.get("getAllEventosIdNome")
            .then(response => {
                if (response.status === 401) {
                bus.$emit("logout");
                }
                else
                {
                    this.allEvents = response.data;
                    if(this.acao == 'E')
                    {
                        this.mensagem.evento = this.allEvents.find(evento => evento.id == this.mensagem.idEvento);
                    }
                    this.disabledEvento = false;
                }
            })
            .catch(error => {
            if(error.response.status === 401){
                    bus.$emit("logout");
                    }
            });
        }

        let MyDate = new Date();
        if(MyDate.getHours() >= 9)
        {
            MyDate.setDate(MyDate.getDate() + 1)
        }
        this.minDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                        ('0' + MyDate.getDate()).slice(-2);
        

    },
    methods: {
        onChangeEvento()
        {
            if(this.mensagem.evento != null)
            {
                this.$axios.get("getDadosEvento/" + this.mensagem.evento.id)
                .then(response => {
                    if (response.status === 401) {
                        bus.$emit("logout");
                    }
                    else
                    {
                        let dataEvento = response.data.dataEvento.split(" ")[0];
                        
                        if(this.firstOpen && this.acao == 'E')
                        {
                            this.mensagem.dataEnvio = this.mensagemEdit.dataEnvio;
                            this.firstOpen = false;
                        }
                        else if(new Date(dataEvento).getTime() < new Date().getTime())
                        {
                            this.$toasted.error("O evento escolhido, já foi realizado");
                            this.mensagem.evento = null;
                            return;
                        }
                        else
                        {
                            let MyDate = new Date();
                            if(MyDate.getHours() >= 9)
                            {
                                MyDate.setDate(MyDate.getDate() + 1)
                            }
                            this.mensagem.dataEnvio = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                                            ('0' + MyDate.getDate()).slice(-2);
                        }

                        this.maxDate = dataEvento;
                    }
                })
            }
            else
            {
                this.mensagem.dataEnvio = null;
                this.maxDate = null;
            }
        },
        registarEditar() {
            if(this.validarCampos())
            {
                if(this.tipoMensagem == "E"){  
                    if(this.mensagem.dataEnvio == null || this.mensagem.evento == null)
                    {
                        this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
                        return;
                    }
                    else if(new Date().getHours() >= 9 && new Date(this.mensagem.dataEnvio).getTime() <= new Date().getTime())
                    {
                        this.$toasted.error("A data de envio deve ser superior à data de hoje");
                        return;
                    }
                    else if(new Date(this.mensagem.dataEnvio).setHours(0,0,0,0) < new Date().setHours(0,0,0,0))
                    {
                        this.$toasted.error("A data de envio deve ser superior ou igual à data de hoje");
                        return;
                    }
                }
                this.submiting = true;
                if(this.acao == 'E')
                {
                    this.$axios.put("putMensagem/" + this.mensagem.id, {
                        tipo: this.tipoMensagem,
                        titulo: this.mensagem.titulo,
                        estado: this.tipoMensagem != 'E' ? this.mensagem.estado : 0,
                        mensagem: this.mensagem.mensagem,
                        dataEnvio: this.tipoMensagem == 'E' ? this.mensagem.dataEnvio : null,
                        idEvento: this.tipoMensagem == 'E' ? this.mensagem.evento.id : null
                    })
                    .then(response => {
                        if (response.status === 401) {
                            bus.$emit("logout");
                        }
                        else if(response.status == 200)
                        {
                            this.$toasted.success("Mensagem editada com sucesso");
                            if(this.tipoMensagem == 'E')
                            {
                                bus.$emit("registoEdicaoEvento");  
                            }
                            else if(this.tipoMensagem == 'A')
                            {
                                bus.$emit("registoEdicaoAniversario");  
                            }
                            else if(this.tipoMensagem == 'AS')
                            {
                                bus.$emit("registoEdicaoAmigoSocio");  
                            }
                        }
                        else
                        {
                            this.$toasted.error("Erro ao editar mensagem");
                        }
                        this.submiting = false;
                    })
                    .catch(error => {
                        this.$toasted.error("Erro ao editar mensagem");
                        if(error.response.status === 401){
                            bus.$emit("logout");
                        }
                    });
                }
                else
                {
                    this.$axios.post("postMensagem", {
                        tipo: this.tipoMensagem,
                        titulo: this.mensagem.titulo,
                        mensagem: this.mensagem.mensagem,
                        dataEnvio: this.tipoMensagem == 'E' ? this.mensagem.dataEnvio : null,
                        idEvento: this.tipoMensagem == 'E' ? this.mensagem.evento.id : null
                    })
                    .then(response => {
                        if (response.status === 401) {
                        bus.$emit("logout");
                        }
                        else if(response.status == 201)
                        {
                            this.$toasted.success("Mensagem criada com sucesso");
                            if(this.tipoMensagem == 'E')
                            {
                                bus.$emit("registoEdicaoEvento");  
                            }
                            else if(this.tipoMensagem == 'A')
                            {
                                bus.$emit("registoEdicaoAniversario");  
                            }
                            else if(this.tipoMensagem == 'AS')
                            {
                                bus.$emit("registoEdicaoAmigoSocio");  
                            }
                        }
                        else
                        {
                            this.$toasted.error("Erro ao criar mensagem");
                        }
                        this.submiting = false;
                    })
                    .catch(error => {
                        this.$toasted.error("Erro ao criar mensagem");
                        if(error.response.status === 401){
                            bus.$emit("logout");
                        }
                    });
                }
            }
        },
        validarCampos()
        {
            if(this.mensagem.titulo == null || this.mensagem.mensagem == null || this.mensagem.titulo.trim().length == 0 || this.mensagem.mensagem.trim().length == 0)
            {
                this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
                return false;
            }
            return true;
        }
    }
};
</script>

<style scoped>

.btn.btn-secondary.mensagem:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.btn.btn-secondary.mensagem
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.nota-mensagem-nota, .nota-mensagem-variavel {
    color: #ff1515;
}

.nota-mensagem{
    margin-bottom: 30px;
    margin-top: 20px;
}

.form-control:focus {
    border-color: #5bb1b3;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(17 144 147);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(141 207 208);
}

.conteudo{
    background-color: oldlace;
    width: 100%;
    display: flex;
    justify-content: center;
}

.conteudo-group{
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    width: 80%;
}

.obrigatorio{
    color: red;
}

.form-group-mensagem, .form-group-titulo, .form-group-data, .form-group-evento{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 30px;
}

.form-group-evento{
    width: 50%;
}

.form-group-data input{
    width: 30%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
    margin-bottom: 10px;
}

.botoes{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
