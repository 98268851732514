
<template>
  <div class="tabelaEmpresarios">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <div class="loader" v-if="isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar empresários ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="tabela-paginate">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Empresa</th>
              <!--<th>Dados</th>-->
            </tr>
            <tr class="conteudo" v-for="(users, key) in allEmpresario" :key="key">
              <td class="idPessoa">{{ users.idPessoa }}</td>
              <td class="verButton" v-on:click="ver(users.idPessoa)" title="Ver Ficha de Pessoa">{{ users.nome }}</td>
              <td>{{users.email}}</td>
              <td>{{users.telefone}}</td>
              <td v-on:click="verEmpresa(users.idEmpresa)" role="button" title="Ver Ficha da Empresa">{{ users.nomeEmpresa }}</td>
              <td class="buttons">
                <!--<div class="verButton">
                    <a v-on:click="ver(users.idPessoa)"> Ver </a>
                </div>-->
                <div class="editarButton">
                  <a class="bi bi-pencil-square" v-on:click="editar(users)" title="Editar"></a>
                </div>
                <div class="apagarButton">
                  <a class="bi bi-trash-fill" v-on:click="apagar(users)" title="Remover empresário"></a>
                </div>
              </td>
            </tr>
          </table>
            <Pagination :page=page :total=total @pagination="getEmpresarios"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
  activated() {
    this.getEmpresarios();
  },
  data() {
    return {
      allEmpresario: null,
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      isLoading: true,
    };
  },
  computed: {},
  methods: {
    getEmpresarios(page)
    {
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllEmpresarios?pageURL="+(page ? page : 1))
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else
          {
            this.allEmpresario = response.data.empresarios;
            this.total = response.data.total;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
              }
        });
    },
    ver(idPessoa) {
      bus.$emit("viewPessoa", idPessoa);
    },
    verEmpresa(idEmpresa) {
      bus.$emit("viewEmpresa", idEmpresa);
    },
    editar(data) {
      bus.$emit("editarPessoa", data);
    },
    apagarEmpresario(empresario)
    {
      this.$axios.delete("deleteEmpresario/" + empresario.idPessoa)
        .then(response => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status == 200)
          {
            this.$toasted.success("Empresário removido com sucesso")
            this.getEmpresarios();
          }
          else
          {
            this.$toasted.error("Erro ao remover empresário")
          }
        })
        .catch(error => {
          if(error.response.status === 401){
              bus.$emit("logout");
          }
        });
    },
    apagar(empresario)
    {
      this.title = "Remover Empresário";
      this.message = "Tem a certeza que pretende remover o empresário - " + empresario.nome +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.apagarEmpresario(empresario);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    }
  }
};


</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

#tabela{
  width: 70%;
  margin: auto;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.tabelaEmpresarios h1 {
  text-align: center;
}

.tabelaEmpresarios {
  font-family: "Open Sans";
}

.tabelaEmpresarios .tabela .conteudo:hover{
  font-weight: bold;
  background-color: #e2e2e2
  
}
.tabelaEmpresarios .tabela {
  width: 100%;
  margin-bottom: 20px;
}
.tabelaEmpresarios .dados .idPessoa {
  width: 3%;
}
.tabelaEmpresarios .tabela table td {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaEmpresarios .dados table td {
  width: 25%;
}

.tabelaEmpresarios .tabela table th {
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.tabelaEmpresarios .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabelaEmpresarios .verButton {
  cursor: pointer;
}
.tabelaEmpresarios .verButton a:hover {
  font-weight: bold;
}

.tabelaEmpresarios .editarButton, .tabelaEmpresarios .apagarButton {
  cursor: pointer;
}

.tabelaEmpresarios .editarButton a:hover, .tabelaEmpresarios .apagarButton a:hover {
  font-weight: bold;
  font-size: 14px;
}

.tabelaEmpresarios .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}
</style>