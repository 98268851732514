
<template>
  <div class="empresas">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <br>
    <h1>Empresas</h1>
    <br>
    <div class="loader" v-if="isLoading">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar empresas ...</h3>
    </div>
    <div class="dados" v-else>
      <div id="fields" class="tabela">
        <div class="buttonsRegisto">
        <div class="buttonAdicionarEmpresa">
          <button v-on:click="toggleRegistarEmpresa()">
            <i :class="registarEmpresa ? 'bi bi-chevron-left' : 'bi bi-plus-circle'"></i> {{this.registarEmpresaText}}
          </button>
          </div>
        <div class="buttonAdicionarDonativo">
          <button v-on:click="toggleRegistarDonativo()">
            <i :class="showFormDonativo ? 'bi bi-chevron-left' : 'bi bi-plus-circle'"></i> {{this.registarDonativoText}}
          </button>
        </div>
        </div>

        <template v-if="registarEmpresa">
          <keep-alive>
          <RegistarEmpresa/>
          </keep-alive>
        </template>

        <template v-if="showFormDonativo">
            <keep-alive>
              <RegistarDonativo :tipoDonativo="'e'"/>
            </keep-alive>
          </template>

        <div class="tabela-paginate" v-show="!registarEmpresa && !showFormDonativo">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <!--<th>Dados </th>-->
            </tr>
            <!--ID, nome, tipo, data, local -->
            <tr class="conteudo" v-for="(empresa, tableKey) in allEmpresas" :key="tableKey">
              <td class="verButton" v-on:click="ver(empresa.idEmpresa)" title="Ver Ficha de Empresa"> {{ empresa.nomeEmpresa }}</td>
              <td>{{ empresa.emailEmpresa }}</td>
              <td>{{ empresa.telefoneEmpresa }}</td>
              <td class = "buttons">
                <div class="editarButton">
                    <a class="bi bi-pencil-square" v-on:click="editarEmpresa(empresa)" title="Editar Empresa"> </a>
                </div>
                <div class="eliminarButton">
                    <a class="bi bi-trash-fill" v-on:click="eliminarEmpresa(empresa)" title="Eliminar Empresa"> </a>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=page :total=total @pagination="getAllEmpresas"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  mounted()
  {
    bus.$on('EmpresaRegisto', () =>{
      this.toggleRegistarEmpresa();
      this.getAllEmpresas();
    })
    bus.$on('donativoRegisto', () =>{
      this.toggleRegistarDonativo();
    })
  },
  activated(){
    this.getAllEmpresas();
  },
  data() {
    return {
      allEmpresas: [],
      tableKey: 0,
      registarEmpresa: false,
      registarEmpresaText: "Registar Empresa",
      showFormDonativo: false,
      registarDonativoText: "Registar Donativo",
      page: 1,
      total: 0,
      dialog: false,
      title: "",
      message: "",
      btns: [],
      isLoading: true,
    }
  },
  methods: {
    getAllEmpresas(page)
    {
      this.isLoading = true;
      this.page = page || 1;
      this.$axios.get("getAllEmpresas?pageURL="+(page ? page : 1))
        .then(response => {
          if(response.status === 401){
            bus.$emit("logout");
          }
          else{
            this.allEmpresas = response.data.empresas;
            this.total = response.data.total;
          }
          this.isLoading = false;
        }).catch(error => {
          this.error = error;
        });
    },
    toggleRegistarDonativo(){
      if(this.registarEmpresa)
      {
        this.toggleRegistarEmpresa();
      }
      this.showFormDonativo = !this.showFormDonativo;
      if(this.showFormDonativo){
        this.registarDonativoText = "Cancelar";
      }else{
        this.registarDonativoText = "Registar Donativo";
      }
    },
    toggleRegistarEmpresa(){
      if(this.showFormDonativo)
      {
        this.toggleRegistarDonativo();
      }
      this.registarEmpresa = !this.registarEmpresa;
      if(this.registarEmpresa){
        this.registarEmpresaText = "Cancelar";
      }else{
        this.registarEmpresaText = "Registar Empresa";
      }
    },
    ver(idEmpresa){
        bus.$emit('viewEmpresa', idEmpresa);
    },
    editarEmpresa(data){
        bus.$emit("editarEmpresa", data);
    },
    eliminarEmpresa(empresa)
    {
      this.title = "Eliminar Empresa";
      this.message = "Tem a certeza que pretende eliminar a empresa " + empresa.nomeEmpresa +" ?";
      this.btns = [
        {
          text: "Eliminar",
          function: () => 
          {
            this.eliminarEmpresaConfirm(empresa);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    eliminarEmpresaConfirm(empresa)
    {
      this.$axios.delete("deleteEmpresa/" + empresa.idEmpresa)
        .then(response => {
          if(response.status === 401){
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Empresa " + empresa.nomeEmpresa + " eliminada com sucesso");
            this.getAllEmpresas();
          }
          else if(response.status === 400 && response.data == "reservaservico")
          {
            this.$toasted.error("Erro ao eliminar empresa, pois existem reservas associadas aos serviços da mesma")
          }
          else
          {
            this.$toasted.error("Erro ao eliminar empresa")
          }
        }).catch(error => {
          this.error = error;
        });
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

a.bi.bi-trash-fill {
    font-size: 16px;
}

.empresas h1 {
  text-align: center;
}

.empresas{
	font-family: "Open Sans";
}

.empresas .menuEventos{
  text-align: center;
  display: flex;
}

.empresas .tabela{
	width: 100%;
  margin-bottom: 20px;
}

.empresas #tabela{
  width: 80%;
  margin: auto;
}

.empresas .tabela table td{
  border: 1px solid #ccc;
}

.empresas .dados table td{
  width: 25%;
  text-align: center;
  vertical-align: middle;
}

.empresas .tabela table th{
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}
.empresas .tabela .conteudo:hover{
  font-weight: bold;
  background-color: #e2e2e2
  
}

.empresas .dados{
	display: flex;
  align-items: center;
  justify-content: center;
}

.empresas .verButton{
  cursor: pointer;
  
}
.empresas .verButton a:hover{
  font-weight: bold;
}

.empresas .editarButton{
  cursor: pointer;
  
}

.empresas .eliminarButton{
  cursor: pointer;
  
}

.empresas .editarButton a:hover{
  font-weight: bold;
  font-size: 14px;

}

.empresas .eliminarButton a:hover{
  font-weight: bold;
  font-size: 15px;
}

.empresas .dados .buttons{
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}

.empresas .buttonsRegisto{
  margin-bottom: 5px;
  margin-left: 10%;
  display: -webkit-box;
}

.empresas .buttonsRegisto button {
  text-align: center;
  cursor: pointer;
  background-color: #ffe885;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
}

.empresas .buttonsRegisto button:hover {
  background-color: #ffdd47;
}

</style>