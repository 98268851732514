<template>
  <div>
    <br />
    <div class="voltarInqueritos">
      <button v-on:click="switchComponent('InqueritosTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <div class="respostas">
      <div class="selectQuestao">
        <i class="fa fa-angle-down" /><label for="questao"
          >Selecione a questão:
        </label>
        <select
          @change="onChangeQuestao()"
          id="questao"
          v-model="input.questao"
        >
          <option selected value="todos">Completo</option>
          <option
            v-for="(questao, tableKey) in allQuestoes"
            :key="tableKey"
            :value="questao"
          >
            {{ "ID: " + questao.idQuestao + " Questão: " + questao.questao }}
          </option>
        </select>
      </div>
      <div class="loader" v-if="isLoading">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div> 
        <h3>A carregar respostas do inquérito ...</h3>
      </div>
      <div v-if="!isLoading && allRespostas.length > 0">
        <div class="dados">
          <div class="tabela-paginate">
            <table class="w3-table-all" id="tabela">
              <tr>
                <th>ID</th>
                <th>ID Questão</th>
                <th>Questão</th>
                <th>Resposta</th>
              </tr>
              <tr
                class="conteudo"
                v-for="(resposta, tableKey) in allRespostas"
                :key="tableKey"
              >
                <td>{{ tableKey + 1 }}</td>
                <td>{{ resposta.idQuestao }}</td>
                <td>{{ resposta.questao }}</td>
                <td>{{ resposta.resposta }}</td>
              </tr>
            </table>
            <Pagination
              :page="page"
              :total="total"
              @pagination="onChangeQuestao"
            ></Pagination>
          </div>
        </div>
      </div>
      <h1 class="sem-resposta" v-else-if="!isLoading">Sem Respostas!</h1>
    </div>
  </div>
</template>
<script>
import { bus } from "../../../main.js";

export default {
  data() {
    return {
      input: {
        questao: null,
      },
      allRespostas: [],
      allQuestoes: [],
      page: 1,
      total: 0,
      isLoading: true,
    };
  },
  props: {
    dadosInquerito: {
      type: Object,
      required: true,
    },
  },
  activated() {
    this.getInquerito();
  },
  deactivated() {
    this.allRespostas = [];
    this.allQuestoes = [];
  },
  methods: {
    getInquerito() {
      this.$axios.get(`getInquerito/${this.dadosInquerito.id}`)
        .then((response) => {
          if(response.status == 401)
          {
            bus.$emit("logout");
          }
          else
          {
            this.allQuestoes = response.data.questoes;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
        });
      this.input.questao = "todos";
      this.onChangeQuestao();
    },

    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    onChangeQuestao(page) {
      this.isLoading = true;
      if (this.input.questao == "todos") {
        this.$axios.get(`getRespostasInquerito/${this.dadosInquerito.id}/${this.dadosInquerito.idEvento}?pageURL=` + (page ? page : 1))
          .then((response) => {
            if(response.status == 401)
            {
              bus.$emit("logout");
            }
            else
            {
              this.page = page || 1;
              this.allRespostas = response.data.respostas;
              this.total = response.data.total;
              this.isLoading = false;
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              bus.$emit("logout");
            }
          });
      } else {
        this.$axios.get(`getRespostasInqueritoQuestao/${this.dadosInquerito.id}/${this.dadosInquerito.idEvento}/${this.input.questao.idQuestao}?pageURL=` +
              (page ? page : 1)
          )
          .then((response) => {
            if(response.status == 401)
            {
              bus.$emit("logout");
            }
            else
            {
              this.page = page || 1;
              this.allRespostas = response.data.respostas;
              this.total = response.data.total;
              this.isLoading = false;
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              bus.$emit("logout");
            }
          });
      }
    },
  },
};
</script>

<style scoped>

.loader, .sem-resposta{
  margin-top: 110px;
}

.tabela-paginate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.voltarInqueritos button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  align-items: center;
}
.voltarInqueritos i {
  font-size: 17px;
  margin-right: 3px;
}
.voltarInqueritos button:hover {
  background-color: #fdf5e6;
}

.respostas h1 {
  text-align: center;
}

.respostas {
  font-family: "Open Sans";
}
.respostas .tabela {
  width: 100%;
  margin-bottom: 20px;
}

.respostas #tabela {
  width: 80%;
  margin-top: 10px;
}
.respostas .tabela table td {
  border: 1px solid #ccc;
}

.respostas .dados table td {
  width: 25%;
}

.respostas .tabela table th {
  border: 1px solid #ccc;
}

.respostas .selectQuestao {
  margin-left: 10%;
  background: rgba(246, 136, 72, 0.91);
  width: 45%;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.respostas .selectQuestao label {
  width: 25%;
  height: 100%;
}
.respostas .selectQuestao select {
  width: 75%;
  height: 100%;
}
</style>