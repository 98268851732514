<template>
    <nav aria-label="Page navigation" style="overflow-x:auto;">
        <ul class="pagination">
            <li :class="page == 1 ? 'page-item disabled' : 'page-item'"><a class="page-link" role="button" @click.prevent="changePage(page != 1 ? page - 1 : 1)">Página Anterior</a></li>
            <li class="page-item" v-show="pages[0] != 1"><a class="page-link" role="button" @click.prevent="changePage(1)">1</a></li>
            <li class="page-item disabled" v-show="pages[0] != 1"><a class="page-link reticencias" >...</a></li>
            <li :class="page == pageAux ? 'page-item  active' : 'page-item' " v-for="(pageAux, value) in pages" :key="value">
                <a class="page-link" role="button" @click.prevent="changePage(pageAux)">{{pageAux}}</a>
            </li>
            <li class="page-item disabled" v-show="pages[pages.length - 1] != lastPage"><a class="page-link reticencias">...</a></li>
            <li class="page-item" v-show="pages[pages.length - 1] != lastPage"><a class="page-link" role="button" @click.prevent="changePage(lastPage)">{{lastPage}}</a></li>
            <li :class=" page == lastPage ? 'page-item disabled' : 'page-item'"><a class="page-link" role="button" @click.prevent="changePage(page <= lastPage ? page + 1 : lastPage)">Página Seguinte</a></li>
        </ul>
    </nav>
</template>

<script>
/* eslint-disable */

export default {
    data(){
        return {
            numShown: 15,
            lastPage: 1,
        }
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        total:
        {
            type: Number,
            default: 0
        }
    },
    watch: {
        total(newPage){  
            this.lastPage = Math.ceil(newPage / 20) == 0 ? 1 : Math.ceil(newPage / 20);
        }
    },
    computed: {
        pages() {
            const numShown = Math.min(this.numShown, this.lastPage);
            let first = this.page - Math.floor(numShown / 2);
            first = Math.max(first, 1);
            first = Math.min(first, this.lastPage - numShown + 1);
            return [...Array(numShown)].map((k,i) => i + first);   
        }
    },
    methods: {
        changePage(page)
        {
            this.$emit('pagination', page)
        }
    },
    mounted() {
        this.lastPage = Math.ceil(this.total / 20) == 0 ? 1 : Math.ceil(this.total / 20);
    }
}
</script>

<style scoped>

.pagination{
    flex-wrap: wrap;
}

nav{
    padding: 10px;
    user-select: none;
}

.page-item.active .page-link {
    background-color: #838383 !important;
    border-color: #838383 !important;
}

.page-link {
    color: #2b2c2e !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
}

.page-link:focus {
    box-shadow: 0 0 0 0.25rem rgb(43 43 43 / 25%);
}

.page-link:hover {
    color: #000000 !important;
}

.page-item.disabled .page-link {
    color: #6c757d !important;
}

a.page-link.reticencias{
    background-color: #e7e7e7;
}
</style>