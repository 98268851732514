<template>
  <div class="formNovoEmpresario">
    <br>
    <br>
    <div class="form">
      <template>
        <!-- já frequentou, user existente -->
        <div class="novoEmpresario">
          <label>Pessoa:<i class="obrigatorio">*</i></label>
          <br>
          <vue-single-select
            v-model="input.inputPessoa"
            :options="allPessoaNotInEmpresa"
            :placeholder="disabledPessoa ? 'A carregar dados...' : 'Procurar por nome, email, telemóvel ou nif'"
            option-label="nome"
            :disabled="disabledPessoa"
          ></vue-single-select>
        </div>
        </template>
      <div class="registar">
        <button type="submit" v-on:click.prevent="submit()" :disabled="submiting"><i class="bi bi-file-earmark-check-fill"></i> Registar</button>
      </div>
      
      </div>
      
    </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  props: {
    idEmpresa: {
        type: Number,
        required: true
    }
  },
  mounted(){
    this.$axios.get("getPessoasNaoEmEmpresa/" + this.idEmpresa)
        .then(response =>{ 
          if(response.status === 401){
            bus.$emit("logout");
          }
          else
          {
            this.allPessoaNotInEmpresa = response.data
            this.disabledPessoa = false;
          }
        }).catch(error => {
        if(error.response.status === 401){
            bus.$emit("logout");
            }
      });
  },
  data() {
    return {
      input: {
        nome: "",
        inputPessoa: "",
      },
      allPessoaNotInEmpresa: [],
      disabledPessoa: true,
      submiting: false,
    };
  },
  methods: {
    submit: function (){
      this.submiting = true;
      
      this.$axios.post("postEmpresario",
      {
        idPessoa: this.input.inputPessoa.id,
        idEmpresa: this.idEmpresa
        
      }).then((response)=>{
        if(response.status === 401){
          bus.$emit("logout");
        }
        else if(response.status === 200)
        {
          this.$toasted.success("Novo Empresário registado com sucesso");
          bus.$emit("EmpresarioRegisto")
        }
        this.submiting = false;

      }).catch((error) =>{
        if(error.error.response.status === 401){
        bus.$emit("logout");
        }
        this.$toasted.error("Erro a inserir novo Empresário")

      })
        
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.formNovoEmpresario .registar button:hover:disabled
{
  background-color: #e99d72;
  cursor: not-allowed;
}

.formNovoEmpresario{
  font-family: "Open Sans";
	display: flex;
  align-items: center;
  justify-content: center;
}

.formNovoEmpresario .form{
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  /* grid-column-gap: 15px; */
  background-color: oldlace;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.formNovoEmpresario .form .obrigatorio{
  color: red;
}
.formNovoEmpresario .novoEmpresario{
    grid-column-start: 1;
    grid-row-start: 1;
    margin-top: 10px;
    /* margin-left: 192px; */
    padding: 10px 30px 10px 30px;
    width: 50%;
}

.formNovoEmpresario .registar{
  margin-bottom: 15px;
}

.formNovoEmpresario .registar button {
  height: 50px;
  font-size: medium;
  cursor: pointer;
  border: none;
  background-color: #e99d72;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.formNovoEmpresario .registar button:hover {
  background-color: #fab995;
}

</style>