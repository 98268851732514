
<template>
  <div class="fichaPessoa">
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <br>
    <div class="voltar">
      <button v-on:click="switchComponent('PersonsTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <h1>Ficha Individual de {{this.pessoaData.nome}}</h1>
    <br>
    <div class="dadosPessoa">
      <div class="dadosGerais">
        <div class="nome">
          <label>Nome:</label>
          <br>
          {{this.pessoaData.nome}}
        </div>
        <div class="genero">
          <label>Género:</label>
          <br>
          {{this.pessoaData.genero == 'M' ? 'Masculino' : this.pessoaData.genero == 'F' ? 'Feminino' : 'Outro'}}
        </div>
        <div class="idade">
          <label>Data Nasc.:</label>
          <br>
          {{this.pessoaData.dataNasc}}
        </div>
        <div class="email">
          <label>Email:</label>
          <br>
          {{this.pessoaData.email}}
        </div>
        <div class="telefone">
          <label>Telefone:</label>
          <br>
          {{this.pessoaData.telefone}}
        </div>
        <div class="zona">
          <label>Zona:</label>
          <br>
          {{this.pessoaData.zona}}
        </div>
        <div class="nif">
          <label>NIF:</label>
          <br>
          {{this.pessoaData.nif ? this.pessoaData.nif : 'Sem Nif'}}
        </div>

        <div class="comoConheceu">
          <label>Como conheceu a associação:</label>
          <br>
          <!-- código para formatar e apresentar dados -->
          <template
            v-if="this.pessoaData.comoConheceu === 'Redes Sociais' || this.pessoaData.comoConheceu === 'E-Mail'"
          >{{this.pessoaData.comoConheceu}}</template>
          <template
            v-else-if="this.pessoaData.comoConheceu === 'Pessoa'"
          >{{this.pessoaData.atravesQuemNome ? this.pessoaData.atravesQuemNome : this.pessoaData.comoConheceu}}</template>
          <template
            v-else-if="this.pessoaData.comoConheceu == null || this.pessoaData.comoConheceu === ''"
          >N/A</template>
          <template v-else>{{this.pessoaData.comoConheceu}}</template>
          <br>
        </div>
        <div class="numPessoasTrouxe">Trouxe {{this.numPessoasTrouxe}} pessoas à organização</div>
        <div
          class="primeiroEventoData"
        ><label>Primeiro evento foi no dia:</label><br>{{this.primeiroEventoData ? this.primeiroEventoData.dataEvento : 'N/A'}}</div>
        <template v-if="numApoio > 0">
          <div class="numPontosStaff">
          <label>Pontuação Staff:</label>
          <br>
           {{this.pontosStaff}}</div>
        </template>

      <template v-if="this.numDonativos > 0">
      <!--<label> Dados de Amigo Sócio: </label>-->
      <div class="morada">
        <label><a :class="editarMorada ? 'bi bi-x-square' : 'bi bi-pencil-square morada'" role="button" v-on:click="toggleEditarMorada" :title="editarMorada ? 'Cancelar Edição' : 'Editar Morada'"></a> Morada: </label>
        <br>
        {{this.amigoSocioData[0].morada}}
        <div class="nova-morada" v-if="editarMorada">
          <div class="morada-group">
            <input type="text" class="form-control" placeholder="Nova morada" v-model="novaMorada">
            <input type="text" class="form-control cod-postal" placeholder="Cod-Postal" v-model="novoCodPostal">
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" @click="editarMoradaFunc" :disabled="submiting">Guardar</button>
          </div>
        </div>
      </div>
        <div class="codP">
          <label>Código Postal: </label>
          <br>
          {{this.amigoSocioData[0].codPostal}}
        </div>
        <div class="estatuto">
          <label>Estatuto de Amigo Sócio:</label> Expira dia {{this.amigoSocioData[0].dataExpiracao}}
        </div>
        
      </template>
      </div>
      
    </div>

    <template v-if="this.numEventos > 0">
      <div class="eventosParticipou">
        <h2>Eventos em que participou: {{this.numEventos}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th>Nome</th>
                  <th>Tipo</th>
                  <!--<th> Ações </th>-->
                </tr>
                <tr
                  class="conteudo"
                  v-for="(parts, componentKey) in this.participacaoData"
                  :key="componentKey"
                >
                  <td class="verButton" v-on:click="verEvento(parts.idEvento)" title="Ver Ficha de Evento" >{{ parts.nomeEvento }}</td>
                  <td>{{ parts.tipoEvento }}</td>
                  <td class="buttons">
                    <div class="apagarButton">
                      <a
                        class="bi bi-trash-fill"
                        v-on:click="apagar(parts)"
                        title="Remover participação"
                      ></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateEventos.page :total=paginateEventos.total @pagination="getEventosParticipou"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="this.numOrador > 0">
      <div class="eventosApresentou">
        <h2>Eventos que apresentou: {{this.numOrador}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th>Nome</th>
                  <th>Tipo</th>
                  <!--<th>Ações</th>-->
                </tr>
                <tr
                  class="conteudo"
                  v-for="(parts, componentKey) in this.oradorData"
                  :key="componentKey"
                >
                  <td class="verButton" v-on:click="verEvento(parts.idEvento)" title="Ver Ficha de Evento">{{ parts.nomeEvento }}</td>
                  <td>{{ parts.tipoEvento }}</td>

                  <td class="buttons">
                    <div class="editarButton">
                      <a class="bi bi-pencil-square" v-on:click="editarEvento(parts)" title="Editar"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateOrador.page :total=paginateOrador.total @pagination="getEventosOrador"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="this.numApoio > 0">
      <div class="apoiosRealizou">
        <h2>Apoios que realizou: {{this.numApoio}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th>Evento:</th>
                  <th>Tipo:</th>
                  <th>Pontos:</th>
                  <th>Obs.:</th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(parts, componentKey) in this.apoioData"
                  :key="componentKey"
                >
                  <td class="verButton" v-on:click="verEvento(parts.idEvento)" title="Ver Ficha de Evento">{{ parts.nomeEvento }}</td>
                  <td>{{ parts.tipoEvento}}</td>
                  <td>{{ parts.pontos }}</td>
                  <td class="observacoes">{{ parts.descricao}}</td>
                  <td class="buttons">
                    <div class="editarButton">
                      <a class="bi bi-pencil-square" v-on:click="editarApoio(parts)" title="Editar Apoio"></a>
                    </div>
                  </td>
                </tr>
              </table>
              <Pagination :page=paginateApoios.page :total=paginateApoios.total @pagination="getApoiosStaff"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="this.numDonativos > 0">
      <div class="apoiosRealizou">
        <h2>Donativos que realizou: {{this.numDonativos}}</h2>
        <div class="dados">
          <div id="fields" class="tabela">
            <div class="tabela-paginate">
              <table class="w3-table-all">
                <tr>
                  <th>Quantidade:</th>
                  <th>Data:</th>
                  <th>Obs.:</th>
                </tr>
                <tr
                  class="conteudo"
                  v-for="(parts, componentKey) in this.amigoSocioData"
                  :key="componentKey"
                >
                  <td>€{{ parts.quantidadeDonativo}}</td>
                  <td>{{ parts.dataDonativo }}</td>
                  <td class="observacoes">{{ parts.observacoes}}</td>
                </tr>
              </table>
              <Pagination :page=paginateDonativos.page :total=paginateDonativos.total @pagination="getDonativosPessoa"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  props: {
    idPessoa: {
      required: true
  }
  },
  created() {},
  activated() {
    this.$axios.get("getDadosPessoa/" + this.idPessoa)
      .then(response => {

        this.pessoaData = response.data;
        this.$axios.get("getDadosPrimeiroEventoPessoa/" + this.idPessoa)
          .then(response => {
            if (response.status === 401) {
              bus.$emit("logout");
            }
            else
            {
              this.primeiroEventoData = response.data;
            } 
          })
          .catch(() => {

          });

        if (response.status === 401) {
          bus.$emit("logout");
        }
      })
      .catch(() => {

      });

    this.$axios.get("getPessoasTrouxe/" + this.idPessoa)
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.numPessoasTrouxe = response.data;
        }
      })
      .catch(() => {

      });

      this.getApoiosStaff();
      this.getDonativosPessoa();
      this.getEventosParticipou();
      this.getEventosOrador();
  },
  deactivated() {
    this.pessoaData = [];
    this.primeiroEventoData = [];
    this.participacaoData = [];
    this.oradorData = [];
    this.amigoSocioData = [];
    this.apoioData = [];
    this.numEventos = 0;
    this.numOrador = 0;
    this.numApoio = 0;
    this.numDonativos = 0;
    this.numPessoasTrouxe = 0;
    this.pontosStaff = 0;
  },
  data() {
    return {
      pessoaData: [],
      oradorData: [],
      apoioData: [],
      primeiroEventoData: [],
      participacaoData: [],
      amigoSocioData: [],
      numEventos: 0,
      numOrador: 0,
      numApoio: 0,
      numDonativos: 0,
      numPessoasTrouxe: 0,
      pontosStaff: 0,
      paginateApoios: {
        page: 1,
        total: 0
      },
      paginateDonativos: {
        page: 1,
        total: 0
      },
      paginateEventos: {
        page: 1,
        total: 0
      },
      paginateOrador: {
        page: 1,
        total: 0
      },
      novaMorada: "",
      novoCodPostal: "",
      editarMorada: false,
      dialog: false,
      title: "",
      message: "",
      btns: [] ,
      submiting: false,
    };
  },
  methods: {
    getApoiosStaff(page){
      this.paginateApoios.page = page || 1;
      this.$axios.get("getApoiosStaff/" + this.idPessoa + "?pageURL="+(page ? page : 1))
      .then(response => {
        if( response.status == 401)
        {
          bus.$emit("logout");
        }
        else if (response.status === 200) {
          this.apoioData = response.data.apoios;
          this.paginateApoios.total = response.data.total;
          this.numApoio = response.data.total;
          this.calcularPontos();
        }
      })
      .catch(error => {
        this.error = error;
      });
    },
    toggleEditarMorada()
    {
      this.editarMorada = !this.editarMorada;
      this.novaMorada = "";
      this.novoCodPostal = "";
    },
    editarMoradaFunc()
    {
      if(this.novaMorada == null || this.novaMorada.trim().length == 0 || this.novoCodPostal == null || this.novoCodPostal.trim().length == 0)
      {
        this.$toasted.error("A morada ou Código Postal não podem estar vazios");
        return;
      }
      else if (!/^\d{4}(-\d{3})?$/.test(this.novoCodPostal)) {
        this.$toasted.error("Formato de código postal inválido");
        return;
      }

      this.submiting = true;

      this.$axios.put("editarMoradaAmigoSocio/" + this.idPessoa, {
        novaMorada: this.novaMorada,
        novoCodPostal: this.novoCodPostal
      }).then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else if (response.status === 200) {
          this.$toasted.success("Morada e Código Postal alterada com sucesso");
          this.amigoSocioData[0].morada = this.novaMorada;
          this.amigoSocioData[0].codPostal = this.novoCodPostal;
          this.toggleEditarMorada();
        }
        else
        {
          this.$toasted.error("Erro ao alterar morada e Código Postal");
        }
        this.submiting = false;
      }).catch(() => {
        this.$toasted.error("Erro ao alterar a morada");
      });
    },
    getDonativosPessoa(page){
      this.paginateDonativos.page = page || 1;
      this.$axios.get("getDonativosPessoa/" + this.idPessoa + "?pageURL="+(page ? page : 1))
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.amigoSocioData = response.data.donativos;
          this.paginateDonativos.total = response.data.total;
          this.numDonativos = response.data.total;
        }
      })
      .catch(() => {

      });
    },
    getEventosParticipou(page)
    {
      this.paginateEventos.page = page || 1;
      this.$axios.get("getEventosParticipante/" + this.idPessoa + "?pageURL="+(page ? page : 1))
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.participacaoData = response.data.eventos;
          this.paginateEventos.total = response.data.total;
          this.numEventos = response.data.total;
        }
      })
      .catch(() => {

      });
    },
    getEventosOrador(page)
    {
      this.paginateOrador.page = page || 1;
      this.$axios.get("getEventosOrador/" + this.idPessoa + "?pageURL="+(page ? page : 1))
      .then(response => {
        if (response.status === 401) {
          bus.$emit("logout");
        }
        else
        {
          this.oradorData = response.data.eventos;
          this.paginateOrador.total = response.data.total;
          this.numOrador = response.data.total;
        }
      })
      .catch(() => {

      });
    },
    editarApoio(apoio){
      bus.$emit("editarApoio", apoio);
    },
    calcularPontos() {
      this.apoioData.forEach(el => {
        this.pontosStaff += parseInt(el.pontos, 10);
      });
    },
    verEvento(idEvento) {
      bus.$emit("viewEvento", idEvento);
    },
    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    editarEvento(data) {
      bus.$emit("editarEvento", data);
    },
    apagarParticipante(participante){
      this.$axios.delete("deleteParticipacao/" + participante.idEvento + "/" + participante.idPessoa)
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Participação de " + participante.nome + " removida com sucesso");
            this.getEventosParticipou();
          }
          else
          {
            this.$toasted.error("Erro ao remover participação");
          }
        })
        .catch(() => {
          this.$toasted.error("Erro a remover participação")
        });
    },
    apagar(participante)
    {
      this.title = "Remover Participação";
      this.message = "Tem a certeza que pretende remover o participante " + participante.nome +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.apagarParticipante(participante);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.observacoes{
  max-width: 180px;
}

.btn.btn-secondary:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.form-control:focus {
  border-color: #e088b3;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(237 6 119 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(234 116 174 / 60%);
}

input.form-control.cod-postal {
    width: 45%;
}

.morada-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.bi-x-square::before {
  font-weight: 1000 !important;
}

.nova-morada {
  max-width: 85%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.tabela-paginate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.bi.bi-pencil-square::before {
  font-weight: 600 !important;
}

.fichaPessoa h1 {
  text-align: center;
}
.fichaPessoa h2 {
  text-align: center;
  background-color: linen;
}

.fichaPessoa {
  font-family: "Open Sans";
}

.fichaPessoa .voltar button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fichaPessoa .voltar i {
  font-size: 17px;
  margin-right: 3px;
}
.fichaPessoa .voltar button:hover {
  background-color: #fdf5e6
}
.fichaPessoa .dadosPessoa {
  background-color: rgb(238, 222, 192);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fichaPessoa .dadosGerais {
  display: inline-grid;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: oldlace;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 65px;
  padding-right: 65px;
  grid-row-gap: 20px;
}

.fichaPessoa .dadosGerais label {
  font-weight: bold;
}

.fichaPessoa .nome {
  grid-column-start: 1;
}

.fichaPessoa .idade {
  grid-column-start: 1;
}

.fichaPessoa .genero{
  grid-column-start: 2;
}

.fichaPessoa .tabela {
  width: 70%;
}

.fichaPessoa .tabela table td {
  border: 1px solid #ccc;
}

.fichaPessoa .tabela table th {
  border: 1px solid #ccc;
}

.fichaPessoa .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}

.fichaPessoa .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fichaPessoa .verButton {
  cursor: pointer;
}
.fichaPessoa .verButton a:hover {
  font-weight: bold;
}

.fichaPessoa .apagarButton {
  cursor: pointer;
}

.fichaPessoa .apagarButton a:hover {
  font-weight: bold;
}

.fichaPessoa .editarButton {
  cursor: pointer;
}

.fichaPessoa .editarButton a:hover {
  font-weight: bold;
  font-size: 13px;
}

.fichaPessoa .dados .buttons {
  border-color: white;
  background-color: white;
  border-top: none;
  width: 1%;
}
</style>