
<template>
  <div class="relatorioHistoricoParticipantes">
    <h3 class="mt-5"> Eventos com mais participantes: </h3>
    <div class="dadosTabela">
        <table class="w3-table-all" id="tabela">
          <!--ID, nome, tipo, data, local -->
          <th> Nome </th>
          <th> Num. Registos </th>
          <th> Num. Presentes </th>
          <tr class="conteudo" v-for="(row, tableKey) in dataRelatorio2" :key="tableKey">
            <td>{{row.nomeEvento}}</td>
            <td>{{row.numParticipantes}}</td>
            <td>{{row.numPresencas}}</td>
          </tr>
        </table>
        </div>
    <br>
    <h3>Evolução do número de participantes dos últimos {{numEventos}} eventos:</h3>
    <br>
    <div class="grafico" id="grafico" v-if="showChart">
      <apexcharts width=100% type=bar :options="chartOptions" :series="seriesData"/>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { bus } from "../../main.js";

export default {
  activated() {
    this.showChart = true;
    this.$axios.get("getRelatorioEventosPorParticipacoes")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.dataRelatorio = response.data;
          this.formatData();
        }
      })
      .catch(() => {

      });
    this.$axios.get("getRelatorioParticipacoesPorEvento")
      .then(response => {
        if(response.status == 401)
        {
          bus.$emit("logout");
        }
        else
        {
          this.dataRelatorio2 = response.data;
          this.formatData();
        }
      })
      .catch(() => {

      });
  },
  deactivated() {
    this.showChart = false;
  },
  data() {
    return {
      chartOptions: {},
      dataRelatorio: [],
      dataRelatorio2: [],
      seriesData: [{
        name: 'Participantes',
        data: []
      }],
      showChart: false,
      numEventos: 0,
    };
  },
  methods: {
    formatData(){
      var seriesAux = [];
      var labelsAux = [];
      for(var i in this.dataRelatorio) {
        labelsAux.push(this.dataRelatorio[i].nomeEvento);
        seriesAux.push(this.dataRelatorio[i].numParticipantes);
      }
      this.chartOptions = {
        labels: labelsAux
      }
      this.seriesData = [{
        name: 'Participantes',
        data: seriesAux
      }]
      this.numEventos = this.dataRelatorio.length;

    }
  },
  components: {
    apexcharts: VueApexCharts
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://www.w3schools.com/w3css/4/w3.css");

.relatorioHistoricoParticipantes h3 {
  text-align: center;
  background-color: linen;
  width: 100%;
}

.relatorioHistoricoParticipantes{
  font-family: "Open Sans";

}

.relatorioHistoricoParticipantes #tabela {
  width: 70%;
  margin: auto;
}
/*
.relatorioHistoricoParticipantes .dadosTabela{
  width: 70;
  margin: auto;
}*/
.relatorioHistoricoParticipantes .dadosTabela table th {
  border: 1px solid #ccc;
}
.relatorioHistoricoParticipantes .dadosTabela table td {
  border: 1px solid #ccc;
}

.relatorioHistoricoParticipantes .grafico {
  width: 50%;
  margin: auto;
}

</style>