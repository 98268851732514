<template>
    <div class="conteudo">
        <div class="conteudo-group">
            <div class="tipo-contacto">
                <label>Tipo de Contacto: </label>
                <select class="form-select" v-model="tipoContacto">
                    <option value="pessoa">Pessoa individual</option>
                    <option value="lista">Lista de Emails</option>
                </select>
            </div>
            <div class="form-group-contacto">
                <label for="mensagem">{{contacto}}:<i class="obrigatorio">*</i></label>
                <vue-single-select v-if="tipoContacto == 'pessoa'"
                    v-model="mensagem.pessoa"
                    :options="allPessoas"
                    :placeholder="disabledPessoa ? 'A carregar dados...' : ('Escolher ' + contacto)"
                    option-label="nome"
                    :disabled="disabledPessoa"
                    :classes="{
                        pointer: -1,
                        wrapper: 'single-select-wrapper',
                        input: 'search-input-mensagem',
                        icons: 'icons',
                        required: 'required',
                        activeClass: 'active',
                        dropdown: 'dropdown'
                    }"
                ></vue-single-select>
                <vue-single-select v-else
                    v-model="mensagem.lista"
                    :options="allListas"
                    :placeholder="disabledLista ? 'A carregar dados...' : ('Escolher ' + contacto)"
                    option-label="nome"
                    :disabled="disabledLista"
                    :classes="{
                        pointer: -1,
                        wrapper: 'single-select-wrapper',
                        input: 'search-input-mensagem',
                        icons: 'icons',
                        required: 'required',
                        activeClass: 'active',
                        dropdown: 'dropdown'
                    }"
                ></vue-single-select>
            </div>
            <div class="form-group-titulo">
                <label>Título da Mensagem:<i class="obrigatorio">*</i></label>
                <input type="text" class="form-control" name="titulo" v-model="mensagem.titulo" placeholder="Título">
            </div>
            <div class="form-group-mensagem">
                <label>Mensagem:<i class="obrigatorio">*</i></label>
                <textarea class="form-control" v-model="mensagem.mensagem" id="mensagem" rows="10"></textarea>
            </div>
            <div class="tipo-envio">
                <label>Tipo de Envio: </label>
                <select class="form-select" v-model="tipoEnvio">
                    <option value="instantaneo">Instantâneo</option>
                    <option value="programado">Programado</option>
                </select>
            </div>
            <div class="form-group-data" v-if="tipoEnvio == 'programado'">
                <label>Data de Envio:<i class="obrigatorio">*</i></label>
                <input class="form-control" v-model="mensagem.dataEnvio" :min="minDate" type="date" name="data">
            </div>
            <div class="botoes">
                <button type="button" class="btn btn-secondary mensagem" @click="registarEditar" :disabled="submiting || (tipoContacto == 'pessoa' && disabledPessoa) || (tipoContacto == 'lista' && disabledLista)"><i :class="tipoEnvio == 'instantaneo' ? 'bi bi-send-check' : 'bi bi-device-ssd'"></i> {{tipoEnvio == 'instantaneo' ? 'Enviar Mensagem' : 'Agendar Mensagem'}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "../../../main.js";

export default {
    props: {
        mensagemEdit: {
            type: Object,
            default: null,
            required: true,
        },
        acao:{
            type: String,
            default: 'C',
            required: true
        }
    }, 
    data() {
        return {
            allPessoas: [],
            allListas: [],
            mensagem: {
                titulo: "",
                mensagem: "",
                dataEnvio: null,
                pessoa: null,
                lista: null,
            },
            tipoContacto: "pessoa",
            tipoEnvio: "instantaneo",
            minDate: null,
            disabledPessoa: true,
            disabledLista: true,
            submiting: false,
        };
    },
    computed: {
        contacto() {
            return this.tipoContacto == "pessoa" ? "Pessoa" : "Lista Emails";
        }
    },
    activated() {
        if(this.acao == 'E'){
            this.mensagem = {...this.mensagemEdit};
            this.tipoEnvio = 'programado';
            this.tipoContacto = this.mensagem.idPessoa != -1 ? 'pessoa' : 'lista';
        }
        this.$axios.get("getAllPessoasIdNome/")
        .then(response => {
            if (response.status === 401) {
                bus.$emit("logout");
            }
            else
            {
                this.allPessoas = response.data;
                if(this.mensagem.idPessoa != -1)
                {
                    this.mensagem.pessoa = this.allPessoas.find(pessoa => pessoa.id == this.mensagem.idPessoa);
                }
                this.disabledPessoa = false;
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                bus.$emit("logout");
                }
        });

        this.$axios.get("getAllListasIdNome/")
        .then(response => {
            if (response.status === 401) {
                bus.$emit("logout");
            }
            else
            {
                this.allListas = response.data;
                if(this.mensagem.idLista != -1)
                {
                    this.mensagem.lista = this.allListas.find(lista => lista.id == this.mensagem.idLista);
                }
                this.disabledLista = false;
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                bus.$emit("logout");
            }
        });

        let MyDate = new Date();
        if(MyDate.getHours() >= 9)
        {
            MyDate.setDate(MyDate.getDate() + 1)
        }
        this.minDate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + 
                        ('0' + MyDate.getDate()).slice(-2);
    },
    methods: {
        registarEditar() {
            if(this.validarCampos())
            {
                this.submiting = true;
                if(this.tipoEnvio == 'instantaneo')
                {
                    this.$axios.post("enviarMensagem/", {
                        titulo: this.mensagem.titulo,
                        mensagem: this.mensagem.mensagem,
                        idLista: this.tipoContacto == 'lista' ? this.mensagem.lista.id : null,
                        idPessoa: this.tipoContacto == 'pessoa' ? this.mensagem.pessoa.id : null
                    }).then(response => {
                        if (response.status === 401) {
                            bus.$emit("logout");
                        }
                        else if(response.status === 200)
                        {
                            this.$toasted.success("Mensagem enviada com sucesso");
                            bus.$emit("registoEdicaoMensagemPersonalizada");  
                        }
                        else
                        {
                            this.$toasted.show("Ocorreu um erro ao enviar a mensagem, tente novamente mais tarde");
                        }
                        this.submiting = false;
                    })
                }
                else
                {

                    if(this.acao == 'E')
                    {
                        this.$axios.put("putMensagem/" + this.mensagem.id, {
                            tipo: 'P',
                            titulo: this.mensagem.titulo,
                            mensagem: this.mensagem.mensagem,
                            estado: 0,
                            dataEnvio: this.mensagem.dataEnvio,
                            idEvento: null,
                            idLista: this.tipoContacto == 'lista' ? this.mensagem.lista.id : null,
                            idPessoa: this.tipoContacto == 'pessoa' ? this.mensagem.pessoa.id : null
                        })
                        .then(response => {
                            if (response.status === 401) {
                                bus.$emit("logout");
                            }
                            else if(response.status == 200)
                            {
                                this.$toasted.success("Mensagem editada com sucesso");
                                bus.$emit("registoEdicaoMensagemPersonalizada");  
                            }
                            else
                            {
                                this.$toasted.error("Erro ao editar mensagem");
                            }
                            this.submiting = false;
                        })
                        .catch(error => {
                            this.$toasted.error("Erro ao editar mensagem");
                            if(error.response.status === 401){
                                bus.$emit("logout");
                            }
                        });
                    }
                    else
                    {

                        this.$axios.post("postMensagem", {
                            tipo: 'P',
                            titulo: this.mensagem.titulo,
                            mensagem: this.mensagem.mensagem,
                            dataEnvio: this.mensagem.dataEnvio,
                            idEvento: null,
                            idLista: this.tipoContacto == 'lista' ? this.mensagem.lista.id : null,
                            idPessoa: this.tipoContacto == 'pessoa' ? this.mensagem.pessoa.id : null
                        })
                        .then(response => {
                            if (response.status === 401) {
                            bus.$emit("logout");
                            }
                            else if(response.status == 201)
                            {
                                this.$toasted.success("Mensagem agendada com sucesso");
                                bus.$emit("registoEdicaoMensagemPersonalizada");  
                            }
                            else
                            {
                                this.$toasted.error("Erro ao agendar mensagem");
                            }
                            this.submiting = false;
                        })
                        .catch(error => {
                            this.$toasted.error("Erro ao agendar mensagem");
                            if(error.response.status === 401){
                                bus.$emit("logout");
                            }
                        });
                    }
                }
            }
        },
        validarCampos()
        {
            if(this.tipoContacto == null || (this.tipoContacto != 'pessoa' 
                && this.tipoContacto != 'lista') || (this.tipoContacto == 'pessoa' && this.mensagem.pessoa == null) 
                || ( this.tipoContacto == 'lista' && this.mensagem.lista == null)
                || this.mensagem.titulo == null || this.mensagem.titulo.trim().length == 0 ||
                this.mensagem.mensagem == null || this.mensagem.mensagem.trim().length == 0 || this.tipoEnvio == null
                || (this.tipoEnvio != 'instantaneo' && this.tipoEnvio != 'programado') || 
                (this.tipoEnvio == 'programado' && this.mensagem.dataEnvio == null))
            {
                this.$toasted.error("Por favor preencha todos os campos obrigatórios corretamente corretamente");
                return false;
            }
            else if(this.tipoEnvio == 'programado' && new Date().getHours() >= 9 && new Date(this.mensagem.dataEnvio).getTime() <= new Date().getTime())
            {
                this.$toasted.error("A data de envio deve ser superior à data de hoje");
                return false;
            }
            else if(this.tipoEnvio == 'programado' && new Date(this.mensagem.dataEnvio).setHours(0,0,0,0) < new Date().setHours(0,0,0,0))
            {
                this.$toasted.error("A data de envio deve ser superior ou igual à data de hoje");
                return false;
            }
            
            return true;
        }
    }
};
</script>

<style scoped>

.btn.btn-secondary.mensagem:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.btn.btn-secondary.mensagem
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.form-select:focus, .form-control:focus {
    border-color: #5bb1b3;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(17 144 147);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(141 207 208);
}

.conteudo{
    background-color: oldlace;
    width: 100%;
    display: flex;
    justify-content: center;
}

.conteudo-group{
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    width: 80%;
}

.obrigatorio{
    color: red;
}

.form-group-mensagem, .form-group-titulo, .form-group-data, .form-group-evento, .tipo-contacto, .form-group-contacto, .tipo-envio{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 30px;
}

.form-group-evento{
    width: 50%;
}

.form-group-data input{
    width: 30%;
    border: 1px solid #a1a0a0;
    border-radius: 4px;
    height: 35px;
    margin-bottom: 10px;
}

.botoes{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
