<template>
  <div>
    <v-confirm
      v-model="dialog"
      :title="title"
      :message="message"
      :btns="btns"
    />
    <br />
    <div class="voltarEvento">
      <button v-on:click="switchComponent('EventsTablesMenu')">
        <i class="bi bi-reply-fill"></i>Voltar
      </button>
    </div>
    <div class="loader" v-if="isLoading">
      <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
      </div> 
      <h3>A carregar inscrições ...</h3>
    </div>
    <div class="inscricoes">
      <div v-if="!isLoading && this.totalInscricoes != 0">
        <h1>Inscrições no Evento</h1>
        <h3 class="text-center mt-4 mb-4">Evento: {{dadosEvento.nomeEvento}}</h3>
        <div class="form-group-filtrar">
          <form class="form-group-filtrarInscricoes" @submit.prevent="paginateInscricoes()">
              <input type="text" class="form-control" v-model="filtroInscricoes" placeholder="Filtrar por nome, email">
          </form>
        </div>
        <div class="buttons-top">
          <div class="buttonRegistarComparecimentoTodos">
            <button v-on:click="registarComparecimentoTodos()">
              <i class="bi bi-check-all"></i>
              {{ this.registarComparecimentoTodosText }}
            </button>
          </div>
          <div class="buttonExportarPresencas">
            <button v-on:click="exportarPresencas()">
              <i class="bi bi-file-earmark-arrow-down-fill"></i> Exportar Presenças
            </button>
          </div>
          <div class="buttonExportarPresencas">
            <button v-on:click="exportarCertificados()">
             <i class="bi bi-patch-check-fill"></i> Exportar Certificados
            </button>
          </div>
          <label v-if="dadosEvento.necessitaPagamento" class="custo"
            >Custo do Evento: {{ dadosEvento.custo }} €</label
          >
        </div>
        <div class="dados">
          <table class="w3-table-all" id="tabela">
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Primeiro Evento</th>
              <th class="compareceu"><div class="ordenar-compareceu" :title="ordenacaoCompareceu ? 'Ordenar por não comparecimento' : 'Ordenar por comparecimento'" @click="ordenarComparecimento">Compareceu <i :class="ordenacaoCompareceu ? 'bi bi-caret-down' : 'bi bi-caret-up' "></i></div></th>
              <th>Pagamento Necessário</th>
              <th v-if="dadosEvento.necessitaPagamento">Valor (€)</th>
              <th v-if="dadosEvento.necessitaPagamento">Nº Recibo</th>
            </tr>
            <tr
              class="conteudo"
              v-for="(inscricao, tableKey) in allInscricoes"
              :key="tableKey"
            >
              <td>{{ inscricao.nome }}</td>
              <td>{{ inscricao.email }}</td>
              <td>
                {{ inscricao.flagPrimeiroEvento == true ? "Sim" : "Não" }}
              </td>
              <td>{{ inscricao.flagCompareceu == true ? "Sim" : "Não" }}</td>
              <td>
                {{ inscricao.necessitaPagamento == true ? "Sim" : "Não" }}
              </td>
              <td v-if="dadosEvento.necessitaPagamento">
                {{ inscricao.valor == -1 ? "" : inscricao.valor }}
              </td>
              <td v-if="dadosEvento.necessitaPagamento">
                {{ inscricao.recibo == -1 ? "" : inscricao.recibo }}
              </td>
              <td class="buttos-td">
                <div class="buttons">
                  <div class="editarButton" v-if="dadosEvento.necessitaPagamento">
                    <a
                      class="bi bi-credit-card-fill"
                      v-on:click="editar(inscricao)"
                      title="Editar"
                    ></a>
                  </div>
                  <div class="registarCompareceu">
                    <a
                      class="bi bi-check-lg"
                      v-on:click="registarComparecimento(inscricao)"
                      title="Alterar Comparência"
                    ></a>
                  </div>
                  <div class="apagarButton">
                    <a class="bi bi-trash-fill" @click="apagar(inscricao)" title="Remover Participação" role="button"></a>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <Pagination :page=paginatePessoas.page :total=paginatePessoas.total @pagination="paginateInscricoes"></Pagination>
        </div>
      </div>
      <h1 v-else-if="!isLoading">
        Sem inscrições no Evento
      </h1>
    </div>
  </div>
</template>
<script>

import { bus } from "../../main.js";

import   jsPDF  from "jspdf";

export default {
  data() {
    return {
      allInscricoes: [],
      registarComparecimentoTodosText: "Alterar Comparências do Evento",
      dialog: false,
      title: "",
      message: "",
      btns: [],
      filtroInscricoes: "",
      totalInscricoes: 0,
      paginatePessoas:{
        page: 1,
        total: 0,
      },
      isLoading: true,
      ordenacaoCompareceu: true,
    };
  },
  props: {
    dadosEvento: {
      type: Object,
      required: true,
    },
  },
  activated() {
    this.$axios.get(`getInscricoesPagamentoEvento/${this.dadosEvento.idEvento}`)
      .then((response) => {
        if(response.status == 401) {
          bus.$emit('logout');
        }
        else
        {
          this.totalInscricoes = response.data.length;
          this.allInscricoes = response.data;
          this.allInscricoesFull = response.data;
          this.paginateInscricoes();
          this.isLoading = false;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          bus.$emit("logout");
        }
      });
  },
  deactivated() {
    this.allInscricoes = [];
    this.allInscricoesFull = [];
    this.totalInscricoes = 0;
    this.paginatePessoas = {
      page: 1,
      total: 0,
    };
    this.isLoading = true;
    this.ordenacaoCompareceu = true;
    this.filtroInscricoes = "";
  },
  watch: {
    filtroInscricoes() {
      this.paginateInscricoes();
    },
  },
  methods: {
    switchComponent(comp) {
      bus.$emit("switchComp", comp);
    },
    ordenarComparecimento()
    {
      this.ordenacaoCompareceu = !this.ordenacaoCompareceu;
      this.paginateInscricoes();
    },
    paginateInscricoes(page)
    {
      this.paginatePessoas.page = page || 1;
      this.allInscricoes = this.allInscricoesFull.filter(inscricao => inscricao.nome.toLowerCase().includes(this.filtroInscricoes.toLowerCase()) || inscricao.email.toLowerCase().includes(this.filtroInscricoes.toLowerCase()));
      this.allInscricoes.sort((inscricaoA, inscricaoB) => { 
        if(this.ordenacaoCompareceu)
        {
          return inscricaoB.flagCompareceu - inscricaoA.flagCompareceu;
        }
        return inscricaoA.flagCompareceu - inscricaoB.flagCompareceu;
      });
      this.paginatePessoas.total = this.allInscricoes.length;
      this.allInscricoes = this.allInscricoes.slice((this.paginatePessoas.page - 1) * 20, this.paginatePessoas.page * 20);
    },
    apagarInscricao(inscricao)
    {
      this.$axios.delete("deleteParticipacao/" + inscricao.idEvento + "/" + inscricao.idParticipante)
        .then((response) => {
          if (response.status === 401) {
            bus.$emit("logout");
          }
          else if(response.status === 200){
            this.$toasted.success("Participação de " + inscricao.nome + " removida com sucesso");
            this.allInscricoesFull.splice(this.allInscricoesFull.findIndex(i => i.idParticipante === inscricao.idParticipante), 1);
            this.paginateInscricoes(this.allInscricoes.length <= 1 ? this.paginatePessoas.page >= 2 ? this.paginatePessoas.page - 1 : 1 : this.paginatePessoas.page);
          }
          else
          {
            this.$toasted.error("Erro ao remover a participação");
          }
        })
        .catch(() => {
          this.$toasted.error("Erro a remover participação")
        });
    },
    apagar(inscricao)
    {
      this.title = "Remover Participação";
      this.message = "Tem a certeza que pretende remover o participante " + inscricao.nome +" ?";
      this.btns = [
        {
          text: "Remover",
          function: () => 
          {
            this.apagarInscricao(inscricao);
            this.dialog = false;
          }
        },
        {
          text: "Cancelar",
          function: () => this.dialog = false
        }
      ]
      this.dialog = true;
    },
    editar(data) {
      bus.$emit("registarPagamentoInscricao", data);
    },
    registarComparecimento(inscricao) {
      this.$axios.patch(`patchComparecimentoInscricao/${this.dadosEvento.idEvento}/${inscricao.idParticipante}`,null)
        .then((response) => {
          if(response.status == 401) 
          {
            bus.$emit('logout');
          }
          else
          {


            if (response.status == 409) {
              this.$toasted.error("Ocorreu um erro");
            }
            else if (response.status == 200) {
              inscricao.flagCompareceu = !inscricao.flagCompareceu;
              if (inscricao.flagCompareceu) {
                this.$toasted.show("Comparecimento registado com sucesso");
              } else {
                this.$toasted.show("Falta de comparência registada com sucesso");
              }
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
        });
    },
    registarComparecimentoTodos() {
      this.$axios.patch(`patchComparecimentoInscricao/${this.dadosEvento.idEvento}`,null)
        .then((response) => {
          if(response.status == 401)
          {
            bus.$emit('logout');
          }
          else
          {
            this.allInscricoesFull.forEach(function(part, index, theArray) {
              theArray[index].flagCompareceu = !theArray[index].flagCompareceu;
            });

            this.$toasted.show("Comparências alteradas com sucesso");

            if (response.status == 409) {
              this.$toasted.error("Ocorreu um erro");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            bus.$emit("logout");
          }
        });
    },
    exportarCertificados() {
      let pessoas = typeof this.allInscricoesFull != "object" ? JSON.parse(this.allInscricoesFull) : this.allInscricoesFull;
      
      let imgData = new Image();
      imgData.src = 'img/certificado.png';

      let exportado = true;

      pessoas.forEach((pessoa)  => {
        if (pessoa.idAmigoSocio != 0 && new Date(pessoa.dataExpiracao).getTime() > new Date().getTime() && pessoa.flagCompareceu) {
          const doc = new jsPDF("l", "mm", [297, 210]);

          doc.addImage(imgData, "png", 0, 0, 297, 210);

          let width = doc.internal.pageSize.getWidth();
          doc.setFontSize(32);
          doc.setTextColor(64,62,62);
          doc.text("CERTIFICADO de PARTICIPAÇÃO", width / 2, 50, {align: "center"});
          doc.setFontSize(18);
          doc.text("Certificamos que", width / 4, 68, {align: "center"});

          doc.setFontSize(35);
          doc.setTextColor(0,0,0);
          let nome = doc.splitTextToSize(pessoa.nome, 280);
          doc.text(nome, width / 2, 87, {align: "center"});

          doc.setFontSize(20);
          doc.setTextColor(64,62,62);
          doc.text('Esteve presente no evento com o tema:', width / 2, 117, { align: "center" });

          doc.setFontSize(22);
          doc.setTextColor(0,0,0);
          doc.text("\""+this.dadosEvento.nomeEvento+"\"", width / 2, 125, { align: "center" });
          doc.setFont("Times", "Roman");
          doc.setTextColor(38,37,37);
          doc.text(this.dadosEvento.idOrador != -1 ? "Apresentado por: " + this.dadosEvento.nomeOrador : "Apresentado", width / 2, 140, {align: "center"});
          doc.setFontSize(18);
          doc.setFont("courier", "bold");
          doc.text("No dia: " + this.dadosEvento.dataEvento.split(" ")[0], width / 2, 148, { align: "center" });
          doc.setFont("times", "normal");
          doc.setTextColor(0,0,0);
          doc.setFontSize(15);

          let descricao = doc.splitTextToSize(this.dadosEvento.observacoes, 190);
          doc.text(descricao, width / 2, 163, { align: "center" });

          doc.save("Certificado " + pessoa.nome + " - " + this.dadosEvento.nomeEvento + ".pdf");
          exportado = false;
        }
      });
      if (exportado) {
        this.$toasted.error("Não existem amigos sócios com certificados para exportar");
      }
    },
    exportarPresencas() {
      let arrData = typeof this.allInscricoesFull != "object" ? JSON.parse(this.allInscricoesFull) : [...this.allInscricoesFull];

      arrData.sort((a, b) => a.nome.localeCompare(b.nome));

      let CSV = "sep=," + "\r\n\n";

      let row = "";

      row += "ID do Evento" + "," + "Nome do Evento" + "," + "Data do Evento";

      CSV += row + "\r\n";

      let line = "";
      line += this.dadosEvento.idEvento + "," + this.dadosEvento.nomeEvento + "," + this.dadosEvento.dataEvento + "\r\n\r\n";
      CSV += line;

      row = "";

      row += "Nome do Participante" + "," + "Email" + "\r\n";
      CSV += row;

      arrData.forEach(function(row) {
        if (row.idAmigoSocio != 0 && row.flagCompareceu && new Date(row.dataExpiracao).getTime() > new Date().getTime()) {
          let line = "";
          line += row.nome + "," + row.email + "\r\n";
          CSV += line;
        }
      });

      if(arrData.filter(row => row.idAmigoSocio != 0 && row.flagCompareceu && new Date(row.dataExpiracao).getTime() > new Date().getTime()).length == 0) {
        this.$toasted.error("Não existem dados de amigos sócios para exportar");
        return;
      }

      let fileName = "Presencas_" + this.dadosEvento.nomeEvento;
      fileName = fileName.replace(/ /g, "_");

      let uri = "data:text/csv;charset=utf-8," + escape(CSV);

      let link = document.createElement("a");
      link.href = uri;
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
<style scoped>

.ordenar-compareceu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

.ordenar-compareceu:hover{
  text-decoration: underline;
}

.compareceu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

label.comparecimento:hover {
  text-decoration: underline;
  cursor: pointer;
}

.bi.bi-caret-down:before, .bi.bi-caret-up:before {
  font-size: 19px !important;
  margin-top: 4px;
  cursor: pointer;
}

.loader{
  margin-top: 110px;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #5babcd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(91 171 205 / 37%);
}

form.form-group-filtrarInscricoes {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.form-group-filtrar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 100%;
    flex-direction: row;
}

td.buttos-td {
  padding: 0px !important;
}

.inscricoes .dados .buttons{
  border-top: none;
  border: none;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.buttons-top {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-left: 10%;
}

a.bi.bi-check-lg{
  font-size: 19px !important;
}


a.bi.bi-credit-card-fill,
a.bi.bi-trash-fill {
  font-size: 17px !important;
}

a.bi.bi-check-lg:hover
{
  font-size: 17px !important;
}

a.bi.bi-credit-card-fill:hover,
a.bi.bi-trash-fill:hover {
  font-size: 15px !important;
}

.bi-check-all::before {
  content: "\f269";
  font-size: 20px !important;
}

i.bi.bi-check-all {
  display: flex;
}

.custo {
  margin-left: 20px;
  font-size: 18px;
  margin-top: 30px;
}

.voltarEvento button {
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #eedec0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.voltarEvento i {
  font-size: 17px;
  margin-right: 3px;
}

.voltarEvento button:hover {
  background-color: #fdf5e6;
}

.inscricoes h1 {
  text-align: center;
}

.inscricoes {
  font-family: "Open Sans";
}

.inscricoes .tabela {
  width: 100%;
  margin-bottom: 20px;
}

.inscricoes #tabela {
  width: 80%;
  margin-top: 10px;
}

/* .inscricoes .dados .idEvento {
    width: 5%;
} */

.inscricoes .tabela table td {
  border: 1px solid #ccc;
}

/* .inscricoes .dados table td {
    width: 25%;
} */

.inscricoes .tabela table th {
  border: 1px solid #ccc;
}

.inscricoes .tabela .conteudo:hover {
  font-weight: bold;
  background-color: #e2e2e2;
}

.inscricoes .dados {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.inscricoes .editarButton {
  cursor: pointer;
}

.inscricoes .editarButton a:hover {
  font-weight: bold;
  font-size: 13px;
}

.inscricoes .registarCompareceu {
  cursor: pointer;
}

.inscricoes .registarCompareceu a:hover {
  font-weight: bold;
  font-size: 13px;
}

.inscricoes .buttonRegistarComparecimentoTodos,
.inscricoes .buttonExportarPresencas {
  margin-top: 30px;
  display: flex;
}

.inscricoes .buttonRegistarComparecimentoTodos button,
.inscricoes .buttonExportarPresencas button {
  text-align: center;
  cursor: pointer;
  background-color: #a2e3fb;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.inscricoes .buttonRegistarComparecimentoTodos button:hover,
.inscricoes .buttonExportarPresencas button:hover {
  background-color: #43cbfd;
}
</style>
